import IdentityManager from "../Managers/Identity.manager";
import PFManager from "../Managers/Process.Fulfillment.Manager";
import Constants from "./Constants";
import logger, { ILogger } from "./logger";
import Utils from "./Utils";

class ApiService {
    static getSimple = async (endpoint: string): Promise<any> => {
        let token = IdentityManager.AccessToken;
        const request = await fetch(endpoint, { headers: { 'Authorization': `Bearer ${token}` } });
        if (request.status !== 500 && request.status != 400 && request.status != 404) {
            const response = await request.json();
            return response;
        }
        else {
            console.log("Error=" + endpoint)
        }
    };
    static get = async (endpoint: string): Promise<any> => {
        let token = IdentityManager.AccessToken;
        const request = await fetch(endpoint, { headers: { 'Authorization': `Bearer ${token}` } });
        if (request.status >= 400) {
            return { success: false, msg: request.status, data: "" };
        }
        else {
            const response = await request.json();
            if (response.success) {
                return { success: true, msg: "", data: response.data };
            }
            else {
                return { success: false, msg: response.msg, data: "" };
            }
        }
    };

    static nomGet = async (endpoint: string): Promise<any> => {
        const request = await fetch(endpoint);

        if (request.status >= 400) {
            return { success: false, msg: request.status, data: "" };
        }
        else {
            const response = await request.json();
            if (response.success) {
                return { success: true, msg: "", data: response.data };
            }
            else {
                return { success: false, msg: response.msg, data: "" };
            }
        }
    };


    static nomPost = async (endpoint: string, body: any): Promise<any> => {

        try {
            const controller = new AbortController();
            const id = setTimeout(() => {
                controller.abort()
            }, 100000);
            let token = IdentityManager.AccessToken;
            const request = await fetch(endpoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                signal: controller.signal
            });
            clearTimeout(id);



            if (request.status >= 400) {
                return { success: false, msg: request.status, data: "" };
            }
            else {
                const response = await request.json();
                if (response.success) {
                    return { success: true, msg: "", data: response.data };
                }
                else {
                    return { success: false, msg: response.msg, data: "" };
                }
            }
        }
        catch (e) {
            throw e;
        }


    };

    static post = async (
        endpoint: string,
        body: any,
        shipmentKey?: any,
        currentStatus?: any,
        retryCount? : any
    ): Promise<any> => {

        try {
            if(!retryCount)
            {
                retryCount = 1;
            }
            const controller = new AbortController();
            const id = setTimeout(() => {
                controller.abort()
            }, this.defaultAPITimeout(endpoint));
            let token = IdentityManager.AccessToken;
            const request = await fetch(endpoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                signal: controller.signal
            });
            clearTimeout(id);
            if (request.status >= 400) {
                // logging, type - error
                const response = await request.json();
                if (ApiService.allowAPILoggin(endpoint)) {
                    let saveResponseConfig = Utils.getConfigurationWithKey(Constants.Configurations.SaveLogResponse);
                    const tag = endpoint?.toString()?.split("/")?.pop();
                    let loggingPayload: ILogger = {
                        message: response?.msg,
                        tag: endpoint.toString().split("/")[2],
                        payLoad: body,
                    }
                    //Scenario to Resolve 504 Errors for selected Endpoints
                    if (this.handleOMSError(tag,response?.msg))
                    {
                        await PFManager.getConfigurations();
                        if(shipmentKey && currentStatus && this.orderStatusConfig())
                        {
                            const checkStatusPayload = {
                                shipmentKey: shipmentKey,
                                endpoint: tag,
                                currentStatus: currentStatus,
                                configs: {
                                    checkStatus: Utils.getConfigurationWithKey(Constants.Configurations.CheckStatus),
                                    apiTimeout: Utils.getConfigurationWithKey(Constants.Configurations.APITimeout),
                                    resolveErrorEndpoints: Utils.getConfigurationWithKey(Constants.Configurations.ResolveErrorsEndpoint),
                                    resolveErrors: Utils.getConfigurationWithKey(Constants.Configurations.ResolveErrors),
                                    validStatues: Utils.getConfigurationWithKey(Constants.Configurations.ValidStatuses)
                                }
                            };
                            let orderStatus = await PFManager.getOrderStatus(checkStatusPayload);
                            if(orderStatus?.success && orderStatus?.data?.status)
                            {
                                let validStatus = this.getValidStatus(currentStatus, orderStatus.data.status);
                                if(validStatus)
                                {
                                    const success = this.logSuccess(tag,body);
                                    return success;
                                }
                            }

                        }
                        if(this.retryConfig() && retryCount<=1)
                        {
                            return this.post(endpoint,body,shipmentKey,currentStatus,retryCount+1);
                        }
                    }

                    if (saveResponseConfig && saveResponseConfig == "1" && ApiService.checkLogsEndpoint(loggingPayload.tag)) {
                        loggingPayload.responseOMS = JSON.stringify(response);
                        logger.error(loggingPayload);
                    }
                    else {
                        logger.error(loggingPayload);
                    }
                }

                return { success: false, msg: response?.msg, data: "" };

            }
            else {
                const response = await request.json();
                if (response.success) {
                    // logging == success
                    if (ApiService.allowAPILoggin(endpoint)) {
                        let saveResponseConfig = Utils.getConfigurationWithKey(Constants.Configurations.SaveLogResponse);
                        let loggingPayload: ILogger = {
                            message: response?.msg,
                            tag: endpoint.toString().split("/")[2],
                            payLoad: body
                        }
                        if (saveResponseConfig && saveResponseConfig == "1" && ApiService.checkLogsEndpoint(loggingPayload.tag)) {
                            loggingPayload.responseOMS = JSON.stringify(response);
                            logger.info(loggingPayload);
                        }
                        else {
                            logger.info(loggingPayload);
                        }
                    }
                    return { success: true, msg: "", data: response.data };
                }
                else {
                    // logging
                    if (ApiService.allowAPILoggin(endpoint)) {
                        let saveResponseConfig = Utils.getConfigurationWithKey(Constants.Configurations.SaveLogResponse);
                        const tag = endpoint?.toString()?.split("/")?.pop() ?? "";
                        let loggingPayload: ILogger = {
                            message: response?.msg,
                            tag: endpoint.toString().split("/")[2],
                            payLoad: body
                        }
                        
                        //Scenario to Resolve 504 Errors for selected Endpoints
                        if (this.handleOMSError(tag,response?.msg))
                        {
                            await PFManager.getConfigurations();
                            if(shipmentKey && currentStatus && this.orderStatusConfig())
                            {
                                const checkStatusPayload = {
                                    shipmentKey: shipmentKey,
                                    endpoint: tag,
                                    currentStatus: currentStatus,
                                    configs: {
                                        checkStatus: Utils.getConfigurationWithKey(Constants.Configurations.CheckStatus),
                                        apiTimeout: Utils.getConfigurationWithKey(Constants.Configurations.APITimeout),
                                        resolveErrorEndpoints: Utils.getConfigurationWithKey(Constants.Configurations.ResolveErrorsEndpoint),
                                        resolveErrors: Utils.getConfigurationWithKey(Constants.Configurations.ResolveErrors),
                                        validStatues: Utils.getConfigurationWithKey(Constants.Configurations.ValidStatuses)
                                    }
                                };

                                let orderStatus = await PFManager.getOrderStatus(checkStatusPayload);
                                if(orderStatus?.success && orderStatus?.data?.status)
                                {
                                    let validStatus = this.getValidStatus(currentStatus, orderStatus?.data?.status);
                                    if(validStatus)
                                    {
                                        const success = this.logSuccess(tag,body);
                                        return success;
                                    }
                                }

                            }
                            if(this.retryConfig())
                            {
                                return this.post(endpoint,body,shipmentKey,currentStatus,retryCount+1);
                            }

                        }

                        if (saveResponseConfig && saveResponseConfig == "1" && ApiService.checkLogsEndpoint(loggingPayload.tag)) {
                            loggingPayload.responseOMS = JSON.stringify(response);
                            logger.error(loggingPayload);
                        }
                        else {
                            logger.error(loggingPayload);
                        }
                    }
                    return { success: false, msg: response.msg, data: "" };
                }

            }
        }
        catch (error: any) {
            if (ApiService.allowAPILoggin(endpoint) && error) {
                const tag = endpoint?.toString()?.split("/")?.pop() ?? "";
                //Scenario to Resolve AbortError for selected Endpoints
                if (this.handleOMSError(tag, error?.name ?? ""))
                {
                    await PFManager.getConfigurations();
                    if(shipmentKey && currentStatus && this.orderStatusConfig())
                    {
                        const checkStatusPayload = {
                            shipmentKey: shipmentKey,
                            endpoint: tag,
                            currentStatus: currentStatus,
                            configs: {
                                checkStatus: Utils.getConfigurationWithKey(Constants.Configurations.CheckStatus),
                                apiTimeout: Utils.getConfigurationWithKey(Constants.Configurations.APITimeout),
                                resolveErrorEndpoints: Utils.getConfigurationWithKey(Constants.Configurations.ResolveErrorsEndpoint),
                                resolveErrors: Utils.getConfigurationWithKey(Constants.Configurations.ResolveErrors),
                                validStatues: Utils.getConfigurationWithKey(Constants.Configurations.ValidStatuses)
                            }
                        };
                        let orderStatus = await PFManager.getOrderStatus(checkStatusPayload);
                        if(orderStatus?.success && orderStatus?.data?.status)
                        {
                            let validStatus = this.getValidStatus(currentStatus, orderStatus?.data?.status);
                            if(validStatus)
                            {
                                const success = this.logSuccess(tag,body);
                                return success;
                            }
                        }
                    }
                    if(this.retryConfig())
                    {
                        return this.post(endpoint,body,shipmentKey,currentStatus,retryCount+1);
                    }
                    
                }
                logger.error({
                    message: error.name === 'AbortError' ? error.name : error.message,
                    tag: endpoint.toString().split("/")[2],
                    payLoad: body
                });
            }
            throw error;

        }



    };

    private static allowAPILoggin = (endpoint: any) => {

        if (endpoint && endpoint.length > 0 && endpoint.indexOf("/oms/") > -1) {
            return true;
        }

        return false;
    }

    private static logSuccess = (tag:any, payload:any) => {

        const successLogging: ILogger = {
            message:"",
            tag:tag,
            payLoad: payload
        }
        logger.info(successLogging);

        //Dummy Success Response
        let data = {
            IsSuccess: 'Y'
        }

        return {success:true, msg:"", data:data};
    };

    private static defaultAPITimeout = (endpoint?: any) => {
        const timeout =  Utils.getConfigurationWithKey(Constants.Configurations.APITimeout);
        if(timeout>0 && endpoint.includes("oms/"))
        {
            return timeout;
        }
        return 50 * 1000;
    }

    public static getValidStatus = (currentStatus:any,orderStatus:any) => {
        let statuses = Utils.getConfigurationWithKey(Constants.Configurations.ValidStatuses);
        if(statuses)
        {
            const statusesList = JSON.parse(statuses);
            let currentStatusValidStatuses = statusesList?.find((x:any)=>x.currentStatus == currentStatus);
            const validStatuses = currentStatusValidStatuses?.validStatuses;
            if(Array.isArray(validStatuses))
            {
                let validStatus = validStatuses?.some((x:any)=> x == orderStatus);
                return validStatus;
            }

        }
        return false;
    }

    private static handleOMSError = (endpoint:any, inputError:any) => {
        //To check whether we want to exclude logs or not based on endpoints and Error.
        const resolveErrorEndpoints = Utils.getConfigurationWithKey(Constants.Configurations.ResolveErrorsEndpoint);
        const resolveErrors = Utils.getConfigurationWithKey(Constants.Configurations.ResolveErrors);
        let endpoints = resolveErrorEndpoints?.split(",");
        let errors = resolveErrors?.split(",");
        if(endpoints?.length>0 && errors?.length>0)
        {
            let endpointsResult = endpoints.some((x:any)=>x?.trim()?.toLowerCase() == endpoint?.trim()?.toLowerCase());
            let errorsResult = errors.some((x:any)=>inputError?.trim()?.toLowerCase()?.includes(x?.trim()?.toLowerCase()));

            if(endpointsResult && errorsResult)
            {
                return true;
            }

        }
        return false;
    }

    public static orderStatusConfig = () => {
        const checkStatus =  Utils.getConfigurationWithKey(Constants.Configurations.CheckStatus);
        return checkStatus;
    }

    public static retryConfig = () => {
        const retryConfig =  Utils.getConfigurationWithKey(Constants.Configurations.RetryConfig);
        return retryConfig;
    }

    private static checkLogsEndpoint = (endpoint: any)=>{
        let endpointConfig = Utils.getConfigurationWithKey(Constants.Configurations.LogsEndpoint);
        if(!endpointConfig)
        {
            return true;
        }
        else{
            let endpointsArray = endpointConfig.split(",");
            return endpointsArray?.length>0? endpointsArray.some((x:any)=>x.trim()==endpoint): false;
        }
    }

    static patch = async (endpoint: string, body: Partial<any>): Promise<any> => {
        let token = IdentityManager.AccessToken;
        const request = await fetch(endpoint, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: { 'Authorization': `Bearer ${token}` }
        });
        if (request.status >= 400) {
            return { success: false, msg: request.status, data: "" };
        }
        else {
            const response = await request.json();
            return { success: true, msg: "", data: response };
        }
    };


}

export default ApiService;
