import React, { useState } from 'react';
import { Button, IconButton, Paper, Toolbar, Typography, Drawer, Card, CardContent, TextField} from '@mui/material';
import Utils from '../../Common/Utils';
import { ArrowBackIcon, RestartAltIcon, FlashOffIcon, CheckIcon, CloseIcon } from "../Shared/SharedIcons";
import Constants from '../../Common/Constants';
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import { NotificationPopup } from '../Shared/NotificationPopup'
import {
    FrameSourceState,
    Brush,
    Color,
} from "scandit-web-datacapture-core";
import type {TrackedBarcode} from "scandit-web-datacapture-barcode";
import {BarcodeTrackingBasicOverlay} from "scandit-web-datacapture-barcode";
const MobileBarCodeMatrixScan = (props: any) => {
    const [openDrawer, setDrawer] = useState(false);
    const [isOneScanned, setisOneScanned] = useState(false);
    const [openGeneralError, setOpenGeneralError] = useState(false);
    const [errorGeneralMsg, setErrorGeneralMsg] = useState("");
    const closeGeneralErrorModel = () => {
        setOpenGeneralError(false);
        setErrorGeneralMsg("");
    }
    const showGeneralErrorModel = async (msg: any) => {

        setOpenGeneralError(true);
        setErrorGeneralMsg(msg);

    }
    let errorGeneralPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeGeneralErrorModel,
            color: Constants.Colors.red,
        }
    ];
    let GeneralError: INotificationPopupSettings = {
        open: openGeneralError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorGeneralMsg,
        draggable: false,
        handleClose: closeGeneralErrorModel,
        actions: errorGeneralPopupButtons
    }
    const onScanned = (data: any) => {
        props.setSKUNumberForScan(data)
        setisOneScanned(true)
        setDrawer(true);
    }

    const changeScanData = (event: any) => {
        props.setSKUNumberForScan(event.target.value)
    }

    const scanDone = async () => {
        setDrawer(false);
        props.onScanned(props.skuToScan, false);
    };

    const retry = async () => {
        setDrawer(false);
        setisOneScanned(false);
    };

    const initMatrixScan = async () => {
        try {
            props?.view?.connectToElement(document.getElementById("data-capture-view"));
            props?.view?.showProgressBar();
            props?.view?.setProgressBarPercentage(null);
            props?.view?.setProgressBarMessage(Utils.getConfigurationWithKey("ScanLoaderMessage"));
            await props?.view.setContext(props?.context);
            if(props?.barCodeOverlay)
            {
                props.barCodeOverlay.listener = {
                    brushForTrackedBarcode: (overlay: BarcodeTrackingBasicOverlay, trackedBarcode: TrackedBarcode) => {
                        const fillColor = Color.fromRGBA(60, 179, 113, 0.4);
                        const strokeColor = Color.fromRGBA(60, 179, 113, 0.4);
                        const strokeWidth = 2;

                        return new Brush(fillColor, strokeColor, strokeWidth);
                    },
                    didTapTrackedBarcode: (overlay: BarcodeTrackingBasicOverlay, trackedBarcode: TrackedBarcode) => {
                        onScanned(trackedBarcode.barcode.data ?? "");
                    }
                };
            }
            await props?.camera?.switchToDesiredState(FrameSourceState.On);
            await props?.barCodeTracking?.setEnabled(true);
            props?.view?.hideProgressBar();
        }
        catch (err: any) {
            console.error(err);
            let errorString = JSON.stringify(err)
            if (errorString == "{}") {
                errorString = err.toString();
            }
            showGeneralErrorModel(errorString);
        }
    }
    const cleanUp = async () => {
        try {
            await props?.camera?.switchToDesiredState(FrameSourceState.Off);
            props?.view?.detachFromElement();
        }
        catch (err: any) {
            console.error(err);
            let errorString = JSON.stringify(err)
            if (errorString == "{}") {
                errorString = err.toString();
            }
            showGeneralErrorModel(errorString);
        }

    }
    const goBack = async () => {
        props.onScanned("", true);
    }
    React.useEffect(() => {
        async function start() {
            await initMatrixScan();
            window.scrollTo(0, 0)
        }
        start();
        return () => {
            cleanUp();
        }
    }, []);

    return (
        <div>
            <NotificationPopup {...GeneralError}></NotificationPopup>
            <Drawer
                style={{ zIndex: 99999 }}
                anchor={"top"}
                open={openDrawer}
                onClose={() => { }}
                onClick={() => { }}
            >
                <Card style={{ margin: "6px" }}>
                    <CardContent style={{ padding: "10px" }}>
                        <Typography variant="h5" component="div" style={{ fontSize: "18px" }}>
                            Enter Bar Code
                            <br />
                        </Typography>
                        <br></br>
                        <TextField fullWidth variant={"outlined"} placeholder='SKU#' id="fullWidth" value={props.skuToScan} onChange={(event: any) => { changeScanData(event) }} />
                        <br></br>
                        <br></br>
                        <Typography variant="h5" component="div" style={{ fontSize: "18px" }}>
                            Quantity
                            <br />
                        </Typography>
                        <br></br>
                        <TextField fullWidth value={props.scannedQty} onChange={(event: any) => { props.setScannedQty(event.target.value) }} variant={"outlined"} type={"number"} InputProps={{ inputProps: { min: 1 } }} defaultValue={1} InputLabelProps={{ shrink: false }} label="" placeholder='Quantity' id="fullWidth" />

                        <Button onClick={() => retry()} style={{ marginTop: "20px", marginRight: "5px", width: "48%", color: Constants.Colors.red, borderColor: Constants.Colors.red }} variant='outlined' className='' startIcon={<RestartAltIcon />}>Retry</Button>
                        <Button onClick={() => scanDone()} style={{ marginTop: "20px", width: "48%" }} variant='contained' className={props.skuToScan ? 'btn': 'btn disabled'} disabled={!props.skuToScan} startIcon={<CheckIcon />}>Done</Button>

                    </CardContent>
                </Card>
            </Drawer>
            <div className='containerTypeBarCodeMbl'>
                <Button onClick={() => { setDrawer(true); setisOneScanned(true); }} className='red-outline-btn-Scanner' style={{ borderRadius: "14px" }} variant='contained'>Type your barcode</Button>
            </div>
            <Paper style={{ color: Constants.COLORS.MOBILE_RED, top: 0, left: 0, right: 0, zIndex: 999 }} className="hideWhileRotate">
                <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}

                        onClick={() => goBack()}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="caption" component="div" sx={{ flexGrow: 1 }} style={{ marginRight: 60 }}>
                        {Utils.getConfigurationWithKey("ScanLoaderMessage")}
                    </Typography>
                </Toolbar>
            </Paper>
            {
                <div id="container-data-capture">
                    <div id="data-capture-view"></div>
                </div>
            }

        </div>)
}
export default MobileBarCodeMatrixScan;