import { styled } from '@mui/material/styles';
import Loader from "react-loader-spinner";
import MuiAccordion from '@mui/material/Accordion';
//import MuiAccordionSummary from '@mui/material/AccordionSummary';
//import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, Button, Card, CardContent, Checkbox, Divider, Drawer, Grid, Paper, Stack, TextField, Toolbar, Tooltip, Typography, FormHelperText, SwipeableDrawer, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from "@mui/material";
import Utils from "../Common/Utils";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import SiteBreadCrumbs from "../Subcomponent/Shared/SiteBreadCrumbs";
import Constants from "../Common/Constants";
import { VerticalLinearStepper } from "../Subcomponent/Stepper/VerticalLinearStepper";
import { IVerticalStepper, IStepOption } from "../Models/IVerticalStepper";
import { ArrowBackIosNewOutlinedIcon, ArrowForwardIcon, CancelIcon, CheckBoxOutlinedIcon, DoneIcon, LocalPrintshopOutlinedIcon, LocalShippingOutlinedIcon, MoreVertIcon, QrCodeScannerOutlinedIcon, ReportProblemIcon } from "../Subcomponent/Shared/SharedIcons";
import { Note } from "../Subcomponent/Shared/Note";
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import { INotificationPopupSettings, IButton } from "../Models/INotificationPopupSettings";
import LabelText from "../Common/LabelText";
import { BoxCharacteristics } from '../Subcomponent/PackOrder/AddBox'
import allActions from '../Store/Actions/AllActions';
import PFUtils from '../Common/Process.Fulfillment.Utils';
import PFManager from '../Managers/Process.Fulfillment.Manager';
import PdfIframe from "../Subcomponent/PackOrder/PdfIframe";
import { UnPackagedItems } from '../Subcomponent/PackOrder/UnPackagedItems';
import { PackagedItems } from '../Subcomponent/PackOrder/PackagedItems';
import { PackOrderCustomerDetail } from '../Subcomponent/PackOrder/PackOrderCustomerDetail';
import IBoxCharacteristic from "../Models/IBoxCharacteristic";
import IitemQty from '../Models/IItemQty';
import { PackPrintSlip } from "../Subcomponent/PackOrder/PackPrintSlip"
import OMSManager from "../Managers/Oms.manager";
import { ICustomerDetail } from '../Models/IPickOrder';
import StoreManager from '../Managers/Store.manager'
import OrderManager from '../Managers/Orders.Manager';
import { OmsPackOrderDetail, Container, ShipmentLine2 } from "../Models/OmsPackOrderDetails";
import { FileCopyIcon, SaveIcon, PrintIcon, ShareIcon, AddCommentIcon, CloseIcon, ArrowForwardIosSharpIcon, StarPurple500Icon, CheckIcon, LocalShippingIcon, AddCircleIcon, DoDisturbOnIcon, TimelineIcon, CommentIcon, ArrowBackIcon, PlumbingIcon } from "../Subcomponent/Shared/SharedIcons"
import OrderHistory from '../Subcomponent/Shared/OrderHistory';
import ApiService from "../Common/ApiService";
import { AddAdHoqTrackingNumbers } from "../Subcomponent/PackOrder/AddAdHoqTrackingNumbers"
import logger from '../Common/logger';
import { OrderDetailGrid } from '../Subcomponent/Shared/OrderDetailGrid';
import ShipmentStatusConstants from '../Common/ShipmentStatusConstants';
import PrintManager from '../Managers/Print.Manager';
import { ReportErrorPopup } from '../Subcomponent/Shared/ReportErrorPopup';
import { ServiceNowCnfrmText } from '../Subcomponent/Shared/ServiceNowCnfrmText';

var moment = require('moment');
let step: IStepOption[] = [];
step.push({
    label: "Order Release",
    description: ``,
    time: "Jan 20 2022 13:11:17 ",
});
step.push({
    label: "Customer Contacted",
    description: "dummy",
    time: "Jan 20 2022 13:11:17 ",
});
step.push({ label: "Picking", description: ``, time: "Jan 20 2022 13:11:17" });
step.push({ label: "Packing", description: ``, time: "" });
step.push({ label: "Shipped", description: ``, time: "" });
let verticalStepperOptions: IVerticalStepper = {
    activeStep: "Packing",
    stepsOptions: step,
};
const Accordion = styled((props: any) => (
    <MuiAccordion style={{ minHeight: "35px !important" }} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));
const PackOrder = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryParamStore = urlParams.get('store');
    const pathname = window.location.pathname;
    const shipmentkey = urlParams.get('shipmentkey');
    const orderno = urlParams.get('orderno');
    const [disableMultiBox, setdisableMultiBox] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [blobUrl, setBlobUrl] = useState("");
    const [openError, setOpenError] = useState(false);

    const [openTrackingDrawer, setTrackingDrawer] = React.useState(false);
    const [openDrawer, setDrawer] = useState(false);
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [notes, setNotes] = useState("");
    const [notePopup, setNotesPopup] = useState(false);
    const [steps, setSteps] = useState<IVerticalStepper>(verticalStepperOptions)

    const [shipDateError, setShipDateError] = React.useState(false);
    const [showPdf, SetShowPdf] = useState(false);
    const [loadingOrderLines, setLoadingOrderLines] = useState(true);
    const [isReadyForShip, setIsReadyForShip] = useState(true);
    const [shipped, setShipped] = useState(false);
    const [navigationFulfillment, setNavigationFulfillment] = useState(false);

    const [orderNumber, setOrderNumber] = useState("");
    const [isBundle, setIsBundle] = useState(false);
    const [isADhoqPopup, setADhoqPopup] = useState(false);
    const [isPrintPackOrder, setPrintPackOrder] = useState(0);
    const [openSuccess, setSuccess] = useState(false);
    const [inputListAdhoq, setInputListAdhoq] = useState([{ trackingNo: "", error: false }]);
    const [orderDetails, setOrderDetails] = useState({ fulfillmentType: "", levelOfService: "", pickUpStore: "", storeNumber: "" });

    //Report Error Action Use State
    const [reportErrorAction, setReportErrorAction] = useState("");

    // Report Error Pop Up Use States
    const [openReportError, setOpenReportError] = useState(false);
    const [openAlreadyReportedError, setOpenAlreadyReportedError] = useState(false);
    const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
    const [errorReportMsg, setErrorReportMsg] = useState("");

    //Get Pack Data From Elastic
    const [packOrderDetailsFromElastic, setPackOrderDetailsFromElastic] = useState({} as any);

    let boxCharacteristicList: IBoxCharacteristic[] = useSelector((state: any) => state.PackOrderReducersState.boxCharacteristicList);
    let unpackagedItems: OmsPackOrderDetail = useSelector((state: any) => state.PackOrderReducersState.unpackagedItems);
    let selectedItems = useSelector((state: any) => state.PackOrderReducersState.selectedItems);
    let itemQty = useSelector((state: any) => state.PackOrderReducersState.itemQty);
    let packagedItems: Container[] = useSelector((state: any) => state.PackOrderReducersState.packagedItems);
    let isResidential: boolean = useSelector((state: any) => state.PackOrderReducersState.isResidential);
    let shipDate: Date = useSelector((state: any) => state.PackOrderReducersState.shipDate);
    const isHistoryLoading = useSelector((state: any) => state.OrderHistoryReducerState.isLoading);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openLoadingOrderLines = () => {
        setLoadingOrderLines(true)
    }
    const closeLoadingOrderLines = () => {
        setLoadingOrderLines(false)
    }
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const changeResidential = () => {
        let changeIsResidential = !isResidential
        dispatch(allActions.packOrderActions.changeResidential(changeIsResidential));
    }
    const changeShipDate = (shipDate: any) => {
        dispatch(allActions.packOrderActions.changeShipDate(shipDate));
    }
    const closePdfiframe = () => {
        SetShowPdf(false);
    }
    const openPdfiframe = () => {
        SetShowPdf(true);
    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    const popUpBack = () => {
        navigate(-1)
    }

    // Popups

    // Show Pop ups If Error is already reported
    const handleErrorAlreadyReported = () => {
        // setOpenAlreadyReportedError(true);
        setOpenReportError(false);
    }

    // OPEN Confirm Report Error
    const reportConfirmErrorPopup = () => {
        setOpenConfirmReportError(true);
        setOpenError(false);

    }

    // CLOSE Report Error POP up
    const closeErrorReportPopup = () => {
        setOpenReportError(false)
    }

    // Report Error Pop up
    let ReportError: INotificationPopupSettings = {
        open: openReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: "Report Error",
        msg: "",
        isCustomJSX: true,
        customJSX: <ReportErrorPopup handleErrorAlreadyReported={handleErrorAlreadyReported} errorMessage={errorReportMsg} closeErrorReportPopup={closeErrorReportPopup}  urlPathname={pathname} reportErrorAction={reportErrorAction} ></ReportErrorPopup>,
        draggable: false,
        handleClose: closeErrorReportPopup,
        actions: [],
        fullWidth: true,
        maxWidth: "lg"
    }

    // OPEN Confirm Report Error POPup
    const reportErrorPopup = () => {
        setOpenReportError(true);
        setOpenConfirmReportError(false)
    }

    // CLOSE Confirm Report Error
    const closeConfirmReportError = () => {
        setOpenConfirmReportError(false);
    }
    let confirmReportErrorPopupButtons: IButton[] = [
        {
            text: "Continue",
            icon: <ArrowForwardIcon />,
            action: reportErrorPopup,
            color: Constants.Colors.red,
        }
    ];
    let confirmReportError: INotificationPopupSettings = {
        open: openConfirmReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: Constants.MESSAGES.SN_CNFRM_TITLE,
        msg: "",
        customJSX: <ServiceNowCnfrmText></ServiceNowCnfrmText>,
        isCustomJSX: true,
        draggable: false,
        handleClose: closeConfirmReportError,
        actions: confirmReportErrorPopupButtons
    }

  
    // Error Popup

    // Buttons When there is no Action
    let errorPopupCloseButtons: IButton[] = [{
        text: "Close",
        icon: <CloseIcon />,
        action: closeErrorPopup,
        color: Constants.Colors.red,
    }];

    // Buttons When there is Action
    let errorPopupButtons: IButton[] = [
        {
            text: "Report Error",
            icon: <ReportProblemIcon />,
            action: reportConfirmErrorPopup,
            color: Constants.Colors.orange,
        },
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        }
    ];

    if (Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNFlag) === false) {
        errorPopupButtons.shift()
    };
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: reportErrorAction ? errorPopupButtons : errorPopupCloseButtons
    }



    const closeNotes = () => {
        setNotesPopup(false);
    };

    const changeNote = (event: any) => {
        setNotes(event.target.value);
    };
    const showErrorMessageFromChild = (errorMessage: any) => {
        setErrorMsg(errorMessage);
        setErrorReportMsg(errorMessage);
        setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_PACKAGED)
        openErrorPopup();
    }
    const addNotes = () => {
        if (notes != "") {
            let index = Utils.getStepperIndex(verticalStepperOptions, "Packing");
            step.splice(index, 0, {
                label: "Note",
                description: notes,
                time: "Jan 20 2022 13:11:17 ",
            });
            verticalStepperOptions.stepsOptions = step;
            setSteps(verticalStepperOptions);
        }
        setNotesPopup(false);
    };
    let notesPopupButtons: IButton[] = [
        {
            text: "Add",
            icon: <AddCommentIcon />,
            action: addNotes,
            color: Constants.Colors.blue,
        },
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeNotes,
            color: Constants.Colors.grey,
        },
    ];
    let notesCustomerPopup: INotificationPopupSettings = {
        open: notePopup,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.PickOrder.Note,
        msg: "",
        handleClose: closeNotes,
        isCustomJSX: true,
        customJSX: <Note note={notes} changeNote={changeNote}></Note>,
        actions: notesPopupButtons,
    };

    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById("bar") as any;
        var mblBar = document.getElementById("mblBar") as any;
        if (bar) {
            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);

            if (window.pageYOffset > startProductBarPos) {
                bar.classList.add("scrolledTopFix");
            } else {
                bar.classList.remove("scrolledTopFix");
            }
        }
    };

    const getValidation = () => {
        let error = boxCharacteristicList.some((box: any) => {
                const errorValues = Object.values(box.Error); // Get all values from the error object
                return errorValues.some(value => value === true); // Check if any value is true
        });
        
        if (shipDate == null || shipDate instanceof Date == false || isNaN(shipDate.valueOf())) {
            error = true;
        }
        return error;
    }

    const checkBoxValidation = () => {
        let isError = false;
        //
        boxCharacteristicList.forEach((box: any, index: any) => {
            let Error = {
                quantity: false,
                dimensions: false,
                weight: false,
                length: false,
                width: false,
                height: false
            }
            if (box.weight == "" || box.weight <= 0) {
                Error.weight = true
                isError = true
            }
            if (box.dimensions == "") {
                Error.dimensions = true
                isError = true
            }
            if (!box.length || box.length == "") {
                Error.length = true
                isError = true
            }
            if (!box.width || box.width == "") {
                Error.width = true
                isError = true
            }
            if (!box.height || box.height == "") {
                Error.height = true
                isError = true
            }
            let payload = {
                index: box.index,
                value: Error,
                key: "Error"
            }
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            if (shipDate == null || shipDate instanceof Date == false || isNaN(shipDate.valueOf())) {
                setShipDateError(true)
                isError = true
            }
            else {
                setShipDateError(false)
            }
        });
        return isError
    }
    const resetBoxSide = () => {
        const boxCharerisitic = JSON.parse(JSON.stringify(Constants.boxCharacteristic));
        dispatch(allActions.packOrderActions.changeSelectedItems([]));
        dispatch(allActions.packOrderActions.initilizeBoxCharacteristicList(boxCharerisitic));
        if (isResidential == false) {
            changeResidential()
        }
        changeShipDate(new Date())
    }
    //const resetUnpackagedSide = () => {
    //    dispatch(allActions.packOrderActions.changePackagedItem([]));
    //}
    const changeQuantity = (qtyToShip: number, qtyToPack: number, id: number) => {
        //let newQuantity: IitemQty[] = [...itemQty]
        //let qtyOfItem: IitemQty = newQuantity.filter((qty) => { return qty.id == id })[0]
        //qtyOfItem.toPack = qtyToPack;
        //qtyOfItem.toShip = qtyToShip;
        //dispatch(allActions.packOrderActions.changeItemQuantity(newQuantity));
        //setQuantity(event.target.value)
    }

    const savePackingOrderHistory = async () => {
        let isPackingExists = await checkPackingExists();
        let result = await OMSManager.getPackOrdersDetail(shipmentkey);
        let data: OmsPackOrderDetail = result.data;
        let isReadyForShip = true;
        if (data.ShipmentLines?.ShipmentLine?.length > 0) {
            data.ShipmentLines.ShipmentLine.forEach((item: ShipmentLine2) => {
                if (Number(item.QtyAvailableToPack) > 0) {
                    isReadyForShip = false
                }
            })
        }
        // To Log Packing Order History
        if (isReadyForShip && isPackingExists.length == 0 &&
            result.data.Status == ShipmentStatusConstants.ShipmentPacked) {
            //let statusDate = await getStatusDate();
            // let transactionDate = moment.utc(moment.tz("America/Los_Angeles")).toISOString();
            // if(statusDate)
            // {
            //     transactionDate = statusDate;
            // }
            await logPackingOrderHistory();
            dispatch(allActions.orderHistoryActions.forceHistoryLoad());
        }

    }


    const createShipment = async () => {


        let error = checkBoxValidation();
        if (!error) {
            console.log("createShipment")
            showActionLoader();
            let trackingNumbers = [] as any;
            let totalPrice = [] as any;
            let totalPackagesWeight = [] as any;
            let packageDimension = [] as any;
            let shippingService = [] as any;
            let shippingCarrier = [] as any;
            // same data accross one shipment
            let levelOfService = unpackagedItems.LevelOfService
            let selectedStore = StoreManager.currentStore
            let toAddress = unpackagedItems.ToAddress;
            let billToAddress = unpackagedItems.BillToAddress;
            let orderNo = unpackagedItems.ShipmentLines.ShipmentLine[0].OrderNo
            let ShipmentKey = unpackagedItems.ShipmentKey;

            //one/multiple items data
            let ShipmentLineKey = [] as string[];
            let OrderLineKey = [] as string[];

            //loop over selected items to get ShipmentLineKey and OrderLineKey 
            selectedItems.forEach((selectedItem: string) => {
                let selectedItemDetail: ShipmentLine2[] = unpackagedItems.ShipmentLines.ShipmentLine.filter((item: any) => { return item.ShipmentLineKey == selectedItem });
                if (selectedItemDetail.length > 0) {
                    ShipmentLineKey.push(selectedItemDetail[0].ShipmentLineKey)
                    OrderLineKey.push(selectedItemDetail[0].OrderLineKey);
                }
            });

            // create boxes
            let createLabelpayloadList = [] as any;
            boxCharacteristicList.forEach(box => {

                let requestData = PFUtils.parseData_CreateShipment_exstore(ShipmentKey, orderNo, ShipmentLineKey, OrderLineKey, box, selectedStore, levelOfService, toAddress, billToAddress, shipDate, isResidential, false)
                //
                if (requestData.success == true) {
                    let payload = requestData.data
                    createLabelpayloadList.push(payload);
                }
                else {
                    setTrackingDrawer(false)
                    setErrorMsg(requestData.msg);
                    setErrorReportMsg(requestData.msg);
                    setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_CREATE_SHIPMENT)
                    hideActionLoader();
                    openErrorPopup();
                }
            });
            if (boxCharacteristicList.length == createLabelpayloadList.length) {
                let response: any = null;
                // get response of all boxes created
                if (Utils.shippingAPI()) {
                    response = await ApiService.post(Constants.PFServicesRoutes.CreateShipment, createLabelpayloadList)
                }
                else {
                    response = await ApiService.post("/pf/sendRequest", createLabelpayloadList);
                }
                if (response?.success == true) {
                    for (const element of response.data) {
                        let result = element?.response;
                        let payload = element?.payLoad;
                        if (result?.success) {
                            console.log(result, "/pf/sendRequest");
                            if (result.printData) {
                                result.printData.forEach((r: any) => {
                                    PrintManager.sendPrinterRequest(r);
                                });
                            }
                            if (result.data.ERROR_CODE == "0") {
                                trackingNumbers.push(result.data.PACKAGES.PACKAGE.CARRIER_PACKAGE_REFERENCE) // make list of tracking numbers
                                totalPrice.push(result?.data?.TOTAL);
                                shippingService.push(result?.data?.SERVICE);
                                shippingCarrier.push(result?.data?.CARRIER);
                                totalPackagesWeight.push(payload?.PACKAGES[0]?.WEIGHT);
                                packageDimension.push(payload?.PACKAGES[0]?.DIMENSION);
                            }
                            else {
                                setTrackingDrawer(false)
                                setErrorMsg("Error While Creating Label: " + result.data.ERROR_MESSAGE);
                                setErrorReportMsg("Error While Creating Label: " + result.data.ERROR_MESSAGE);
                                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_CREATE_LABEL);
                                hideActionLoader();
                                openErrorPopup();
                                break;
                            }
                        }
                        else {
                            setTrackingDrawer(false)
                            setErrorMsg("Error While Creating Label: " + result?.msg);
                            setErrorReportMsg("Error While Creating Label: " + result?.msg);
                            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_CREATE_LABEL);
                            hideActionLoader();
                            openErrorPopup();
                            break;
                        }
                    }
                    // Logging for Create Label
                    //
                    Utils.writeSendRequestLogs(response, Constants.LOG_TYPES.CREATE_LABEL);


                    // number of boxes and tracking numbers are same then continue
                    if (trackingNumbers.length == boxCharacteristicList.length) {
                        try {
                            let omsContainerResponse = await OMSManager.GCPackUpdateTrackingNo(ShipmentKey, ShipmentLineKey, trackingNumbers, unpackagedItems, itemQty, totalPrice, totalPackagesWeight, packageDimension, shippingService, shippingCarrier, unpackagedItems.Status);
                            if (omsContainerResponse.success == true) {
                                if (omsContainerResponse?.data?.errors != undefined) {
                                    setTrackingDrawer(false)
                                    setErrorMsg(omsContainerResponse.data.errors[0]?.ErrorDescription);
                                    setErrorReportMsg(omsContainerResponse.data.errors[0]?.ErrorDescription);
                                    setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_UPDATE_TRACKING_NO);
                                    hideActionLoader();
                                    openErrorPopup();
                                }
                                else {
                                    setTrackingDrawer(false)
                                    resetBoxSide();
                                    await getPackDetailFromOms();
                                    await savePackingOrderHistory();
                                    hideActionLoader();
                                }
                            }
                            else {
                                setTrackingDrawer(false)
                                setErrorMsg("Error While Creating Label");
                                setErrorReportMsg("Error While Creating Label");
                                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_CREATE_LABEL);
                                hideActionLoader();
                                openErrorPopup();
                            }
                        }
                        catch {
                            setTrackingDrawer(false)
                            setErrorMsg("Error While Creating Label");
                            setErrorReportMsg("Error While Creating Label");
                            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_CREATE_LABEL);
                            hideActionLoader();
                            openErrorPopup();
                        }
                    }
                }
                else {
                    setTrackingDrawer(false)
                    setErrorMsg("Error While Creating Label " + response.msg)
                    setErrorReportMsg("Error While Creating Label " + response.msg);
                    setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_CREATE_LABEL);
                    hideActionLoader();
                    openErrorPopup();
                }
            }
        }
    }
    const createShipmentAdhoq = async (trackingNumbers: any) => {
        setADhoqPopup(false);
        showActionLoader();
        let ShipmentKey = unpackagedItems.ShipmentKey;

        //one/multiple items data
        let ShipmentLineKey = [] as string[];
        let OrderLineKey = [] as string[];

        //loop over selected items to get ShipmentLineKey and OrderLineKey 
        selectedItems.forEach((selectedItem: string) => {
            let selectedItemDetail: ShipmentLine2[] = unpackagedItems.ShipmentLines.ShipmentLine.filter((item: any) => { return item.ShipmentLineKey == selectedItem });
            if (selectedItemDetail.length > 0) {
                ShipmentLineKey.push(selectedItemDetail[0].ShipmentLineKey)
                OrderLineKey.push(selectedItemDetail[0].OrderLineKey);
            }
        });
        try {
            let omsContainerResponse = await OMSManager.GCPackUpdateTrackingNo(ShipmentKey, ShipmentLineKey, trackingNumbers, unpackagedItems, itemQty, [], [], [], [], [], unpackagedItems.Status);
            if (omsContainerResponse.success == true) {
                if (omsContainerResponse?.data?.errors != undefined) {
                    setErrorMsg(omsContainerResponse.data.errors[0]?.ErrorDescription);
                    setErrorReportMsg(omsContainerResponse.data.errors[0]?.ErrorDescription);
                    setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_UPDATE_TRACKING_NO);
                    hideActionLoader();
                    openErrorPopup();
                }
                else {
                    resetBoxSide()
                    await getPackDetailFromOms();
                    await savePackingOrderHistory();
                    hideActionLoader();
                }
            }
            else {
                setErrorMsg("Error While Creating Label");
                setErrorReportMsg("Error While Creating Label");
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_CREATE_LABEL);
                hideActionLoader();
                openErrorPopup();
            }
        }
        catch {
            setErrorMsg("Error While Creating Label");
            setErrorReportMsg("Error While Creating Label");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_CREATE_LABEL);
            hideActionLoader();
            openErrorPopup();
        }
    }
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof obj.offsetParent != "undefined" && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        } else if (obj && obj.y) curtop += obj.y;
        return curtop;
    }
    const getCustomerDetails = (data: OmsPackOrderDetail) => {
        let initialCustomerDetail: ICustomerDetail = {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            state: ""
        }
        if (data.ToAddress != undefined) {
            try {
                try {
                    initialCustomerDetail.name = data.ShipmentLines.ShipmentLine[0].Order.CustomerFirstName
                }
                catch {
                    initialCustomerDetail.name = ""
                }
                try {
                    initialCustomerDetail.name = initialCustomerDetail.name + " " + data.ShipmentLines.ShipmentLine[0].Order.CustomerLastName
                }
                catch {

                }
                if (initialCustomerDetail.name.trim() == "") {
                    initialCustomerDetail.name = data.BillToAddress.FirstName + " " + data.BillToAddress.LastName
                }
                try {
                    initialCustomerDetail.email = data.ShipmentLines.ShipmentLine[0].Order.CustomerEMailID
                }
                catch {
                    initialCustomerDetail.email = ""
                }
                try {
                    initialCustomerDetail.phone = data.ShipmentLines.ShipmentLine[0].Order.CustomerPhoneNo
                }
                catch {
                    initialCustomerDetail.phone = ""
                }
                if (initialCustomerDetail.phone.trim() == "") {
                    initialCustomerDetail.phone = data.BillToAddress.DayPhone
                }
                if (initialCustomerDetail.phone.trim() == "") {
                    initialCustomerDetail.phone = data.BillToAddress.MobilePhone
                }
                if (data.ToAddress.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.ToAddress.City
                }
                if (data.ToAddress.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.ToAddress.ZipCode
                }
                if (data.ToAddress.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.ToAddress.AddressLine1
                }
                if (data.ToAddress.hasOwnProperty("AddressLine2") && data.ToAddress.AddressLine2.trim() != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.ToAddress.AddressLine2
                }
                if (data.ToAddress.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.ToAddress.State
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        return initialCustomerDetail
    }
    const checkPackingExists = async () => {
        let orderHistory: any = [];
        await ApiService.get(`/pf/getOrderHistoryPacking?shipmentKey=` + shipmentkey).then((responseOrderHistory: any) => {

            orderHistory = responseOrderHistory.data.hits.hits;
        });
        return orderHistory
    }

    // const getStatusDate = async () =>{
    //     let statusDate: any = undefined;
    //     await ApiService.get(`/pf/getOrderFromExStore?shipmentKey=` + shipmentkey).then((response: any) => {
    //         statusDate = response.data.hits.hits.length > 0 ? response.data.hits.hits[0]?._source?.Shipment?.StatusDate : undefined;
    //     })
    //     return statusDate;
    // }

    const getPackDetailFromOms = async (isAfterCancel?: any) => {
        let result = [] as any
        openLoadingOrderLines();
        try {
            result = await OMSManager.getPackOrdersDetail(shipmentkey)
        }
        catch {
            setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
            setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_ORDER_DETAIL);
            closeLoadingOrderLines();
            openErrorPopup();
        }
        // try {
        //     let response = await OMSManager.getCustomerDetailsFromElastic(shipmentkey)
        //     const data = response?.data?.hits?.hits[0]?._source.Shipment?.CustomerInfo
        //     dispatch(allActions.pickOrderActions.getCustomerInfoFromElastic(OMSManager.getCustomerDetailsOMS(data)))
        // }
        // catch (error) {
        //     console.log(error);
        //     throw error;
        // }

        if (result.success && result.data.ShipmentLines != undefined && result.data.ShipmentLines.ShipmentLine != undefined) {
            if (result.data.Status == ShipmentStatusConstants.Shipped || result.data.Status == ShipmentStatusConstants.ShipmentShipped) {
                setShipped(true);
            }
            // if refresh is called after the cancelling item then check if there are any items left oherwise redirect to fulfillment page
            if (isAfterCancel && (!result.data.ShipmentLines.ShipmentLine || result.data.ShipmentLines.ShipmentLine.length == 0)) {
                navigate("/FulfillOrders");
            }

            let data: OmsPackOrderDetail = result.data
            if (data.ShipmentLines?.ShipmentLine?.length > 0 && data.ShipmentLines.ShipmentLine[0].OrderNo != undefined &&
                data.ShipmentLines.ShipmentLine[0].OrderNo != "" && data.LevelOfService != undefined && data.LevelOfService != ""
            ) {
                resetBoxSide()
                dispatch(allActions.packOrderActions.addUnpackagedItems(data));
                if (data.Containers?.Container?.length > 0) {
                    dispatch(allActions.packOrderActions.changePackagedItem(data.Containers.Container));
                } else {
                    dispatch(allActions.packOrderActions.changePackagedItem([]));
                }
                let itemQuantity: IitemQty[] = []
                let isReadyForShip = true;
                let isBundle = false;
                data.ShipmentLines.ShipmentLine.forEach((item: ShipmentLine2) => {
                    itemQuantity.push({ id: item.ShipmentLineKey, toPack: item.QtyAvailableToPack, toShip: item.QtyAvailableToPack })
                    if (Number(item.QtyAvailableToPack) > 0) {
                        isReadyForShip = false
                    }
                    if (item.OrderLine.KitCode != "") {
                        isBundle = true
                    }
                })
                let orderDetails = {
                    fulfillmentType: data?.FulfillmentType,
                    levelOfService: data?.LevelOfService,
                    pickUpStore: data.ShipmentLines.ShipmentLine[0].Order?.Extn?.ExtnPickupStoreID,
                    storeNumber: data?.ShipNode?.ShipNode
                }
                setOrderDetails(orderDetails);
                let isPackingExists = await checkPackingExists();
                //To Delete Log
                if (!isReadyForShip && isPackingExists.length > 0) {
                    let docId = isPackingExists[0]._id;
                    let response = await OrderManager.deleteLogByDocId({ docId: docId });
                    dispatch(allActions.orderHistoryActions.forceHistoryLoad());
                }
                setIsReadyForShip(isReadyForShip)
                setIsBundle(isBundle)
                dispatch(allActions.packOrderActions.changeItemQuantity(itemQuantity));
                setOrderNumber(data.ShipmentLines.ShipmentLine[0].OrderNo);
                if (!Utils.customerInfoFromElasticOrNot()) {

                    dispatch(allActions.packOrderActions.changeCustomerDetail(getCustomerDetails(data)));


                }
                if (Utils.customerInfoFromElasticOrNot()) {
                    try {
                        let response = await OMSManager.getCustomerDetailsFromElastic(shipmentkey)
                        const data = response?.data?.hits?.hits[0]?._source.Shipment?.CustomerInfo
                        dispatch(allActions.packOrderActions.changeCustomerDetail(OMSManager.getCustomerDetails(data)))
                    }
                    catch (error) {
                        console.log(error);
                        throw error;
                    }
                }
                let shipmentDataFromShippingCenter = await PFManager.getShipmentDataFromShippingCenter(data.ShipmentKey)
                if (shipmentDataFromShippingCenter.success) {
                    dispatch(allActions.packOrderActions.changeShipmentDataFromShippingCenter(shipmentDataFromShippingCenter.data));
                }

                closeLoadingOrderLines();
            }
            else {
                let errorMsg = "";
                if (!result.success && result.msg) {
                    errorMsg = result.msg;
                }
                else if ((result.success && result.data?.IsShipmentListEmpty == 'Y')
                    || (result.success && !result.data.ShipmentLines.ShipmentLine)) {
                    errorMsg = LabelText.noShipmentLines;
                }
                else {
                    errorMsg = LabelText.generalErrorMessage
                }
                setErrorMsg(errorMsg);
                setErrorReportMsg(errorMsg);
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_ORDER_DETAIL);
                openErrorPopup();
            }

        }
        else {
            let message = ""
            if (result.success && result.data.Status == "9000") {
                message = "This Order has been canceled"
            }
            else {
                message = "Something went wrong please contact the IT Help Desk for assistance"
            }
            setErrorMsg(message)
            setErrorReportMsg(message);
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_ORDER_DETAIL);
            openErrorPopup();
        }
    }
    const printPackSlip = () => {
        setPrintPackOrder(isPrintPackOrder + 1)
    }
    const logShipHistory = async () => {
        await OrderManager.saveOrderHistory({
            shipmentKey: shipmentkey,
            logType: "history",
            transactionType: Constants.OrderHistoryTypes.Shipped,
            message: notes,
            transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
        });
        dispatch(allActions.orderHistoryActions.forceHistoryLoad());
    }
    const logPackingOrderHistory = async () => {
        await OrderManager.saveOrderHistory({
            shipmentKey: shipmentkey,
            logType: "history",
            transactionType: Constants.OrderHistoryTypes.Packing,
            message: notes,
            transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
        });
    }

    const getCurrentStatus = async () => {
        let currentStatus = "";
        const payload = {
            shipmentKey: unpackagedItems.ShipmentKey,
            endpoint:"PackOrder"
        };
        let orderStatus = await PFManager.getOrderStatus(payload);
        if(orderStatus?.success && orderStatus?.data?.status)
        {
            currentStatus = orderStatus.data.status;
        }
        return currentStatus;
    }

    const shipOrder = async () => {
        showActionLoader();

        const currentStatus = await getCurrentStatus();
        OMSManager.GCPackShipConfirm(unpackagedItems.ShipmentKey, unpackagedItems.FulfillmentType, unpackagedItems.OrderHeaderKey, currentStatus).then(async (result: any) => {
            if (result.success && result.data.IsSuccess == 'Y') {

                // Box Number Feature
                if (packOrderDetailsFromElastic?.IsShipToStore === "Y" && packOrderDetailsFromElastic?.ExtnPickupStoreID) {
                    let shipmentArr: any = []
                    packagedItems.forEach((element: any) => {
                        element?.ContainerDetails?.ContainerDetail.forEach((detail: any) => {
                            let conDetailObj = {
                                identifier: element.TrackingNo,
                                boxNumber: 1,
                                FulfillmentType: unpackagedItems?.FulfillmentType,
                                ItemID: Utils.getEIDUsingShipmentLineKey(detail.ShipmentLineKey, unpackagedItems.ShipmentLines.ShipmentLine),
                                ExtnPOSItemID: detail?.ItemID,
                                ItemDesc: detail?.ItemDesc
                            }
                            shipmentArr.push(conDetailObj)

                        })
                    });
                    await OrderManager.saveOrderHistory({
                        orderNo: orderNumber,
                        shipmentKey: shipmentkey,
                        logType: Constants.boxNumberHistory,
                        boxNumberList: JSON.stringify(shipmentArr)
                    });
                }
                //*******End of Box Number Feature ******//


                logShipHistory();
                setPrintPackOrder(isPrintPackOrder + 1)
                hideActionLoader();
                setSuccess(true);
                setShipped(true);
            }
            else {
                let errorMsg = "";
                hideActionLoader();
                if (!result.success && result.msg) {
                    errorMsg = result.msg;
                }
                else {
                    errorMsg = "Something went wrong please contact the IT Help Desk for assistance";

                }
                setErrorMsg(errorMsg);
                setErrorReportMsg(errorMsg);
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_SHIP_CONFIRM);
                openErrorPopup();
            }
        }).catch(() => {
            hideActionLoader();
            setErrorMsg("Something went wrong please contact the IT Help Desk for assistance")
            setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PACK_SHIP_CONFIRM);
            openErrorPopup();
        })
    }

    React.useEffect(() => {
        async function getPackDetail() {
            await getPackDetailFromOms();
        }
        getPackDetail()
        return () => {
            // cleaer this data as these are also being used by kcdc view
            dispatch(allActions.packOrderActions.changeResidential(false));
            dispatch(allActions.packOrderActions.changeShipDate(new Date()));
            resetBoxSide();
            dispatch(allActions.packOrderActions.changeSelectedItems([]));
        }

    }, []);



    React.useEffect(() => {
        if (queryParamStore && props.store?.length == 1 && props.store[0].id != queryParamStore) {
            openNavigationFulfillment();
        }
    }, [props.store]);

    React.useEffect(() => {
        async function getPackDataFromElastic() {
            await ApiService.get(`/pf/getOrderFromExStore?shipmentKey=` + shipmentkey).then((response: any) => {
                let orderDetail = response.data.hits.hits.length > 0 ? response.data.hits.hits[0]._source.Shipment : {};
                setPackOrderDetailsFromElastic(orderDetail);
            })
        }
        getPackDataFromElastic()
    }, [props.store]);

    const createContainerFromAdhoqTrackingNumbers = () => {
        const list = [...inputListAdhoq];
        let anyError = false
        list.forEach((item) => {
            if (item.trackingNo == "") {
                item.error = true
                anyError = true
            }
        })
        setInputListAdhoq(list);
        if (!anyError) {
            let trackingNumberList = [] as any;
            trackingNumberList = list.map((item) => {
                return item.trackingNo
            })
            createShipmentAdhoq(trackingNumberList)
        }
    }
    const closeADhoqPopup = () => {
        setADhoqPopup(false);
    }
    const openADhoqPopup = () => {
        setInputListAdhoq([{ trackingNo: "", error: false }]);
        setADhoqPopup(true);
    }
    let aDhoqPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeADhoqPopup,
            color: Constants.Colors.grey,
        },
        {
            text: "Update Tracking No",
            icon: <CheckIcon />,
            action: createContainerFromAdhoqTrackingNumbers,
            color: Constants.Colors.red,
        }
    ]
    let ADhoqPopup: INotificationPopupSettings = {
        open: isADhoqPopup,
        type: Constants.NotificationPopupType.Info,
        title: "Add Tracking No",
        msg: "",
        handleClose: closeADhoqPopup,
        isCustomJSX: true,
        customJSX: <AddAdHoqTrackingNumbers inputListAdhoq={inputListAdhoq} setInputListAdhoq={setInputListAdhoq} isBundle={isBundle} disableMultiBox={disableMultiBox}></AddAdHoqTrackingNumbers>,
        actions: aDhoqPopupButtons,
        fullWidth: true,
    }
    const closeSuccess = () => {
        setSuccess(false)
    }
    const openNavigationFulfillment = () => {
        setNavigationFulfillment(true);
    }
    // const openSuccess = () => {
    //     setSuccess(true)
    // }
    const navigateToFulfillment = () => {
        navigate("/FulfillOrders");
    }
    let successPopupButtons: IButton[] = [
        {
            text: "To Fulfillment List",
            icon: <DoneIcon />,
            action: navigateToFulfillment,
            color: Constants.Colors.red,
        },
        {
            text: "Print Pack Slip",
            icon: <PrintIcon />,
            action: printPackSlip,
            color: Constants.Colors.blue,
        }
    ];
    let success: INotificationPopupSettings = {
        open: openSuccess,
        type: Constants.NotificationPopupType.Success,
        title: "Success",
        msg: "Order Shipped",
        draggable: false,
        handleClose: closeSuccess,
        actions: successPopupButtons
    }

    let navigateToFulfillOrdersButtons: IButton[] = [
        {
            text: "To Fulfillment List",
            icon: <DoneIcon />,
            action: navigateToFulfillment,
            color: Constants.Colors.red,
        }
    ];
    let navigateToFulfillOrders: INotificationPopupSettings = {
        open: navigationFulfillment,
        type: Constants.NotificationPopupType.Info,
        title: "",
        msg: "This Order doesn't belong to selected Store.",
        draggable: false,
        handleClose: navigateToFulfillment,
        actions: navigateToFulfillOrdersButtons
    }
    const generateTrackingNotes = () => {
        return (
            // <Paper
            //     style={{
            //         padding: "5px",
            //         color: "#D93A2F",
            //         fontSize: "1rem",
            //         marginTop: 4,
            //     }}
            // >
            //     <Typography>
            //         <StarPurple500Icon style={{ fontSize: 12, marginRight: 2 }} /><b>For multi-box item, select item, add <AddCircleIcon></AddCircleIcon> package detail(s) and click generate label</b>
            //     </Typography>
            //     <Typography>
            //         <StarPurple500Icon style={{ fontSize: 12, marginRight: 2 }} />
            //         <b>For single box multiple items, select all the items and click generate labels</b>
            //     </Typography>
            // </Paper>
            <Paper
                style={{
                    color: "#555555",
                    fontSize: "1rem",
                    textAlign: "left",
                    padding: "8px",
                    backgroundColor: "#EEEEEE",
                    margin: "16px 6px 16px 6px",
                    borderLeft: "4px solid #3874BA",  // Add border-left style for blue vertical line
                }}
            >
                <Typography style={{ fontSize: 14, marginRight: 2 }} >
                    <b>For multi-box item</b>
                </Typography>
                <Typography style={{ fontSize: 14, marginRight: 2 }}>
                    {/* select item, add <AddCircleIcon></AddCircleIcon> package detail(s) and click generate label */}
                    select item, add package detail(s) and click generate label
                </Typography>
                <Typography style={{ fontSize: 14, marginRight: 2 }} >
                    <b>For single box multiple items</b>
                </Typography>
                <Typography style={{ fontSize: 14, marginRight: 2 }}>
                    select all the items and click generate labels
                </Typography>
            </Paper>
        );
    };
    const generateTrackingNotesWeb = () => {
        return (
            <Paper
                style={{
                    color: "#555555",
                    fontSize: "1rem",
                    textAlign: "left",
                    padding: "8px",
                    backgroundColor: "#EEEEEE",
                    marginTop: 2,
                    marginBottom: 16,
                    borderLeft: "4px solid #3874BA",  // Add border-left style for blue vertical line
                }}
            >
                <Typography style={{ fontSize: 14, marginRight: 2 }} >
                    <b>For multi-box item</b>
                </Typography>
                <Typography style={{ fontSize: 14, marginRight: 2 }}>
                    {/* select item, add <AddCircleIcon></AddCircleIcon> package detail(s) and click generate label */}
                    select item, add package detail(s) and click generate label
                </Typography>
                <Typography style={{ fontSize: 14, marginRight: 2 }} >
                    <b>For single box multiple items</b>
                </Typography>
                <Typography style={{ fontSize: 14, marginRight: 2 }}>
                    select all the items and click generate labels
                </Typography>
            </Paper>

        );
    };
    const generateTrackingView = () => {
        return (
            <Card className="dCard" style={{ margin: Utils.isMobile ? 5 : '', boxShadow: Utils.isMobile ? "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" : "" }}>
                <CardContent style={{ padding: 20 }}>
                    <Typography className="card-heading" style={{ textAlign: "left" }}>Generate Label/Tracking</Typography>
                    <Divider style={{ border: "1px" }} />
                    {/* <div className="backdrop"> */}
                    {/* <div className="title"> */}
                    <Grid container>
                        {/* <Grid
                                    item
                                    style={{ margin: "auto", textAlign: "left" }}
                                    xs={10}
                                    sm={10}
                                >
                                    <Typography>Generate Label/Tracking</Typography>
                                </Grid> */}
                        {/* <Grid item xs={2} sm={2} style={{ textAlign: "right" }}>
                            {Utils.isMobile ? (
                                <IconButton
                                    onClick={() => {
                                        if (Utils.isMobile) {
                                            setTrackingDrawer(false);
                                        }
                                    }}
                                    style={{ color: "white" }}
                                >
                                    <CancelIcon></CancelIcon>
                                </IconButton>
                            ) : (
                                ""
                            )}
                        </Grid> */}
                    </Grid>
                    {/* </div> */}
                    <div
                        style={{ padding: "2px", marginTop: "15px" }}
                    >
                        {/* {Utils.isMobile ? generateTrackingNotes() : ""} */}
                        <div className={selectedItems.length == 0 ? "overlay" : ""} style={{ borderRadius: 4 }}>
                            <BoxCharacteristics isBundle={isBundle} disableMultiBox={disableMultiBox}></BoxCharacteristics>
                            <Grid item xs={12} sm={12} className="inputField">
                                {/* <Typography className={selectedItems.length > 0 ? "" : "disabledColor"}>Ship Date</Typography> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        disabled={selectedItems.length < 1 ? true : false}
                                        label=""
                                        value={shipDate}
                                        minDate={new Date()}
                                        onChange={(newValue: any) => {
                                            changeShipDate(newValue);
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                InputLabelProps={{ shrink: false }}
                                                fullWidth
                                                label=""
                                                error={true}
                                                style={{ width: "100%" }}
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                className="inputField"
                                style={{ display: "flex" }}
                            >
                                <Grid container>
                                    <Grid item xs={6} sm={6} style={{ padding: "7px" }}>
                                        <Typography className={selectedItems.length > 0 ? "" : "disabledColor"} style={{ marginTop: "12px !important" }}>
                                            Residential
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Checkbox
                                            checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                                            disabled={selectedItems.length > 0 ? false : true}
                                            checked={isResidential}
                                            onChange={() => changeResidential()}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    {/* </div> */}
                    <Button

                        disabled={selectedItems.length > 0 && !getValidation() ? false : true}
                        onClick={() => createShipment()}
                        style={{ marginTop: "10px", width: "100%" }}
                        variant="contained"
                        className={selectedItems.length > 0  && !getValidation() ? "full-button" : "full-button disabled"}
                        startIcon={<QrCodeScannerOutlinedIcon />}
                    >
                        Generate Label
                    </Button>
                </CardContent>
            </Card>
        );
    };
    const webView = () => {
        return (
            <div style={{ margin: "20px" }}>
                {/* <Paper
                        className="paperToBottomPadding"
                        style={{ padding: "10px", marginBottom: "8px" }}
                    > */}
                <Grid container spacing={2} display={"flex"}>
                    <Grid item xs={5} sm={5} className='textLeft'>
                        <Grid container display={"flex"}>
                            <Grid item>
                                <Button onClick={() => navigateToFulfillment()} className="back-button" variant='contained' startIcon={<ArrowBackIosNewOutlinedIcon />}>Back</Button>
                            </Grid>
                            <Grid item style={{ marginLeft: "30px" }}>
                                <Typography className="medium-heading">Order #</Typography>
                                <Typography className="large-heading">{orderNumber}</Typography>
                            </Grid>
                        </Grid>
                        {/* <Typography
                                    variant="h6"
                                    style={{ padding: "0px", marginTop: 1 }}
                                    className="scanColor"
                                >
                                    Order # {orderNumber}
                                </Typography> */}
                    </Grid>
                    {Utils.disableUserAction(props.store) && <Grid item xs={7} sm={7} className="textRight">
                        {/* <Button
                                    disabled={selectedItems.length ? false : true}
                                    className={selectedItems.length ? "btn " : "btn disabledButton"}
                                    variant="contained"
                                    startIcon={<PlumbingIcon />}
                                    onClick={() => openADhoqPopup()}
                                    style={{ marginTop: 1 }}
                                >
                                    Add Tracking # (Ad-hoc)
                                </Button> */}

                        <Button
                            disabled={isReadyForShip ? false : true}
                            className={isReadyForShip ? "header-button" : "header-button disabled"}
                            variant="contained"
                            startIcon={<LocalPrintshopOutlinedIcon />}
                            onClick={() => {
                                if (isReadyForShip && loadingOrderLines == false) {
                                    logger.info({ message: "", tag: Constants.LOG_TYPES.PACK_SLIP });
                                    printPackSlip();

                                }
                            }}
                        >
                            Pack Slip
                        </Button>
                        {
                            !shipped &&
                            <Button
                                style={{ marginLeft: 10 }}
                                disabled={isReadyForShip ? false : true}
                                className={isReadyForShip ? "header-button underline" : "header-button disabled"}
                                variant="contained"
                                startIcon={<LocalShippingOutlinedIcon />}
                                onClick={() => {
                                    if (isReadyForShip && loadingOrderLines == false) {
                                        shipOrder();
                                    }
                                }}
                            >
                                Ship
                            </Button>
                        }
                        <Button
                            disabled={selectedItems.length ? false : true}
                            className={selectedItems.length ? "options-button" : "options-button disabled"}
                            onClick={handleMenuOpen} style={{ marginLeft: 10 }}>
                            <MoreVertIcon />
                        </Button>
                        <Menu
                            PaperProps={{
                                style: {
                                    width: "228px",
                                    borderRadius: "16px"
                                },
                            }}
                            key={Utils.generateRandom(
                                10,
                                Constants.CryptoRandomTypes.numeric
                            )}
                            open={openMenu}
                            keepMounted
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                className="dropdownMenuItem"
                                key={Utils.generateRandom(
                                    10,
                                    Constants.CryptoRandomTypes.numeric
                                )}
                                onClick={() => openADhoqPopup()}
                            >
                                Add Tracking #
                                <PlumbingIcon style={{ fontSize: "20px" }} />
                            </MenuItem>
                        </Menu>
                    </Grid>}
                </Grid>
                {/* </Paper> */}
                <div style={{ marginTop: "26px" }}>
                    <Grid container spacing={2} display={"flex"}>
                        <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px" }} className="textLeft">
                            <OrderDetailGrid loading={loadingOrderLines} data={orderDetails}></OrderDetailGrid>
                            <UnPackagedItems hideActionLoader={hideActionLoader} showActionLoader={showActionLoader} getPackDetailFromOms={getPackDetailFromOms} setdisableMultiBox={setdisableMultiBox} loadingOrderLines={loadingOrderLines} openLoadingOrderLines={openLoadingOrderLines} closeLoadingOrderLines={closeLoadingOrderLines} store={props.store}></UnPackagedItems>
                            <PackagedItems showErrorMessageFromChild={showErrorMessageFromChild} getPackDetailFromOms={getPackDetailFromOms} loadingOrderLines={loadingOrderLines} openLoadingOrderLines={openLoadingOrderLines} closeLoadingOrderLines={closeLoadingOrderLines} shipped={shipped}></PackagedItems>
                            <PackOrderCustomerDetail loadingOrderLines={loadingOrderLines} openLoadingOrderLines={openLoadingOrderLines} closeLoadingOrderLines={closeLoadingOrderLines}></PackOrderCustomerDetail>
                        </Grid>
                        <Grid item xs={3} sm={3} style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px", marginTop: "16px" }}>
                            {Utils.isMobile ? "" : generateTrackingNotesWeb()}
                            {generateTrackingView()}
                            <div>
                                <OrderHistory store={props.store}></OrderHistory>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };
    const mobileView = () => {
        return (
            <React.Fragment>
                <div style={{ paddingBottom: "150px" }}>
                    <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1, background: "#F9F9F9" }}>
                        <Grid container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                            <Grid item xs={12} sm={12} className='textLeft'>
                                <Grid container style={{ paddingLeft: "12px" }}>
                                    <Grid item xs={3}>
                                        <Button onClick={() => navigateToFulfillment()} className="back-button" variant='contained' startIcon={<ArrowBackIosNewOutlinedIcon />}>Back</Button>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography className="medium-heading">Order #</Typography>
                                        <Typography className="large-heading" style={{ wordBreak: "break-all" }}>{orderNumber}
                                        </Typography>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                        {/* <Grid container spacing={2} style={{ margin: 0, padding: "17px" }}> */}
                        {Utils.disableUserAction(props.store) &&
                            <Grid container spacing={2} style={{ marginBottom: "10px", padding: "10px" }}>
                                <Grid item xs={7} sm={7}>
                                    <Button
                                        style={{ width: "100%" }}
                                        disabled={selectedItems.length ? false : true}
                                        className={selectedItems.length ? "header-button" : "header-button disabled"}
                                        variant="contained"
                                        startIcon={<PlumbingIcon />}
                                        onClick={() => openADhoqPopup()}
                                    >
                                        Add Tracking #
                                    </Button>
                                </Grid>
                                {
                                    !shipped &&
                                    <Grid item xs={5} sm={5}>
                                        <Button
                                            style={{ width: "100%" }}
                                            disabled={true}
                                            className={"header-button disabled"}
                                            variant="contained"
                                            startIcon={<LocalShippingOutlinedIcon />}
                                            onClick={() => {
                                                if (isReadyForShip && loadingOrderLines == false) {
                                                    shipOrder();
                                                }
                                            }}
                                        >
                                            Ship
                                        </Button>
                                    </Grid>
                                }
                                {/* <Grid item xs={2} sm={2}>
                                        <Button
                                            disabled={selectedItems.length ? false : true}
                                            className={selectedItems.length ? "options-button" : "options-button disabled"}
                                            onClick={handleMenuOpen} style={{ marginLeft: 10 }}>
                                            <MoreVertIcon />
                                        </Button>
                                    </Grid>
                                    <Menu
                                        PaperProps={{
                                            style: {
                                                width: "228px",
                                                borderRadius: "16px"
                                            },
                                        }}
                                        key={Utils.generateRandom(
                                            10,
                                            Constants.CryptoRandomTypes.numeric
                                        )}
                                        open={openMenu}
                                        keepMounted
                                        anchorEl={anchorEl}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem
                                            className="dropdownMenuItem"
                                            key={Utils.generateRandom(
                                                10,
                                                Constants.CryptoRandomTypes.numeric
                                            )}
                                            onClick={() => openADhoqPopup()}
                                        >
                                            Add Tracking #
                                            <PlumbingIcon style={{ fontSize: "20px" }} />
                                        </MenuItem>
                                    </Menu> */}
                            </Grid>}
                        {/* </Grid> */}


                        <UnPackagedItems setTrackingDrawer={setTrackingDrawer} hideActionLoader={hideActionLoader} showActionLoader={showActionLoader} getPackDetailFromOms={getPackDetailFromOms} setdisableMultiBox={setdisableMultiBox} loadingOrderLines={loadingOrderLines} openLoadingOrderLines={openLoadingOrderLines} closeLoadingOrderLines={closeLoadingOrderLines} store={props.store}></UnPackagedItems>
                        <PackagedItems showErrorMessageFromChild={showErrorMessageFromChild} getPackDetailFromOms={getPackDetailFromOms} loadingOrderLines={loadingOrderLines} openLoadingOrderLines={openLoadingOrderLines} closeLoadingOrderLines={closeLoadingOrderLines} shipped={shipped}></PackagedItems>
                        {generateTrackingView()}
                        {Utils.isMobile ? generateTrackingNotes() : ""}
                        <OrderDetailGrid loading={loadingOrderLines} data={orderDetails}></OrderDetailGrid>
                        <PackOrderCustomerDetail loadingOrderLines={loadingOrderLines} openLoadingOrderLines={openLoadingOrderLines} closeLoadingOrderLines={closeLoadingOrderLines}></PackOrderCustomerDetail>
                        <OrderHistory store={props.store}></OrderHistory>

                    </Paper>
                </div>
            </React.Fragment >
        );
    };
    return (
        <div>
            <NotificationPopup {...success}></NotificationPopup>
            <NotificationPopup {...ADhoqPopup}>
            </NotificationPopup>
            <NotificationPopup {...Error}>
            </NotificationPopup>
            <NotificationPopup {...ReportError}></NotificationPopup>
            
            <NotificationPopup {...confirmReportError}> </NotificationPopup>
            <NotificationPopup {...navigateToFulfillOrders}>
            </NotificationPopup>
            <PdfIframe open={showPdf} close={closePdfiframe} blobUrl={blobUrl} ></PdfIframe>
            <NotificationPopup {...notesCustomerPopup}></NotificationPopup>
            {Utils.isMobile ? mobileView() : webView()}
            <PackPrintSlip loadingOrderLines={loadingOrderLines} isPrintPackOrder={isPrintPackOrder} ></PackPrintSlip>

        </div>
    );
};

export default PackOrder;
