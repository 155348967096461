import { Button, Card, CardContent, Checkbox, Divider, Drawer, FormControl, FormControlLabel, Grid, Paper, RadioGroup, TextField, Toolbar, Typography, Select, SelectChangeEvent, MenuItem, Tooltip, Box, SwipeableDrawer, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Utils from '../Common/Utils';
import Constants from '../Common/Constants';
import Radio from '@mui/material/Radio';
import { IconButton } from '@mui/material';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { VerticalLinearStepper } from '../Subcomponent/Stepper/VerticalLinearStepper'
import { IVerticalStepper, IStepOption } from '../Models/IVerticalStepper';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs'
import { Note } from "../Subcomponent/Shared/Note";
import { NotificationPopup } from '../Subcomponent/Shared/NotificationPopup'
import { INotificationPopupSettings, IButton } from '../Models/INotificationPopupSettings';
import LabelText from "../Common/LabelText"
import { ICustomerDetail } from '../Models/IPickOrder'
import allActions from "../Store/Actions/AllActions"
import OMSManager from "../Managers/Oms.manager";
import OrderLineShimmer from "../Subcomponent/PickOrder/OrderLineShimmer"
import OrderLineShimmerMobile from "../Subcomponent/PickOrder/OrderLineShimmerMobile"
import { PickupPrintSlip } from '../Subcomponent/CustomerPickup/PickupPrintSlip';
import CustomerDetailShimmer from "../Subcomponent/PickOrder/CustomerDetailShimmer"
import CustomerDetailShimmerMobile from "../Subcomponent/PickOrder/CustomerDetailShimmerMobile"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { ICustomerPickupDetail, ContainerDetail, Component } from "../Models/ICustomerPickupDetail";
import StoreManager from "../Managers/Store.manager";
import ApiService from "../Common/ApiService";
import OrderManager from '../Managers/Orders.Manager';
import { AddCommentIcon, CloseIcon, CancelIcon, CommentIcon, PersonIcon, PrintIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon, ArrowBackIcon, CheckIcon, DoneIcon, InfoOutlinedIcon, ReportProblemIcon, ArrowForwardIcon, ArrowBackIosNewOutlinedIcon, LocalPrintshopOutlinedIcon, ExpandMoreIcon, EmailOutlinedIcon, CancelOutlinedIcon, CheckBoxOutlinedIcon } from "../Subcomponent/Shared/SharedIcons"
import OrderHistory from '../Subcomponent/Shared/OrderHistory';
import logger from '../Common/logger';
import IdentityManager from '../Managers/Identity.manager';
import PFManager from '../Managers/Process.Fulfillment.Manager';
import { CustomerDetails } from '../Subcomponent/Shared/CustomerDetails';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Subcomponent/Shared/ImageViewerPopup';
import { display } from '@mui/system';
import { ReportErrorPopup } from '../Subcomponent/Shared/ReportErrorPopup';
import { NumberOfBoxes } from '../Subcomponent/Shared/NumberOfBoxes';
import { ServiceNowCnfrmText } from '../Subcomponent/Shared/ServiceNowCnfrmText';
import ShipmentStatusConstants from '../Common/ShipmentStatusConstants';
var moment = require('moment');
let step: IStepOption[] = []
step.push({ label: 'Order Release', description: ``, time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Customer Contacted', description: "dummy", time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Note', description: "dummy", time: 'Jan 20 2022 13:11:17 ' })
step.push({ label: 'Picking', description: ``, time: 'Jan 20 2022 13:11:17' })
step.push({ label: 'Packing', description: ``, time: 'Jan 20 2022 13:11:17' })
step.push({ label: 'Customer Pickup', description: ``, time: '' })
let verticalStepperOptions: IVerticalStepper = {
    activeStep: 'Customer Pickup',
    stepsOptions: step
}
var momenttz = require('moment-timezone');
const today = momenttz.tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly);
const todayDate = momenttz.tz("America/Los_Angeles");

const CustomerPickup = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderno = urlParams.get('orderno');
    const pathname = window.location.pathname;
    const shipmentkey = urlParams.get('shipmentkey');
    const queryParameterStore = urlParams.get('store');
    const [value, setValue] = React.useState("");
    const [idType, setIdType] = React.useState("-1");
    const [stateType, setstateType] = React.useState("0");
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [openTrackingDrawer, setTrackingDrawer] = React.useState(false);
    const [openDrawer, setDrawer] = useState(false);
    const [showPickupSlip, setShowPickupSlip] = useState(0);
    const [notes, setNotes] = useState("")
    const [notePopup, setNotesPopup] = useState(false);
    const [orderDetailFromExStore, setOrderDetailFromExStore] = React.useState<any | null>(null);
    const [navigationFulfillment, setNavigationFulfillment] = useState(false);
    const [monthYear, setMonthYear] = React.useState<Date | null>(null);
    const [steps, setSteps] = useState<IVerticalStepper>(verticalStepperOptions)
    let orderLines: ContainerDetail[] = useSelector((state: any) => state.CustomerPickupState.orderLines);
    let customerDetail: ICustomerDetail = useSelector((state: any) => state.CustomerPickupState.customerDetail);
    // let customerDetailOMS: ICustomerDetail = useSelector((state: any) => state.PickOrdersReducersState.customerDetailOMS);
    let orderDetails: ICustomerPickupDetail = useSelector((state: any) => state.CustomerPickupState.orderDetails);
    const isHistoryLoading = useSelector((state: any) => state.OrderHistoryReducerState.isLoading);
    let selectedItems = useSelector((state: any) => state.CustomerPickupState.selectedItems);
    const [orderNumber, setOrderNumber] = useState("");
    const [loadingOrderLines, setLoadingOrderLines] = useState(true);
    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("Something went Wrong");
    const [openError, setOpenError] = useState(false);
    const [state, setState] = useState("-1");
    const [pickingVerification, setPickingVerification] = useState(false);
    const [expiryValue, setExpiryValue] = useState("");
    const [expiryValueYear, setExpiryValueYear] = useState("");
    const [saveType, setSaveType] = useState("");
    const [printPickupSlipData, setPrintPickupSlipData] = useState({} as any);
    const [imagePopUp, setImagePopUp] = useState(false);
    const [imageData, setImageData] = useState({} as IImageViewerDetail);
    // Report Error Pop Up Use States
    const [openReportError, setOpenReportError] = useState(false);
    const [openAlreadyReportedError, setOpenAlreadyReportedError] = useState(false);
    const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
    const [errorReportMsg, setErrorReportMsg] = useState("");
    const [currentStatus, setCurrentStatus] = useState("");


    //Report Error Action Use State
    const [reportErrorAction, setReportErrorAction] = useState("");

    //Number of box use state action
    const [loadingNumberOfBoxes, setLoadingNumberOfBoxes] = useState(true);
    const [numberOfBoxArr, setNumberOfBoxArr] = useState([]);

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const openLoadingOrderLines = () => {
        setLoadingOrderLines(true)
    }
    const closeLoadingOrderLines = () => {
        setLoadingOrderLines(false)
    }
    const closeNotes = () => {
        setNotesPopup(false)
    }
    const openNotes = () => {
        setNotesPopup(true)
        setNotes("")
    }
    const changeNote = (event: any) => {
        setNotes(event.target.value);
    }
    const addNotes = () => {
        if (notes != "") {
            let index = Utils.getStepperIndex(verticalStepperOptions, "Customer Pickup")
            step.splice(index, 0, { label: 'Note', description: notes, time: 'Jan 20 2022 13:11:17 ' })
            verticalStepperOptions.stepsOptions = step
            setSteps(verticalStepperOptions)
        }
        setNotesPopup(false)
    }
    let notesPopupButtons: IButton[] = [{
        text: "Add",
        icon: <AddCommentIcon />,
        action: addNotes,
        color: Constants.Colors.blue,
    },
    {
        text: "Close",
        icon: <CloseIcon />,
        action: closeNotes,
        // color: Constants.Colors.grey,
        color: Constants.Colors.white,
        border: Constants.Colors.grey,
        textColor: Constants.Colors.grey
    }
    ]
    let notesCustomerPopup: INotificationPopupSettings = {
        open: notePopup,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.PickOrder.Note,
        msg: "",
        handleClose: closeNotes,
        isCustomJSX: true,
        customJSX: <Note note={notes} changeNote={changeNote}></Note>,
        actions: notesPopupButtons,
    }
    let stateOptions = [
        { label: 'Please Select Issuing State', id: "-1" }
    ];
    const changeSelectedItem = (event: any, id: any) => {
        let checked = event.target.checked
        if (checked) {
            let newSelectedItems = [...selectedItems]
            newSelectedItems.push(id)
            dispatch(allActions.customerPickupActions.changeSelectedItem(newSelectedItems));
        }
        else {
            let newSelectedItems = selectedItems.filter((listId: any) => { return listId != id })
            dispatch(allActions.customerPickupActions.changeSelectedItem(newSelectedItems));
        }
    }


    // Top Bar Scroll Handle
    // Scroll
    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById('bar') as any;
        var mblBar = document.getElementById('mblBar') as any;
        if (bar) {

            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);

            if (window.pageYOffset > startProductBarPos) {

                bar.classList.add("scrolledTopFix");

            } else {
                bar.classList.remove("scrolledTopFix");

            }
        }

    };

    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }

    //
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value, "valll");
        setValue((event.target as HTMLInputElement).value);
    };
    const generateTrackingNotes = () => {
        return <Paper style={{ background: "#EEE", marginTop: 10, padding: 10, textAlign: "center", boxShadow: "0px 0px 0px 0px" }}>
            {/* <CardContent> */}
            <Typography className="small-heading">Note: Only the persons eligible to pick up the order are listed below</Typography>
            {/* </CardContent> */}
        </Paper>;
    }

    const onChangeState = (event: any) => {
        setState(event.target.value);

    }

    const confirmCustomerPickup = async (type: string) => {
        setSaveType(type)
        let containerDetails = [] as any;
        orderLines.forEach((orderLine: ContainerDetail) => {
            if (selectedItems.indexOf(orderLine.UniqueContainerKey) > -1) {
                let containerDetail = {
                    "ContainerDetailsKey": orderLine.ContainerDetailsKey,
                    "DocumentType": orderLine.DocumentType,
                    "Quantity": Number(orderLine.Quantity),
                    "ShipmentKey": orderLine.ShipmentKey,
                    "ShipmentContainerKey": orderLine.ShipmentContainerKey,
                    "OrderHeaderKey": orderLine.OrderHeaderKey,
                    "OrderLineKey": orderLine.OrderLineKey,
                    "OrderReleaseKey": orderLine.OrderReleaseKey,
                } as any;
                if (orderLine.IsBundleParent) {
                    containerDetail.IsBundleParent = orderLine.IsBundleParent
                }
                if (orderLine.IsMultibox) {
                    containerDetail.IsMultibox = orderLine.IsMultibox
                }
                if (orderLine.hasOwnProperty("WarrantyComponent")) {
                    let warrentComponent = {
                        "ContainerDetailsKey": orderLine.WarrantyComponent.ContainerDetailsKey,
                        "Quantity": Number(orderLine.WarrantyComponent.Quantity),
                        "ShipmentContainerKey": orderLine.WarrantyComponent.ShipmentContainerKey,
                        "OrderLineKey": orderLine.WarrantyComponent.OrderLineKey,
                        "OrderReleaseKey": orderLine.WarrantyComponent.OrderReleaseKey
                    }
                    containerDetail.WarrantyComponent = warrentComponent

                }
                if (orderLine.hasOwnProperty("Component")) {
                    // whatever we have in Component we are passing as it is
                    //let ordercomponents = [] as any
                    // if (orderLine.Component.length != undefined) {
                    //     orderLine.Component.forEach((comp: Component) => {
                    //         if (comp.Quantity == undefined) {
                    //             let compt = {
                    //                 "ContainerDetailsKey": comp.ContainerDetailsKey,
                    //                 "ShipmentContainerKey": comp.ShipmentContainerKey,
                    //                 "DocumentType": comp.DocumentType,
                    //                 "OrderHeaderKey": comp.OrderHeaderKey,
                    //                 "OrderLineKey": comp.OrderLineKey,
                    //                 "OrderReleaseKey": comp.OrderReleaseKey
                    //             }
                    //             ordercomponents.push(compt);
                    //         }
                    //         else {
                    //             let compt = {
                    //                 "ContainerDetailsKey": comp.ContainerDetailsKey,
                    //                 "ShipmentContainerKey": comp.ShipmentContainerKey,
                    //                 "DocumentType": comp.DocumentType,
                    //                 "Quantity": Number(comp.Quantity),
                    //                 "OrderHeaderKey": comp.OrderHeaderKey,
                    //                 "OrderLineKey": comp.OrderLineKey,
                    //                 "OrderReleaseKey": comp.OrderReleaseKey
                    //             }
                    //             ordercomponents.push(compt);
                    //         }

                    //     });
                    //     containerDetail.Component = ordercomponents
                    // }
                    //else {
                    containerDetail.Component = orderLine.Component
                    //}
                    containerDetail.ProductClass = orderLine.ProductClass
                }
                containerDetails.push(containerDetail)
            }
        });
        //expiryValue + expiryValueYear

        let month = ""
        if (monthYear) {
            month = String((monthYear?.getMonth() + 1)).padStart(2, '0');
        }
        let year = ""
        if (monthYear) {
            year = (monthYear?.getFullYear().toString()).slice(-2)
        }
        let payload = {
            "ContainerDetails": {
                "ExtnPickedUpBy": value,
                "OrderHeaderKey": orderDetails.OrderHeaderKey,
                "ExtnIsPickUpPersonVerified": "Y",
                "UserName": "",
                "UserAuthenticated": OMSManager.GetUserIdFromLocalStorage(),
                "EmployeeID": OMSManager.GetEmployeeId(),
                "IdType": idType,
                "OrderNo": orderno,
                "ContainerDetail": containerDetails,
                "ExtnIdTypeStateIssued": state ? state : "",
                "ExtnIdTypeExpDate": month + year
            }
        }
        console.log(payload);
        showActionLoader();
        ApiService.post("/oms/GCCustomerPickedUpConfirm", payload,shipmentkey,currentStatus).then(async (response) => {
            if (response.success == true) {
                await Utils.getPickupSlipDataFromPickup(orderLines, customerDetail, orderDetails, selectedItems, orderDetailFromExStore,true).then((printData: any) => {

                    setPrintPickupSlipData(printData);

                })
                OrderManager.saveOrderHistory({
                    shipmentKey: shipmentkey,
                    logType: "history",
                    transactionType: Constants.OrderHistoryTypes.Customer_Pickup,
                    message: notes,
                    transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
                });
                setShowPickupSlip(showPickupSlip + 1)
                hideActionLoader();
                if (saveType != "email") {
                    // navigate("/FulfillOrders");
                }
            }
            else {
                hideActionLoader();
                setIsError(true)
                setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
                setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.CUSTOMER_PICKUP_CONFIRM_OMS)
                openErrorPopup();
            }
        }).catch(() => {
            hideActionLoader();
            setIsError(true)
            setErrorMsg("Something went wrong please contact the IT Help Desk for assistance")
            setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.CUSTOMER_PICKUP_CONFIRM_OMS)
            openErrorPopup();
        })
    }

    const getPickingPersons = (orderDetails: any) => {

        let pickingPersons: any[] = [];
        if (orderDetails && orderDetails.BillingCustomerName) {
            pickingPersons = [orderDetails.BillingCustomerName];
            if (pickingPersons.indexOf(orderDetails.PickUpPersonName) <= -1) {
                pickingPersons.push(orderDetails.PickUpPersonName);
            }
        }

        return pickingPersons;

    }

    const generateMobileSavePrintView = () => {
        // states
        let statesRaw = orderDetails.StatesList ? orderDetails.StatesList.CommonCodeList.CommonCode : [];
        let statesOptions = [
            { label: 'Please Select Issuing State', id: "-1" }
        ];

        statesRaw.forEach((val: any) => {

            statesOptions.push({
                label: val.CodeShortDescription,
                id: val.CodeValue
            });

        });

        // Ids Type
        let IdsTypeRaw = orderDetails.CommonCodeList ? orderDetails.CommonCodeList.CommonCode : [];
        let IdsOptions = [{ value: " Please Select ID Type", key: "-1" }];
        IdsTypeRaw.forEach((val: any) => {

            IdsOptions.push({ value: val.CodeValue, key: val.CodeValue });

        });

        // Picking Person
        let pickingPersons: any[] = getPickingPersons(orderDetails);


        console.log(pickingPersons, "pickingPersons");

        console.log(value, "pickingPersonsValue");

        let enableSavePrint = false;
        //(expiryValue && expiryValue.length == 2) && (expiryValueYear && expiryValueYear.length == 2)
        console.log(selectedItems, "selectedItems");
        console.log(monthYear, "monthYear");
        console.log(state, "state");

        if ((selectedItems && selectedItems.length > 0) && (value && value.length > 0) && (pickingVerification)
            && (idType && idType.length > 0) && (state && state.length > 0 && state != "-1") &&
            (monthYear != null && monthYear.toString() != "Invalid Date" && new Date(monthYear) >= new Date(today))) {
            enableSavePrint = true;
        }
        else {
            enableSavePrint = false;
        }


        return <Card className="dCard">
            <CardContent>

                <Typography className="card-heading" style={{ textAlign: "left" }}>Person picking up the items</Typography>
                <Divider />
                {/* <div className="title">
                <Grid item style={{ margin: "auto", textAlign: "left" }} xs={10} sm={10}>
                    <Typography>Person picking up the items</Typography>
                </Grid>
            </div> */}
                {/* <div className="mainContainer" style={{ padding: "10px" }}> */}
                {generateTrackingNotes()}
                <Grid container>
                    <Grid item xs={12} sm={12} className='inputField'>
                        {
                            pickingPersons && pickingPersons.length > 0 ?
                                <FormControl className='customRadio'>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        {pickingPersons.map((val: any) => {
                                            return <FormControlLabel value={val} control={<Radio style={{ color: "#f2554a" }} />} label={val} />
                                        })}


                                    </RadioGroup>
                                </FormControl> : ""
                        }

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Grid container>
                            <Grid item xs={2} sm={2} style={{ textAlign: "left" }}>
                                <Checkbox

                                    checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                                    onChange={(event: any, checked: boolean) => {

                                        setPickingVerification(checked);

                                    }}
                                    checked={pickingVerification}
                                    style={{
                                        color: "#D93A2F",
                                    }} />
                            </Grid>
                            <Grid item xs={10} sm={10} style={{ textAlign: "center" }}>
                                <Typography className="small-heading" style={{ paddingLeft: "1px" }}>
                                    I have verified the ID of the person picking up this Order
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Grid item xs={12} sm={12} className='inputField' style={{ marginTop: 10 }}>
                        {/* <Typography>
                            ID Type *
                        </Typography> */}
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={idType.toString()}
                                onChange={(event: SelectChangeEvent) => {
                                    console.log(event.target.value, "event.target.value Id")
                                    setIdType(event.target.value as string);
                                }}
                                variant='outlined'
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                    IdsOptions.map((val: any) => {

                                        return <MenuItem value={val.key}>{val.value}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} className='inputField' style={{ marginTop: 5 }}>
                        {/* <Typography >
                            Issuing State *
                        </Typography> */}
                        <FormControl>
                            <Select
                                labelId="issuing-state-label"
                                id="issuing-state-id"
                                value={state}
                                onChange={onChangeState}
                                variant='outlined'
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                    statesOptions.map((val: any) => {

                                        return <MenuItem value={val.id}>{val.label}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>
                        {/* <Autocomplete
                            value={state}
                            onChange={onChangeState}
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            defaultValue={stateOptions[0]}
                            disablePortal
                            id="combo-box-demo"
                            options={statesOptions}
                            renderInput={(params) => {
                                return <TextField 
                                {...params} 
                                InputLabelProps={{ // this part
                                    shrink: false
                                }}
                                label="" variant="outlined"/>
                            }}
                        /> */}
                    </Grid>
                    <Grid item xs={12} sm={12} className='inputField' style={{ marginTop: 5 }}>
                        {/* <Typography>
                            Exp. *
                        </Typography> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box m={0}>
                                <DatePicker
                                    inputFormat="MM-yy"
                                    mask="__-__"
                                    disableMaskedInput={false}
                                    views={['year', 'month']}
                                    label="Month-Year"
                                    minDate={new Date(today)}
                                    value={monthYear}
                                    onChange={setMonthYear}
                                    renderInput={(params: any) => <TextField {...params} helperText={null} />}
                                />
                            </Box>
                        </LocalizationProvider>
                        {/* <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td style={{width: "50%"}}>
                                        <TextField value={expiryValue} onChange={(event: any) => {

                                            setExpiryValue(event.target.value.length > 2 ? event.target.value.slice(0, 2) : event.target.value);

                                        }} type={"number"} InputLabelProps={{ shrink: false }} inputProps={{ maxLength: 4, min:1, max:12 }} id="filled-basic" placeholder="MM" variant="outlined" />
                                    </td>
                                    <td style={{ fontSize: "30px", color: "#666363", fontWeight: "100" }}>
                                        /
                                    </td>
                                    <td style={{}}>
                                        <TextField value={expiryValueYear} onChange={(event: any) => {

                                            setExpiryValueYear(event.target.value.length > 2 ? event.target.value.slice(0, 2) : event.target.value);

                                        }} type={"number"} InputLabelProps={{ shrink: false }} inputProps={{ maxLength: 4, min:1 }} id="filled-basic" placeholder="YY" variant="outlined" />
                                    </td>
                                </tr>
                            </tbody>

                        </table> */}

                    </Grid>
                    <br></br>
                    <Grid item xs={12} sm={12} style={{ marginTop: "10px" }}>
                        {
                            !Utils.isMobile ?
                                <Button onClick={() => {
                                    if (Utils.isMobile) {
                                        setTrackingDrawer(false)
                                        confirmCustomerPickup("print");
                                    }
                                    else {
                                        confirmCustomerPickup("print");
                                    }
                                }} disabled={!enableSavePrint}
                                    variant='contained' className={!enableSavePrint ? "full-button disabled" : "full-button"} startIcon={<PrintIcon />}>Save & Print</Button>
                                :
                                <></>
                        }
                        {/* <Button onClick={() => {
                            if (Utils.isMobile) {
                                setTrackingDrawer(false)
                                confirmCustomerPickupTest("email");
                            }
                            else {
                                confirmCustomerPickupTest("email");
                            }
                        }}
                            style={{ width: "100%", marginTop: "5px" }} variant='contained' startIcon={<EmailIcon />}>Save & Send Email Test</Button> */}
                        <Button onClick={() => {
                            if (Utils.isMobile) {
                                setTrackingDrawer(false)
                                confirmCustomerPickup("email");
                            }
                            else {
                                confirmCustomerPickup("email");
                            }
                        }} disabled={!enableSavePrint}
                            style={{ width: "100%", marginTop: "5px" }} variant='contained' className={!enableSavePrint ? "full-button disabled" : "full-button"} startIcon={<EmailIcon />}>Save & Send Email</Button>
                    </Grid>
                </Grid>
                {/* </div > */}

            </CardContent>
        </Card >
    }

    const orderHistory = () => {
        return (
            <div>
                <OrderHistory store={props.store}></OrderHistory>
            </div>
        );
    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    const getCustomerDetails = (data: ICustomerPickupDetail) => {
        let initialCustomerDetail: ICustomerDetail = {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            state: ""
        }
        if (data.BillToAddress != undefined) {
            try {
                if (data.BillToAddress.hasOwnProperty("FirstName")) {
                    initialCustomerDetail.name = data.BillToAddress.FirstName
                }
                if (data.BillToAddress.hasOwnProperty("LastName")) {
                    initialCustomerDetail.name = initialCustomerDetail.name + " " + data.BillToAddress.LastName
                }
                if (data.BillToAddress.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.BillToAddress.City
                }
                if (data.BillToAddress.hasOwnProperty("EMailID")) {
                    initialCustomerDetail.email = data.BillToAddress.EMailID
                }
                if (data.BillToAddress.hasOwnProperty("DayPhone")) {
                    initialCustomerDetail.phone = data.BillToAddress.DayPhone
                }
                if (initialCustomerDetail.phone == "" && data.BillToAddress.hasOwnProperty("MobilePhone")) {
                    initialCustomerDetail.phone = data.BillToAddress.MobilePhone
                }
                if (data.BillToAddress.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.BillToAddress.ZipCode
                }
                if (initialCustomerDetail.zipCode == "" && data.BillToAddress.hasOwnProperty("ShortZipCode")) {
                    initialCustomerDetail.zipCode = data.BillToAddress.ShortZipCode
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.BillToAddress.AddressLine1
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine2") && data.BillToAddress.AddressLine2 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine2
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine3") && data.BillToAddress.AddressLine3 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine3
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine4") && data.BillToAddress.AddressLine4 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine4
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine5") && data.BillToAddress.AddressLine5 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine5
                }
                if (data.BillToAddress.hasOwnProperty("AddressLine6") && data.BillToAddress.AddressLine6 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.BillToAddress.AddressLine6
                }
                if (data.BillToAddress.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.BillToAddress.State
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        return initialCustomerDetail
    }
    const popUpBack = () => {
        navigate(-1)
    }
    // Show Pop ups If Error is already reported
    const handleErrorAlreadyReported = () => {
        // setOpenAlreadyReportedError(true);
        setOpenReportError(false);
    }

    // OPEN Confirm Report Error
    const reportConfirmErrorPopup = () => {
        setOpenConfirmReportError(true);
        setOpenError(false);

    }

    // CLOSE Report Error POP up
    const closeErrorReportPopup = () => {
        setOpenReportError(false)
    }

    // Buttons When there is no Action
    let errorPopupCloseButtons: IButton[] = [{
        text: "Close",
        icon: <CloseIcon />,
        action: closeErrorPopup,
        // color: Constants.Colors.red,
        color: Constants.Colors.white,
        border: Constants.Colors.red,
        textColor: Constants.Colors.red
    },
    {
        text: "Back",
        icon: <ArrowBackIcon />,
        action: popUpBack,
        color: Constants.Colors.red,
    }];

    // Buttons When there is Action
    let errorPopupButtons: IButton[] = [
        {
            text: "Report Error",
            icon: <ReportProblemIcon />,
            action: reportConfirmErrorPopup,
            color: Constants.Colors.orange,
        },
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            // color: Constants.Colors.red,
            color: Constants.Colors.white,
            border: Constants.Colors.red,
            textColor: Constants.Colors.red
        },
        {
            text: "Back",
            icon: <ArrowBackIcon />,
            action: popUpBack,
            color: Constants.Colors.red,
        }
    ];
    if (Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNFlag) === false) {
        errorPopupButtons.shift()
    };
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: reportErrorAction ? errorPopupButtons : errorPopupCloseButtons
    }

    // Report Error Pop up
    let ReportError: INotificationPopupSettings = {
        open: openReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: "Report Error",
        msg: "",
        isCustomJSX: true,
        customJSX: <ReportErrorPopup handleErrorAlreadyReported={handleErrorAlreadyReported} errorMessage={errorReportMsg} closeErrorReportPopup={closeErrorReportPopup} urlPathname={pathname} reportErrorAction={reportErrorAction}></ReportErrorPopup>,
        draggable: false,
        handleClose: closeErrorReportPopup,
        actions: [],
        fullWidth: true,
        maxWidth: "lg"
    }

    // OPEN Confirm Report Error POPup
    const reportErrorPopup = () => {
        setOpenReportError(true);
        setOpenConfirmReportError(false)
    }

    // CLOSE Confirm Report Error
    const closeConfirmReportError = () => {
        setOpenConfirmReportError(false);
    }
    let confirmReportErrorPopupButtons: IButton[] = [
        {
            text: "Continue",
            icon: <ArrowForwardIcon />,
            action: reportErrorPopup,
            color: Constants.Colors.red,
        }
    ];
    let confirmReportError: INotificationPopupSettings = {
        open: openConfirmReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: Constants.MESSAGES.SN_CNFRM_TITLE,
        msg: "",
        customJSX: <ServiceNowCnfrmText></ServiceNowCnfrmText>,
        isCustomJSX: true,
        draggable: false,
        handleClose: closeConfirmReportError,
        actions: confirmReportErrorPopupButtons
    }


    const getCustomerPickDetailFromOms = async () => {
        openLoadingOrderLines();
        let storeId = IdentityManager.getStoreId(props.store);
        if ((!storeId || storeId < 0) && queryParameterStore != null) {
            storeId = queryParameterStore
        }
        if (!storeId || storeId < 0) {
            setIsError(true);
            setErrorMsg("Please Select Store");
            openErrorPopup();
            return;
        }
        // try {
        //     let response = await OMSManager.getCustomerDetailsFromElastic(shipmentkey)
        //     const data = response?.data?.hits?.hits[0]?._source.Shipment?.CustomerInfo
        //     dispatch(allActions.pickOrderActions.getCustomerInfoFromElastic(OMSManager.getCustomerDetailsOMS(data)))
        // }
        // catch (error) {
        //     console.log(error);
        //     throw error;
        // }

        let result = [] as any
        try {
            result = await OMSManager.getGCCustomerPickupDetailView(storeId, orderno);
        }
        catch {
            setIsError(true);
            setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
            setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.CUSTOMER_PICKUP_DETAILED_OMS)
            openErrorPopup();
        }
        if (result.success) {
            let data = result.data

            setOrderNumber(data.OrderNo);
            //addingUniqueIdForSelection             
            if (data?.ContainerDetails?.ContainerDetail && data?.ContainerDetails?.ContainerDetail.length > 0) {
                data.ContainerDetails.ContainerDetail.forEach((containerDetail: ContainerDetail) => {
                    containerDetail.UniqueContainerKey = Utils.generateUUID();
                });
            }
            dispatch(allActions.customerPickupActions.changeOrderLines(data.ContainerDetails.ContainerDetail));
            const customerDetail = getCustomerDetails(data)
            if (!Utils.customerInfoFromElasticOrNot()) {

                dispatch(allActions.customerPickupActions.changeCustomerDetails(customerDetail));

            }
            let responseNumberBoxHistory = await ApiService.get("/pf/getNumberBoxHistory?orderNo=" + data.OrderNo);
            if (responseNumberBoxHistory?.data?.hits?.hits && responseNumberBoxHistory?.data?.hits?.hits.length > 0) {
                let getShipmentKeys = Utils.getAllShipmentKeysFromDetails(data?.ContainerDetails?.ContainerDetail)
                let resuledBoxDetails = Utils.filterBoxesByShipmentKeys(getShipmentKeys, responseNumberBoxHistory)
                let sumedBoxes = Utils.sumSimilarBoxes(resuledBoxDetails)
                setNumberOfBoxArr(sumedBoxes);
                setLoadingNumberOfBoxes(false)
            }
            if (Utils.customerInfoFromElasticOrNot()) {
                try {
                    let response = await OMSManager.getCustomerDetailsFromElastic(shipmentkey)
                    const data = response?.data?.hits?.hits[0]?._source.Shipment?.CustomerInfo
                    dispatch(allActions.customerPickupActions.changeCustomerDetails(OMSManager.getCustomerDetails(data)))
                }
                catch (error) {
                    console.log(error);
                    throw error;
                }
            }
            dispatch(allActions.customerPickupActions.setOrderDetails(data));
            let pickingPersons = getPickingPersons(data);
            setValue(pickingPersons && pickingPersons.length > 0 ? pickingPersons[0] : "");
            dispatch(allActions.customerPickupActions.changeSelectedItem([]));
            if (data.ContainerDetails.ContainerDetail.length == 1) {
                let uniqueContainerKey = data.ContainerDetails.ContainerDetail[0].UniqueContainerKey;
                let newSelectedItems = [...selectedItems]
                newSelectedItems.push(uniqueContainerKey)
                dispatch(allActions.customerPickupActions.changeSelectedItem(newSelectedItems));
            }
            closeLoadingOrderLines();
        }
        else {
            setIsError(true)
            setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
            setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.CUSTOMER_PICKUP_DETAILED_OMS)
            closeLoadingOrderLines()
            openErrorPopup();
        }

    }

    const navigateToFulfillment = () => {
        navigate("/FulfillOrders");
    }

    const openNavigationFulfillment = () => {
        setNavigationFulfillment(true);
    }

    let navigateToFulfillOrdersButtons: IButton[] = [
        {
            text: "To Fulfillment List",
            icon: <DoneIcon />,
            action: navigateToFulfillment,
            color: Constants.Colors.red,
        }
    ];
    let navigateToFulfillOrders: INotificationPopupSettings = {
        open: navigationFulfillment,
        type: Constants.NotificationPopupType.Info,
        title: "",
        msg: "This Order doesn't belong to selected Store.",
        draggable: false,
        handleClose: navigateToFulfillment,
        actions: navigateToFulfillOrdersButtons
    }

    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }

    const imageViewer = (item: any) => {
        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ?? "-",
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.Quantity
        }

        setImagePopUp(true);
        setImageData(imageData);
    }
    const getItemTrackingNo = (item: any) => {
        let trackingNumberList = [];
        if (item.TrackingNo) {
            trackingNumberList.push(item.TrackingNo);
        }
        if (item.Component?.length != undefined && item.Component.length > 0) {
            item.Component.forEach((obj: any) => {
                if (obj.TrackingNo) {
                    trackingNumberList.push(obj.TrackingNo);
                }
            });
        }
        else if (item.Component?.TrackingNo) {
            trackingNumberList.push(item.Component.TrackingNo);
        }
        return trackingNumberList;
    }

    const getMobileItemTrackingNo = (item: any) => {
        let trackingNumberList = getItemTrackingNo(item);
        if (trackingNumberList.length > 0) {
            return trackingNumberList.join(", ");
        }
        return "";

    }

    const getCurrentStatus = async () => {
        const payload = {
            shipmentKey: shipmentkey,
            endpoint:"CustomerPickup"
        };
        let orderStatus = await PFManager.getOrderStatus(payload);
        if(orderStatus?.success && orderStatus?.data?.status)
        {
            setCurrentStatus(orderStatus.data.status);
        }
    }

    React.useEffect(() => {
        async function getCustomerPickDetail() {
            await getCustomerPickDetailFromOms();
            await getCurrentStatus();
            let data: any = await PFManager.getShipmentDataFromShippingCenter(shipmentkey);
            setOrderDetailFromExStore((data.data.hits.hits.length > 0 ? data.data.hits.hits[0]._source.Shipment : null));
        }
        getCustomerPickDetail()

    }, [props.store]);


    React.useEffect(() => {
        if (queryParameterStore && props.store?.length == 1 && props.store[0].id != queryParameterStore) {
            openNavigationFulfillment();
        }
    }, [props.store])

    const webView = () => {
        return (
            <div>
                <div style={{ margin: "20px" }}>
                    {/* <Paper className='paperToBottomPadding' style={{ padding: "10px" }}> */}
                    <Grid container spacing={2} display={"flex"}>
                        <Grid item xs={6} sm={6} className='textLeft'>
                            <Grid container display={"flex"}>
                                <Grid item>
                                    <Button onClick={() => navigateToFulfillment()} className="back-button" variant='contained' startIcon={<ArrowBackIosNewOutlinedIcon />}>Back</Button>
                                </Grid>
                                <Grid item style={{ marginLeft: "30px" }}>
                                    <Typography className="medium-heading">Order #</Typography>
                                    <Typography className="large-heading">{orderNumber}</Typography>
                                </Grid>
                            </Grid>
                            {/* <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                    Order # {orderNumber}
                                </Typography> */}
                        </Grid>
                        <Grid item xs={6} sm={6} className='textRight'>
                            <Button
                                style={{ marginTop: "4px", marginBottom: "6px" }}
                                disabled={selectedItems && selectedItems.length > 0 ? false : true}
                                variant="contained"
                                startIcon={<LocalPrintshopOutlinedIcon />}
                                className={selectedItems && selectedItems.length > 0 ? "header-button" : "header-button disabled"}
                                onClick={async () => {
                                    try {
                                        showActionLoader();
                                        console.log(orderDetails, "orderDetails")
                                        Utils.getPickupSlipDataFromPickup(orderLines, customerDetail, orderDetails, selectedItems, orderDetailFromExStore).then((printData: any) => {
                                            hideActionLoader();
                                            setPrintPickupSlipData(printData);
                                            setShowPickupSlip(showPickupSlip + 1);
                                            logger.info({
                                                tag: Constants.LOG_TYPES.CUSTOMER_PICKUP_SLIP,
                                                message: ""
                                            });

                                        })
                                    }
                                    catch (e) {
                                        hideActionLoader();
                                    }


                                }}>
                                Pickup Slip
                            </Button>
                        </Grid>
                    </Grid>
                    {/* </Paper> */}
                    <div style={{ marginTop: "26px" }}>
                        <Grid container spacing={2} display={"flex"}>
                            <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px", paddingLeft: "0px" }} className='textLeft'>

                                {
                                    !loadingNumberOfBoxes && numberOfBoxArr?.length > 0 ?
                                        <Card className="dCard" style={{ margin: 16 }}>
                                            <CardContent className="dCardContent">
                                                <Typography className="card-heading"> Number Of Boxes</Typography>
                                                <Divider />
                                                <NumberOfBoxes numberOfBoxArr={numberOfBoxArr}></NumberOfBoxes>
                                            </CardContent>
                                        </Card>
                                        : loadingNumberOfBoxes && numberOfBoxArr?.length > 0 ?
                                            <Card className="dCard" style={{ margin: 16 }}>
                                                <CardContent className="dCardContent">
                                                    <Typography className="card-heading"> Number Of Boxes</Typography>
                                                    <Divider />
                                                    <CustomerDetailShimmer></CustomerDetailShimmer>
                                                </CardContent>
                                            </Card>
                                            : <></>
                                }

                                <Card className="dCard" style={{ margin: 16 }}>
                                    <CardContent className="dCardContent">
                                        <Typography className="card-heading"> Order Lines</Typography>
                                        <Divider />
                                        <div style={{ marginTop: 3, padding: "20px" }}>
                                            {/* <Grid container spacing={2} display={"flex"}>
                                            <Grid item xs={1} sm={1} className='textLeft'>
                                            </Grid>
                                            <Grid item xs={7} sm={7} className='textLeft'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Description
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={2} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Qty Ready for Pickup
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={2} className='textCenter'>
                                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                    Tracking #
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider /> */}
                                            <div>
                                                {
                                                    !loadingOrderLines ?
                                                        <React.Fragment>
                                                            {
                                                                orderLines.map((item: any, index: any) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <Grid container spacing={1} display={"flex"} className="justifyBetween productHeader">
                                                                                <div>

                                                                                    {/* <Grid container spacing={2} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}> */}
                                                                                    {/* <Grid item xs={3} sm={3} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                                                        
                                                                                        <div className="image-zoom" onClick={() => imageViewer(item)}>
                                                                                            <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                                                                        </div>
                                                                                    </Grid> */}
                                                                                    <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">
                                                                                        <Grid item display={"flex"} style={{ alignItems: "center", justifyContent: "center" }}>
                                                                                            {Utils.disableUserAction(props.store) && <React.Fragment>
                                                                                                {orderLines.length != 1 && <Checkbox
                                                                                                    checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                                                                                                    onChange={(e) => changeSelectedItem(e, item.UniqueContainerKey)}
                                                                                                    style={{
                                                                                                        color: "#D93A2F",
                                                                                                    }} />
                                                                                                }
                                                                                            </React.Fragment>}
                                                                                        </Grid>
                                                                                        <Grid item display={"flex"} style={{ alignItems: "center", justifyContent: "center" }}>
                                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                                Qty
                                                                                            </Typography>
                                                                                            <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                                {item.Quantity}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item display={"flex"} style={{ alignItems: "center", justifyContent: "center" }}>
                                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                                Tracking #
                                                                                            </Typography>
                                                                                            {getItemTrackingNo(item).length <= 1 ? <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                                {getItemTrackingNo(item)[0]}
                                                                                            </Typography> :
                                                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                                                                                    <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                                        {
                                                                                                            getItemTrackingNo(item)[0]
                                                                                                        }
                                                                                                    </Typography>
                                                                                                    <Tooltip title={
                                                                                                        <React.Fragment>
                                                                                                            {getItemTrackingNo(item).map((value: any) => {
                                                                                                                return <Typography variant="body2" sx={{ fontSize: 15 }}>{value}</Typography>
                                                                                                            })}
                                                                                                        </React.Fragment>}>
                                                                                                        <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px", marginBottom: "5px" }} />
                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    {/* <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                                        <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{item.ItemDesc}</Typography>

                                                                                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                            SKU # <b>{item.ItemID}</b>
                                                                                        </Typography>
                                                                                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                            Price: <b>{item.UnitPrice}</b>
                                                                                        </Typography>
                                                                                    </Grid> */}
                                                                                    {/* </Grid> */}
                                                                                    {/* <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                                                <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                    {Number(item.Quantity)}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={2} sm={2} className='textCenter' style={{ marginTop: "8px" }}>
                                                                                {
                                                                                    getItemTrackingNo(item).length == 1 ?
                                                                                        <Typography style={{ wordBreak: "break-all" }} variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                            {
                                                                                                getItemTrackingNo(item)[0]
                                                                                            }
                                                                                        </Typography>
                                                                                        :
                                                                                        getItemTrackingNo(item).length > 1
                                                                                            ?
                                                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                                                                                <Typography style={{ wordBreak: "break-all" }} variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                                                                    {
                                                                                                        getItemTrackingNo(item)[0]
                                                                                                    }
                                                                                                </Typography>
                                                                                                <Tooltip title={
                                                                                                    <React.Fragment>
                                                                                                        {getItemTrackingNo(item).map((value: any) => {
                                                                                                            return <Typography variant="body2" sx={{ fontSize: 15 }}>{value}</Typography>
                                                                                                        })}
                                                                                                    </React.Fragment>}>
                                                                                                    <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px", marginBottom: "5px" }} />
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                            : ""

                                                                                }
                                                                            </Grid> */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid container spacing={2} display={"flex"} key={index}>
                                                                                <Grid item xs={12} sm={12} className='textLeft'>
                                                                                    <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                        <Grid item xs={3} sm={3} style={{ padding: "8px", display: "flex", justifyContent: "center" }}>
                                                                                            <div className="image-zoom" onClick={() => imageViewer(item)}>
                                                                                                <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                                            <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{item.ItemDesc}</Typography>
                                                                                            <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                                    <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                            SKU #
                                                                                                        </Typography>
                                                                                                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                            <b>{item.ItemID}</b>
                                                                                                        </Typography>
                                                                                                    </div>
                                                                                                    <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                            Price
                                                                                                        </Typography>
                                                                                                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                            <b>${Utils.nFormatter(item.UnitPrice, 2)}</b>
                                                                                                        </Typography>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    );
                                                                })

                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <OrderLineShimmer></OrderLineShimmer>
                                                }
                                            </div>
                                        </div>

                                    </CardContent>
                                </Card>

                                <Card className="dCard" style={{ margin: 16 }}>
                                    <CardContent className="dCardContent">
                                        <Typography className="card-heading"> Customer Details</Typography>
                                        <Divider />
                                        {
                                            !loadingOrderLines ?
                                                <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                                :
                                                // <div className="mainContainer" style={{ padding: "20px" }}>
                                                <CustomerDetailShimmer></CustomerDetailShimmer>
                                            // </div >

                                        }
                                    </CardContent>
                                </Card>

                            </Grid>
                            <Grid item xs={3} sm={3} className='textRight' style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px", marginTop: "16px" }}>
                                {generateMobileSavePrintView()}
                                {orderHistory()}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>);
    }

    const mobileView = () => {
        return (<div style={{ paddingBottom: "150px" }}>
            {/* <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                    <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}

                            onClick={() => { navigate(-1) }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-48px" }}>
                            Customer Pickup Order
                        </Typography>
                        <IconButton style={{ background: "#D93A2F", color: "white" }} sx={{ p: '10px' }} aria-label="actions">
                        </IconButton>
                    </Toolbar>
                </Paper> */}
            <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1, background: "#F9F9F9" }}>
                <Grid container display={"flex"} style={{ padding: "20px" }}>
                    <Grid item xs={12} sm={12} className='textLeft'>
                        <Grid container display={"flex"}>
                            <Grid item style={{ paddingLeft: "5px" }}>
                                <Button onClick={() => navigateToFulfillment()} className="back-button" variant='contained' startIcon={<ArrowBackIosNewOutlinedIcon />}>Back</Button>
                            </Grid>
                            <Grid item style={{ marginLeft: "10px" }}>
                                <Typography className="medium-heading">Order #</Typography>
                                <Typography className="large-heading">
                                    {orderNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Card style={{ margin: "6px" }} id='cardOrder'>
                        <CardContent style={{ padding: "10px" }}>
                            <Grid container spacing={2} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={12} sm={12} style={{ padding: "8px" }}>
                                    <Typography variant="h6" style={{ padding: "0px" }} className='scanColor'>
                                        Order #  {orderNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card> */}
                {Utils.disableUserAction(props.store) && <div style={{ padding: "0px 5px 0px 5px" }}>
                    <Button onClick={() => { setTrackingDrawer(true) }} style={{ marginTop: "4px", marginBottom: "4px", width: "98%" }} variant='contained' className='full-button' startIcon={<CheckIcon />}>Confirm</Button>
                </div>}

                {/* <Button onClick={() => setShowPickupSlip(showPickupSlip + 1)} style={{ marginTop: "4px", marginBottom: "4px", width: "98%" }} variant='contained' className={selectedItems && selectedItems.length > 0 ? "btn" : "btn disableClass"} disabled={selectedItems && selectedItems.length > 0 ? false : true} startIcon={<PrintIcon />}>Print Pickup Slip</Button> */}
                {
                    loadingNumberOfBoxes && numberOfBoxArr?.length <= 0 ?
                        <></>
                        :
                        <Accordion defaultExpanded style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ color: Constants.Colors.darkGrey, height: 80 }}
                            >
                                <div className="topHeading">
                                    <div className="gridHeading" style={{ display: "flex" }}>
                                        <Typography>Number Of Boxes</Typography>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails style={{ display: "block", padding: 0, overflowY: "scroll" }}>
                                {
                                    !loadingNumberOfBoxes && numberOfBoxArr?.length > 0 ?
                                        <NumberOfBoxes numberOfBoxArr={numberOfBoxArr}></NumberOfBoxes>
                                        : loadingNumberOfBoxes && numberOfBoxArr?.length > 0 ?
                                            <CustomerDetailShimmer></CustomerDetailShimmer>
                                            : <Typography style={{ textAlign: "center", marginTop: "3px" }}> No details found</Typography>
                                }
                            </AccordionDetails>
                        </Accordion>
                }

                <Accordion defaultExpanded style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ color: Constants.Colors.darkGrey, height: 80 }}
                    >
                        <div className="topHeading">
                            <div className="gridHeading" style={{ display: "flex" }}>
                                <Typography> Order Lines</Typography>
                            </div>
                        </div>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails style={{ display: "block", padding: 0, minHeight: "250px", overflowY: "scroll" }}>
                        {
                            !loadingOrderLines ?
                                <React.Fragment>
                                    {
                                        orderLines.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <Grid container spacing={1} display={"flex"} style={{ marginTop: "10px" }} className="justifyBetween productHeader">
                                                        <div>
                                                            <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">

                                                                <Grid item display={"flex"} style={{ alignItems: "center", justifyContent: "center" }}>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                        Qty
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                        {item.Quantity}
                                                                    </Typography>
                                                                </Grid>


                                                                <Grid item display={"flex"} style={{ alignItems: "center", justifyContent: "center" }}>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                        Tracking #
                                                                    </Typography>
                                                                    {getItemTrackingNo(item).length <= 1 ? <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                        {getItemTrackingNo(item)[0]}
                                                                    </Typography> :
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                                                            <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                {
                                                                                    getItemTrackingNo(item)[0]
                                                                                }
                                                                            </Typography>
                                                                            <Tooltip title={
                                                                                <React.Fragment>
                                                                                    {getItemTrackingNo(item).map((value: any) => {
                                                                                        return <Typography variant="body2" sx={{ fontSize: 15 }}>{value}</Typography>
                                                                                    })}
                                                                                </React.Fragment>}>
                                                                                <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px", marginBottom: "5px" }} />
                                                                            </Tooltip>
                                                                        </div>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                    <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                        {Utils.disableUserAction(props.store) && <React.Fragment>
                                                            {orderLines.length != 1 && <Checkbox style={{
                                                                color: "#D93A2F",
                                                            }}
                                                                onChange={(e) => changeSelectedItem(e, item.UniqueContainerKey)}
                                                            />}
                                                        </React.Fragment>}
                                                        <Grid item xs={4} sm={4} style={{ padding: "8px" }}>
                                                            <img style={{ height: "100px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} onClick={() => { imageViewer(item) }} />
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                                            <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{item.ItemDesc}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{ marginLeft: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                            <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                    SKU #
                                                                </Typography>
                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                    <b>{item.ItemID}</b>
                                                                </Typography>
                                                            </div>
                                                            <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                    Price
                                                                </Typography>
                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                    <b>${Utils.nFormatter(item.UnitPrice, 2)}</b>
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                // <Card style={{ margin: "6px" }} >
                                                //     <CardContent style={{ padding: "10px" }}>
                                                //         <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                //             <Grid item xs={3} sm={3} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                                //                 {Utils.disableUserAction(props.store) && <React.Fragment>
                                                //                     {orderLines.length != 1 && <Checkbox style={{
                                                //                         color: "#D93A2F",
                                                //                     }}
                                                //                         onChange={(e) => changeSelectedItem(e, item.UniqueContainerKey)}
                                                //                     />}
                                                //                 </React.Fragment>}
                                                //                 <img style={{ height: "85px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} onClick={() => imageViewer(item)} />
                                                //             </Grid>
                                                //             <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                //                 <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}><em>{item.ItemDesc}</em></Typography>
                                                //                 <Typography style={{ fontSize: "11px" }}>Qty Ready for Pickup : <b>{item.Quantity}</b></Typography>
                                                //                 <Typography style={{ fontSize: "11px" }}>SKU #: <b>{item.ItemID}</b></Typography>
                                                //                 <Typography style={{ fontSize: "11px" }}>Tracking #: <b>{getMobileItemTrackingNo(item)}</b></Typography>
                                                //                 <Typography style={{ fontSize: "11px" }}>Price: <b>$ {item.UnitPrice}</b></Typography>
                                                //             </Grid>

                                                //         </Grid>
                                                //     </CardContent>
                                                // </Card>
                                            );
                                        })

                                    }
                                </React.Fragment>
                                :
                                <OrderLineShimmerMobile></OrderLineShimmerMobile>
                        }
                    </AccordionDetails>
                </Accordion>

                <Accordion style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ color: Constants.Colors.darkGrey, height: 80 }}
                    >
                        <div className="topHeading">
                            <div className="gridHeading" style={{ display: "flex" }}>
                                <Typography>Customer Details</Typography>
                            </div>
                        </div>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails style={{ display: "block", padding: 0, overflowY: "scroll" }}>
                        {
                            !loadingOrderLines ?
                                <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                :
                                // <div className="mainContainer" style={{ padding: "20px" }}>
                                <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                            // </div >

                        }
                    </AccordionDetails>
                </Accordion>
                <OrderHistory store={props.store}></OrderHistory>
            </Paper>

            <div style={{ display: "none" }}>
                <OrderHistory store={props.store}></OrderHistory>
            </div>
            {/* <Card className='customerDetailsBoxPickOrder'>
                    <CardContent style={{ padding: "1px", paddingBottom: "10px", background: "#F3F3F4" }}>
                        <Button className="MobileDrawerButtons" onClick={() => { setDrawer(true) }} variant="outlined">Customer Details</Button>
                        <Button className="MobileDrawerButtons" onClick={() => { isHistoryLoading ? setHistoryDrawer(false) : setHistoryDrawer(true) }} variant="outlined">Fulfillment History</Button>
                    </CardContent>
                </Card> */}
            <Drawer
                anchor={"top"}
                open={openTrackingDrawer}
                onClose={() => { setTrackingDrawer(false) }}
            >
                <Card style={{ margin: "6px" }}>
                    <CardContent style={{ padding: "10px" }}>
                        {generateMobileSavePrintView()}
                    </CardContent>
                </Card>
            </Drawer>
            <Drawer
                anchor={"bottom"}
                open={openDrawer}
                onClose={() => { }}
                onClick={() => { setDrawer(false) }}
            >
                <Card style={{ margin: "6px" }}>
                    {
                        !loadingOrderLines ?
                            <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                            :
                            <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                    }
                </Card>
            </Drawer>
            <SwipeableDrawer
                anchor={"bottom"}
                open={openHistoryDrawer}
                onClose={() => { }}
                onOpen={() => { }}
                ModalProps={{ onBackdropClick: () => { setHistoryDrawer(false) } }}
            >

                <OrderHistory store={props.store}></OrderHistory>

            </SwipeableDrawer>
        </div>);
    }

    return (
        <div>
            <NotificationPopup {...ReportError}></NotificationPopup>
            <NotificationPopup {...confirmReportError}> </NotificationPopup>
            <NotificationPopup {...Error}>
            </NotificationPopup>
            <NotificationPopup {...notesCustomerPopup}>
            </NotificationPopup>
            <NotificationPopup {...navigateToFulfillOrders}>
            </NotificationPopup>
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
            {/* {Utils.isMobile ? <></> : <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.CustomerPickup}></SiteBreadCrumbs>} */}
            {Utils.isMobile ? mobileView() : webView()}
            {
                showPickupSlip > 0 ?
                    <PickupPrintSlip loadingOrderLines={loadingOrderLines} showPickupSlip={showPickupSlip} saveType={saveType} CustomIPickupSlip={printPickupSlipData} navigateAfterSuccess={true}></PickupPrintSlip>
                    :
                    <div></div>
            }

        </div>
    );
}
export default CustomerPickup;