import React from "react";
import { FormHelperText, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, TextField, Toolbar, Tooltip, Typography, List, ListItem } from "@mui/material";
import MuiInput from "@mui/material/Input";
import Constants from "../../Common/Constants";
import Utils from "../../Common/Utils";
import { CancelIcon, AddCircleIcon } from "../../Subcomponent/Shared/SharedIcons";
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/Actions/AllActions';
import PFManager from "../../Managers/Process.Fulfillment.Manager";
import StoreManager from "../../Managers/Store.manager";
import IStoreInfo from "../../Models/IStoreInfo";
import IBoxCharacteristic from "../../Models/IBoxCharacteristic";
import "./BoxCharacteristics.scss";
import LabelText from "../../Common/LabelText";
export const BoxCharacteristicts: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    let boxCharacteristicList: IBoxCharacteristic[] = useSelector((state: any) => state.PackOrderReducersState.boxCharacteristicList);
    let boxValues: IBoxCharacteristic[] = boxCharacteristicList.filter((box: any) => { return box.index == props.index })
    let selectedItems = useSelector((state: any) => state.PackOrderReducersState.selectedItems);
    const changeWeight = (event: any, index: number) => {
        if(event.target.value == "" || Number(event.target.value)>0)
        {
            let payload = {
                index: index,
                value: event.target.value,
                key: "weight"
            }
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));

            const weight = Number(event.target.value);
            //Show Error
            if(weight>0 && weight<=150)
            {
                let Error = boxValues[0].Error;
                Error.weight = false;
                Error.weightLimit = false;
                let payload = {
                    index: index,
                    value: Error,
                    key: "Error"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            }
            else{
                let Error = boxValues[0].Error;
                Error.weight = true;
                Error.weightLimit = weight > 150 ? true : false;
                let payload = {
                    index: index,
                    value: Error,
                    key: "Error"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            }
        }
    }
    
    const changeLength = (event: any, index: number) => {
        if(event.target.value == "" || Number(event.target.value)>0)
        {
            let payload = {
                index: index,
                value: event.target.value,
                key: "length"
            }
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            const length = Number(event.target.value);
            const girth = calculateGirth(Number(boxValues[0].height), Number(boxValues[0].width),length);
            //Show Error
            if(length>0 && length<=108)
            {
                let Error = boxValues[0].Error;
                Error.length = false;
                Error.lengthLimit = false;
                Error.girthLimit = girth > 165 ? true : false;
                let payload = {
                    index: index,
                    value: Error,
                    key: "Error"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            }
            else{
                let Error = boxValues[0].Error;
                Error.length = true;
                Error.lengthLimit = length > 108 ? true : false;
                Error.girthLimit = girth > 165 ? true : false;
                let payload = {
                    index: index,
                    value: Error,
                    key: "Error"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            }
        }

    }

    const calculateGirth = (height:any,width:any,length:any) => {
        let girth = 0;
        if(height>0 && width>0 && length>0)
        {
            girth = length + 2*(height + width);
        }
        return girth;
    }

    const changeWidth = (event: any, index: number) => {
        if(event.target.value == "" || Number(event.target.value)>0)
        {
            let payload = {
                index: index,
                value: event.target.value,
                key: "width"
            }
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));

            //Show Error
            let girth = calculateGirth(Number(boxValues[0].height), Number(event.target.value),Number(boxValues[0].length));
            if(girth > 0 && girth <= 165)
            {
                let Error = boxValues[0].Error;
                Error.height = false;
                Error.width = false;
                Error.girthLimit = false;
                let payload = {
                    index: index,
                    value: Error,
                    key: "Error"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            }
            else{
                let Error = boxValues[0].Error;
                Error.height = true;
                Error.width = true;
                Error.girthLimit = girth > 165 ? true : false;
                let payload = {
                    index: index,
                    value: Error,
                    key: "Error"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            }

        }

    }

     const changeHeight = (event: any, index: number) => {
        if(event.target.value == "" || Number(event.target.value)>0)
        {
            let payload = {
                index: index,
                value: event.target.value,
                key: "height"
            }
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));

            //Show Error
            let girth = calculateGirth(Number(event.target.value), Number(boxValues[0].width),Number(boxValues[0].length));
            if(girth > 0 && girth<=165)
            {
                let Error = boxValues[0].Error;
                Error.height = false;
                Error.width = false;
                Error.girthLimit = false;
                let payload = {
                    index: index,
                    value: Error,
                    key: "Error"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            }
            else{
                let Error = boxValues[0].Error;
                Error.height = true;
                Error.width = true;
                Error.girthLimit = girth > 165 ? true : false;
                let payload = {
                    index: index,
                    value: Error,
                    key: "Error"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            }

        }

    }

    const resetErrors = (index:any) => {
        //Remove Error Flags
        let error = boxValues[0].Error;
        error.height = false; 
        error.length = false; 
        error.width = false; 
        error.girthLimit = false;
        error.lengthLimit = false;
        let removeErrors = {
            index: index,
            value: error,
            key: "Error"
        }
        dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(removeErrors));
    }

     const initializeErrors = (index:any) => {
        //Initialize Error Flags
        let error = boxValues[0].Error;
        error.height = true; 
        error.length = true; 
        error.width = true;
        error.weight = true;
        let removeErrors = {
            index: index,
            value: error,
            key: "Error"
        }
        dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(removeErrors));
    }

    const changeBoxType = (event: any, index: number) => {
        let payloadBoxType = {
            index: index,
            value: event.target.value,
            key: "boxType"
        }
        //boxType
        dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payloadBoxType));
        let payloadDimensions = {
            index: index,
            value: event.target.value,
            key: "dimensions"
        }
        dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payloadDimensions));
        // L, W, H
        if (event.target.value == "Custom" || !event.target.value || event.target.value == "") {
            let payload = {
                index: index,
                value: "",
                key: "length"
            }
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            payload.key = "width";
            payload.value = "";
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            payload.key = "height";
            payload.value = "";
            dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
            initializeErrors(index);
        }
        else {

            let splittedDims: any = event.target.value.split("x");
            if (splittedDims && splittedDims.length > 0) {
                let payload = {
                    index: index,
                    value: splittedDims[0],
                    key: "length"
                }
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
                payload.key = "width";
                payload.value = splittedDims[1];
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
                payload.key = "height";
                payload.value = splittedDims[2];
                dispatch(allActions.packOrderActions.changeBoxCharacteristicByIndex(payload));
                resetErrors(index);

            }

        }

    }

    const addBox = () => {
        if (selectedItems.length == 1 && props.isBundle == false && props.disableMultiBox == false) {
            props.addBox()
        }
    }
    const removeBox = () => {
        if (selectedItems.length > 0) {
            props.removeBox(props.index)
        }
    }

    const noteSection = () => {
        return (boxValues[0].Error.lengthLimit || boxValues[0].Error.girthLimit || boxValues[0].Error.weightLimit)
    }

    return (
        <div>
            <Paper className={selectedItems.length > 0 ? "boxCharacterHeader" : "boxCharacterHeader"}>
                <Typography>
                    Package Detail
                </Typography>
                {props.index == 0 ?
                    <Tooltip onClick={() => addBox()} title="Add Package">
                        <AddCircleIcon className={selectedItems.length != 1 || props.isBundle == true || props.disableMultiBox == true ? "disabledIcon" : ""} />
                    </Tooltip>
                    :
                    <Tooltip onClick={() => removeBox()} title="Remove Package">
                        <CancelIcon />
                    </Tooltip>
                }
            </Paper>
            <Grid item xs={12} sm={12} className="inputField" style={{ paddingRight: "5px", paddingLeft: "5px", marginTop: "3px" }}>
                {/* <Typography className={selectedItems.length > 0 ? "" : "disabledColor"}>Container/Box Type</Typography> */}
                <FormControl>
                    <Select
                        disabled={selectedItems.length > 0 ? false : true}
                        error={!boxValues[0].dimensions}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={boxValues[0].boxType}
                        onChange={(event: SelectChangeEvent) => changeBoxType(event, props.index)}
                        variant="outlined"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                    >
                        <MenuItem value={0}>
                            Container/Box Type
                        </MenuItem>
                        {
                            Constants.SIZES.map((data: any, i: number) => <MenuItem value={data.value} key={i}>{data.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>
            <div style={{ padding: 5 }}>
                <Grid container style={{ display: boxValues[0].dimensions == "Custom" ? "" : "none" }}>
                    <Grid item xs={12} sm={12} className="inputField">
                        <Typography className={selectedItems.length > 0 ? "" : "disabledColor"}>Dimensions</Typography>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td>
                                    <TextField
                                        disabled={selectedItems.length > 0 && boxValues[0].dimensions == "Custom" ? false : true}
                                        error={boxValues[0].Error.length}
                                        fullWidth
                                        className="remove-buttons"
                                        InputLabelProps={{ shrink: false }}
                                        id="dimentions_length"
                                        placeholder="L"
                                        variant="outlined"
                                        value={boxValues[0].length}
                                        onChange={(event: any) => changeLength(event, props.index)}
                                        type={"text"}
                                    />
                                </td>
                                <td>
                                    x
                                </td>
                                <td>
                                    <TextField
                                        disabled={selectedItems.length > 0 && boxValues[0].dimensions == "Custom" ? false : true}
                                        error={boxValues[0].Error.width}
                                        fullWidth
                                        InputLabelProps={{ shrink: false }}
                                        className="remove-buttons"
                                        id="dimentions_width"
                                        placeholder="W"
                                        variant="outlined"
                                        value={boxValues[0].width}
                                        onChange={(event: any) => changeWidth(event, props.index)}
                                        
                                        type={"text"}
                                    />
                                </td>
                                <td>
                                    x
                                </td>
                                <td>
                                    <TextField
                                        disabled={selectedItems.length > 0 && boxValues[0].dimensions == "Custom" ? false : true}
                                        error={boxValues[0].Error.height}
                                        fullWidth
                                        InputLabelProps={{ shrink: false }}
                                        id="dimentions+heuight"
                                        className="remove-buttons"
                                        placeholder="H"
                                        variant="outlined"
                                        value={boxValues[0].height}
                                        onChange={(event: any) => changeHeight(event, props.index)}
                                        type={"text"}
                                    />
                                </td>
                            </tr>
                        </table>

                    </Grid>
                    <List dense disablePadding>
                        {boxValues[0].Error.lengthLimit && <ListItem className="listItem">
                            <Typography className="textFieldError">&bull; {LabelText.lengthLimit}</Typography>
                        </ListItem>}
                        {boxValues[0].Error.girthLimit && <ListItem className="listItem">
                            <Typography className="textFieldError">&bull; {LabelText.girthLimit}</Typography>
                        </ListItem>}
                    </List>
                </Grid>
                <Grid item xs={12} sm={12} className="inputField">
                    {/* <Typography className={selectedItems.length > 0 ? "" : "disabledColor"}>Weight</Typography> */}
                    <TextField
                        disabled={selectedItems.length > 0 ? false : true}
                        error={boxValues[0].Error.weight}
                        type={"text"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">lb</InputAdornment>
                            ),
                            inputProps: { min: 0, pattern: '[0-9]*' }
                        }}
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        id="weight"
                        placeholder="Weight"
                        variant="outlined"
                        value={boxValues[0].weight.replace(/[^\d.]/g, '')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            // Filter out non-numeric characters
                            const numericValue = event.target.value.replace(/[^\d.]/g, '');;
                            // Update the state with the filtered numeric value
                            changeWeight({ ...event, target: { ...event.target, value: numericValue } }, props.index);
                        }}

                    />
                </Grid>
                {boxValues[0].Error.weightLimit &&  <Grid item xs={12} sm={12} className="inputField">
                    <Typography className="textFieldError">{LabelText.weightLimit}</Typography>
                </Grid>}
                
                {noteSection() && <Grid item xs={12} sm={12} className="inputField">
                    <Paper
                        style={{
                            color: "#555555",
                            fontSize: "1rem",
                            textAlign: "left",
                            padding: "8px",
                            backgroundColor: "#EEEEEE",
                            margin: "5px 4px",
                            borderLeft: "4px solid #3874BA",  // Add border-left style for blue vertical line
                        }}
                    >
                        <Grid style={{display:"flex"}}>
                            <p style={{ fontSize: 14, marginRight: 2 }} >
                                <b>Note: </b>{LabelText.dimensionsNote}
                            </p>         
                        </Grid>
                    </Paper>
                </Grid>}
            </div>
        </div>
    );
};
