import ApiService from "../Common/ApiService";
import { OmsPickOrderDetail, ShipmentLine } from "../Models/OmsPickOrderDetail";
import { OmsPackOrderDetail, Container, ShipmentLine2 } from "../Models/OmsPackOrderDetails";
import { RequestResourceLine } from "../Models/RequestResourceLine";
import { ICustomerDetail } from "../Models/IPickOrder";
import ApiURLS from "../Common/ApiURLS";
import IdentityManager from "./Identity.manager";
import StoreManager from "./Store.manager";
import { ICatalogCategory } from "../Models/ICatalogCategory";
import Constants from "../Common/Constants";
import Utils from "../Common/Utils";
import { StoreIcon } from "../Subcomponent/Shared/SharedIcons";
import ShipmentStatusConstants from "../Common/ShipmentStatusConstants";
import { IResourceItemInput } from "../Models/IResourceItemInput";
export default class OMSManager {

    public static catalogCategories: ICatalogCategory = { Category: [] }

    public static getCustomerDetailsFromElastic = async (shipmentKey: any) => {
        try {
            let response = await ApiService.get(`/pf/getCustomerDetailsFromElastic?shipmentKey=${shipmentKey}`)
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    public static getPickOrdersDetail = async (shipmentNo: any) => {

        let payload = {
            "Shipment": {
                "ShipmentKey": shipmentNo
            }
        }
        try {
            let response = await ApiService.post(`/oms/GCGetPickDetailView`, payload);
            if (response.success && response.data?.ShipmentLines?.ShipmentLine?.length > 0) {
                let _shipmentLines = response.data.ShipmentLines.ShipmentLine;
                _shipmentLines.forEach((line: any) => {
                    if (line.hasOwnProperty("CustomComponents") && typeof (line.CustomComponents.ShipmentLine === "object")
                        && !Array.isArray(line?.CustomComponents?.ShipmentLine)) {
                        line.CustomComponents.ShipmentLine = [line.CustomComponents.ShipmentLine];
                    }
                    if (line.hasOwnProperty("WarrantyComponents") && typeof (line.WarrantyComponents.ShipmentLine === "object")
                        && !Array.isArray(line.WarrantyComponents.ShipmentLine)) {
                        line.WarrantyComponents.ShipmentLine = [line.WarrantyComponents.ShipmentLine];
                    }

                });
            }
            return response


            // let response = await ApiService.post(`/oms/GCGetPickDetailView`, payload);
            // return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    public static getPickOrdersDetailNom = async (shipmentNo: any) => {

        let payload = {
            "Shipment": {
                "ShipmentKey": shipmentNo
            }
        }
        try {
            let response = await ApiService.nomPost(ApiURLS.NOM.getPickOrdersDetailNom.URL, payload);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    public static genericPickOrderDetails = async (shipmentNo: any) => {

        let payload = {
            "Shipment": {
                "ShipmentKey": shipmentNo
            }
        }
        try {
            let response = await ApiService.post(`/oms/GCGetPickDetailView`, payload);
            if (response.success && response.data?.ShipmentLines?.ShipmentLine?.length > 0) {
                let _shipmentLines = response.data.ShipmentLines.ShipmentLine;
                _shipmentLines.forEach((line: any) => {
                    if (line.hasOwnProperty("CustomComponents") && typeof (line.CustomComponents.ShipmentLine === "object")
                        && !Array.isArray(line?.CustomComponents?.ShipmentLine)) {
                        line.CustomComponents.ShipmentLine = [line.CustomComponents.ShipmentLine];
                    }
                    if (line.hasOwnProperty("WarrantyComponents") && typeof (line.WarrantyComponents.ShipmentLine === "object")
                        && !Array.isArray(line.WarrantyComponents.ShipmentLine)) {
                        line.WarrantyComponents.ShipmentLine = [line.WarrantyComponents.ShipmentLine];
                    }

                });
            }
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    public static getPackOrdersDetail = async (shipmentNo: any) => {
        let payload = {
            "Shipment": {
                "ShipmentKey": shipmentNo
            }
        }
        try {
            let response = await ApiService.post(`/oms/GCGetPackDetailView`, payload);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    public static getItemLocationDetails = async (itemIdsWithLocationsPayload: any) => {
        try {
            let response = await ApiService.post(`/oms/OMSGetPosCycleCount`, itemIdsWithLocationsPayload);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    public static getReceiveOrdersDetail = async (shipmentKey: any, shipmentContainerKey: any) => {
        let payload = {
            "Container": {
                "ShipmentKey": shipmentKey,
                "ShipmentContainerKey": shipmentContainerKey
            }
        }
        try {
            let response = await ApiService.post(`/oms/GCGetReceiptDetailView`, payload);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }
    public static getGCCustomerPickupDetailView = async (storeNumber: any, OrderNo: any) => {
        let payload = { "ReadyForPickUpList": { "PickUpStore": storeNumber, "OrderNo": OrderNo, "PurchaseOrderNo": "", "PurchaseOrderHeaderKey": "" } }

        try {
            let response = await ApiService.post(`/oms/GCCustomerPickupDetailView`, payload);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    public static scanSkue = async (orderDetails: OmsPickOrderDetail, shipLine: ShipmentLine, qty: any, sku: any) => {

        let payLoad = {
            "Shipment": {
                "ShipmentKey": "",
                "IsManagerApproval": "",
                "Action": "",
                "EnterpriseCode": "",
                "ShipNode": orderDetails.ShipNode.ShipNode,
                "ReasonCode": "",
                "ReasonText": "",
                "ShipmentLines": {
                    "ShipmentLine": [
                        {
                            "ShipmentLineNo": shipLine.ShipmentLineNo,
                            "OrderLineKey": shipLine.OrderLineKey,
                            "ProductClass": shipLine.ProductClass && shipLine.ProductClass.length > 0 ? shipLine.ProductClass : "REGULAR",
                            "Quantity": shipLine.Quantity,
                            "ShipmentLineKey": shipLine.ShipmentLineKey,
                            "POSItemID": sku,
                            "Inv.input": sku,
                            "Extn": shipLine.OrderLine.ItemDetails.Extn,
                            "QuantityPicked": qty
                        }
                    ]
                },
                "ParentIndex": -1,
                "Index": 0
            }
        }

        try {
            let response = await ApiService.post(`/oms/GCPickScanSku`, payLoad);
            return response
        }
        catch (error) {
            console.log(error);
            throw error
        }
    }
    public static scanSkueUpdated = async (QuantityPicked: string, ScanNo: string, ShipmentKey: string, ShipNode: string) => {
        let payload = {
            "Shipment": {
                "ShipNode": ShipNode,
                "ShipmentKey": ShipmentKey,
                "ShipmentLines": {
                    "ShipmentLine": [
                        {
                            "ScanNo": ScanNo,
                            "QuantityPicked": QuantityPicked
                        }
                    ]
                }
            }
        }

        try {
            let response = await ApiService.post(`/oms/GCPickScanSkuV2`, payload);
            return response
        }
        catch (error) {
            console.log(error);
            throw error
        }
    }

    public static GCPickConfirm = async (orderDetail: OmsPickOrderDetail, onHold?: any) => {
        let action = "ConfirmPick" //PartialPick;
        let shipmentKey = orderDetail.ShipmentKey;
        let currentStatus = orderDetail.Status;
        let payLoad = {

            Shipment: {

                ShipmentKey: orderDetail.ShipmentKey,
                Action: "",
                UserID: OMSManager.GetUserIdFromLocalStorage(),
                EmployeeID: OMSManager.GetEmployeeId(),
                IsManagerApproval: "N",
                ShipNode: {
                    ShipNode: orderDetail.ShipNode.ShipNode
                },
                ShipmentLines: {
                    ShipmentLine: []
                }

            }
        } as any;

        for (let i = 0; i < orderDetail.ShipmentLines.ShipmentLine.length; i++) {

            let currentLine = orderDetail.ShipmentLines.ShipmentLine[i];
            let scanItem = {} as any;
            if (currentLine.GCPickScanSkuResult || (!currentLine.GCPickScanSkuResult && currentLine.hasOwnProperty("CustomComponents"))) {
                // if (Number(currentLine.Quantity) - Number(currentLine.ScannedQty) != 0) {
                //     action = "PartialPick"
                // }
                scanItem = {

                    ActualSerialNo: currentLine?.GCPickScanSkuResult?.SerialNumber,
                    ItemID: currentLine.ItemID,
                    PrimeLineNo: currentLine.PrimeLineNo,
                    Quantity: currentLine.Quantity,
                    QuantityPicked: currentLine.ScannedQty ? currentLine.ScannedQty : currentLine.Quantity,
                    RequestedTagNo: "",
                    ShipmentLineKey: currentLine.ShipmentLineKey,
                    SKUNumber: currentLine?.GCPickScanSkuResult?.SKUNumber

                } as any;


            }

            //
            // Stand Alone Warranty inside Top level for an shipment
            if (currentLine.hasOwnProperty("WarrantyComponents")) {
                //
                // Initialize waranty components
                scanItem.WarrantyComponents = {};
                let warrantyComp = currentLine.WarrantyComponents;
                if (Array.isArray(currentLine.WarrantyComponents?.ShipmentLine)) {
                    scanItem.WarrantyComponents.ShipmentLine = [];
                    for (let w = 0; w < currentLine.WarrantyComponents?.ShipmentLine.length; w++) {
                        if (warrantyComp && warrantyComp?.ShipmentLine[w].GCPickScanSkuResult) {
                            let warrantyScan = {
                                ActualSerialNo: warrantyComp.ShipmentLine[w]?.GCPickScanSkuResult?.SerialNumber,
                                ItemID: warrantyComp.ShipmentLine[w].ItemID,
                                PrimeLineNo: warrantyComp.ShipmentLine[w].PrimeLineNo,
                                Quantity: warrantyComp.ShipmentLine[w]?.Quantity,
                                OriginalQuantity: warrantyComp.ShipmentLine[w]?.OriginalQuantity,
                                //QuantityPicked: warrantyComp.ShipmentLine?.ScannedQty,
                                RequestedTagNo: "",
                                ShipmentLineKey: warrantyComp.ShipmentLine[w]?.ShipmentLineKey,
                                SKUNumber: warrantyComp.ShipmentLine[w]?.GCPickScanSkuResult?.SKUNumber
                            }
                            //
                            scanItem.WarrantyComponents.ShipmentLine.push(warrantyScan);
                        }
                    }
                }
                else {
                    if (warrantyComp && warrantyComp?.ShipmentLine.GCPickScanSkuResult) {
                        scanItem.WarrantyComponents.ShipmentLine = [];
                        let warrantyScan = {
                            ActualSerialNo: warrantyComp.ShipmentLine?.GCPickScanSkuResult?.SerialNumber,
                            ItemID: warrantyComp.ShipmentLine.ItemID,
                            PrimeLineNo: warrantyComp.ShipmentLine.PrimeLineNo,
                            Quantity: warrantyComp.ShipmentLine?.Quantity,
                            OriginalQuantity: warrantyComp.ShipmentLine?.OriginalQuantity,
                            //QuantityPicked: warrantyComp.ShipmentLine?.ScannedQty,
                            RequestedTagNo: "",
                            ShipmentLineKey: warrantyComp.ShipmentLine?.ShipmentLineKey,
                            SKUNumber: warrantyComp.ShipmentLine?.GCPickScanSkuResult?.SKUNumber
                        }
                        //
                        scanItem.WarrantyComponents.ShipmentLine.push(warrantyScan);
                    }
                }


            }


            // Custom Components
            //
            if (currentLine.hasOwnProperty("CustomComponents")) {
                //
                let warrantyScan = [] as any
                scanItem.CustomComponents = {};

                if (currentLine.CustomComponents && currentLine.CustomComponents.ShipmentLine.length > 0) {
                    scanItem.CustomComponents.ShipmentLine = [];

                    for (let j = 0; j < currentLine.CustomComponents.ShipmentLine.length; j++) {

                        let currentCustComp = currentLine.CustomComponents.ShipmentLine[j];
                        let custCompScan = {} as any;

                        if (currentCustComp.GCPickScanSkuResult) {
                            // if (Number(currentCustComp.Quantity) - Number(currentCustComp.ScannedQty) != 0) {
                            //     action = "PartialPick"
                            // }
                            custCompScan = {
                                ActualSerialNo: currentCustComp.GCPickScanSkuResult?.SerialNumber,
                                ItemID: currentCustComp.ItemID,
                                PrimeLineNo: currentCustComp.PrimeLineNo,
                                Quantity: currentCustComp.Quantity,
                                QuantityPicked: currentCustComp.ScannedQty,
                                RequestedTagNo: "",
                                ShipmentLineKey: currentCustComp.ShipmentLineKey,
                                SKUNumber: currentCustComp.GCPickScanSkuResult?.SKUNumber
                            }
                        }

                        if (currentCustComp.WarrantyComponents) {
                            let custCompWarranty = currentCustComp.WarrantyComponents;
                            if (Array.isArray(currentCustComp.WarrantyComponents?.ShipmentLine)) {
                                for (let w = 0; w < currentCustComp.WarrantyComponents?.ShipmentLine.length; w++) {
                                    if (custCompWarranty?.ShipmentLine[w].GCPickScanSkuResult) {
                                        let custCompWarrantyScan = {
                                            ActualSerialNo: custCompWarranty.ShipmentLine[w]?.GCPickScanSkuResult?.SerialNumber,
                                            ItemID: custCompWarranty.ShipmentLine[w].ItemID,
                                            PrimeLineNo: custCompWarranty.ShipmentLine[w].PrimeLineNo,
                                            Quantity: custCompWarranty.ShipmentLine[w]?.Quantity,
                                            OriginalQuantity: custCompWarranty.ShipmentLine[w]?.OriginalQuantity,
                                            //QuantityPicked: warrantyComp.ShipmentLine?.ScannedQty,
                                            RequestedTagNo: "",
                                            ShipmentLineKey: custCompWarranty.ShipmentLine[w]?.ShipmentLineKey,
                                            SKUNumber: custCompWarranty.ShipmentLine[w]?.GCPickScanSkuResult?.SKUNumber
                                        }
                                        //
                                        custCompScan.WarrantyComponents = { ShipmentLine: [] }
                                        custCompScan.WarrantyComponents.ShipmentLine.push(custCompWarrantyScan)
                                        warrantyScan.push(custCompWarrantyScan)
                                    }
                                }
                            }
                            else {
                                let custCompWarrantyScan = {
                                    ActualSerialNo: custCompWarranty.ShipmentLine?.GCPickScanSkuResult?.SerialNumber,
                                    ItemID: custCompWarranty.ShipmentLine.ItemID,
                                    PrimeLineNo: custCompWarranty.ShipmentLine.PrimeLineNo,
                                    Quantity: custCompWarranty.ShipmentLine.Quantity,
                                    RequestedTagNo: "",
                                    ShipmentLineKey: custCompWarranty.ShipmentLine.ShipmentLineKey,
                                    SKUNumber: custCompWarranty?.ShipmentLine?.GCPickScanSkuResult?.SKUNumber
                                }
                                custCompScan.WarrantyComponents = { ShipmentLine: [] }
                                custCompScan.WarrantyComponents.ShipmentLine.push(custCompWarrantyScan)
                                warrantyScan.push(custCompWarrantyScan)
                            }
                        }
                        scanItem.CustomComponents.ShipmentLine.push(custCompScan);

                    }
                    if (warrantyScan.length > 0) {
                        if (!(scanItem.WarrantyComponents && scanItem.WarrantyComponents.ShipmentLine)) {
                            scanItem.WarrantyComponents = { ShipmentLine: [] } as any
                        }
                        scanItem.WarrantyComponents.ShipmentLine = warrantyScan;
                    }
                }
            }

            if (currentLine?.GCPickScanSkuResult?.GCSalesPersonList?.GCSalesPerson) {
                scanItem.GCSalesPersonList = currentLine.GCPickScanSkuResult.GCSalesPersonList
            }
            payLoad.Shipment.ShipmentLines.ShipmentLine.push(scanItem);
        }
        //To On Hold Order or Releasing it.
        if (onHold) {
            payLoad.Shipment.IsActionForPickHoldStatus = "Y";
        }

        payLoad.Shipment.Action = action
        //
        console.log(payLoad)
        try {
            let response = await ApiService.post(`/oms/GCPickConfirm`, payLoad,shipmentKey,currentStatus);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }


    }
    public static GCOnHoldPickConfirm = async (orderDetail: OmsPickOrderDetail) => {
        let action = "ConfirmPick" //PartialPick
        let shipmentKey = orderDetail.ShipmentKey;
        let currentStatus = orderDetail.Status;
        let payLoad = {

            Shipment: {

                ShipmentKey: orderDetail.ShipmentKey,
                Action: "",
                UserID: OMSManager.GetUserIdFromLocalStorage(),
                EmployeeID: OMSManager.GetEmployeeId(),
                IsManagerApproval: "N",
                ShipNode: {
                    ShipNode: orderDetail.ShipNode.ShipNode
                },
                ShipmentLines: {
                    ShipmentLine: []
                }

            }
        } as any;

        for (let i = 0; i < orderDetail.ShipmentLines.ShipmentLine.length; i++) {

            let currentLine = orderDetail.ShipmentLines.ShipmentLine[i];
            let scanItem = {} as any;
            scanItem = {

                ActualSerialNo: "",//Need From OMS Detail
                ItemID: currentLine.ItemID,
                PrimeLineNo: currentLine.PrimeLineNo,
                Quantity: currentLine.Quantity,
                QuantityPicked: currentLine.Quantity,
                RequestedTagNo: "",
                ShipmentLineKey: currentLine.ShipmentLineKey,
                SKUNumber: "",//Need From OMS Detail

            } as any;

            //
            // Stand Alone Warranty inside Top level for an shipment
            if (currentLine.hasOwnProperty("WarrantyComponents")) {
                //
                // Initialize waranty components
                scanItem.WarrantyComponents = {};
                let warrantyComp = currentLine.WarrantyComponents;
                if (Array.isArray(currentLine.WarrantyComponents?.ShipmentLine)) {
                    scanItem.WarrantyComponents.ShipmentLine = [];
                    for (let w = 0; w < currentLine.WarrantyComponents?.ShipmentLine.length; w++) {
                        let warrantyScan = {
                            ActualSerialNo: "",//Need From OMS Detail
                            ItemID: warrantyComp.ShipmentLine[w].ItemID,
                            PrimeLineNo: warrantyComp.ShipmentLine[w].PrimeLineNo,
                            Quantity: warrantyComp.ShipmentLine[w]?.Quantity,
                            OriginalQuantity: warrantyComp.ShipmentLine[w]?.OriginalQuantity,
                            //QuantityPicked: warrantyComp.ShipmentLine?.ScannedQty,
                            RequestedTagNo: "",
                            ShipmentLineKey: warrantyComp.ShipmentLine[w]?.ShipmentLineKey,
                            SKUNumber: "",//Need From OMS Detail
                        }
                        //
                        scanItem.WarrantyComponents.ShipmentLine.push(warrantyScan);
                    }
                }
                else {
                    scanItem.WarrantyComponents.ShipmentLine = [];
                    let warrantyScan = {
                        ActualSerialNo: "",//Need From OMS Detail
                        ItemID: warrantyComp.ShipmentLine.ItemID,
                        PrimeLineNo: warrantyComp.ShipmentLine.PrimeLineNo,
                        Quantity: warrantyComp.ShipmentLine?.Quantity,
                        OriginalQuantity: warrantyComp.ShipmentLine?.OriginalQuantity,
                        //QuantityPicked: warrantyComp.ShipmentLine?.ScannedQty,
                        RequestedTagNo: "",
                        ShipmentLineKey: warrantyComp.ShipmentLine?.ShipmentLineKey,
                        SKUNumber: "",//Need From OMS Detail
                    }
                    //
                    scanItem.WarrantyComponents.ShipmentLine.push(warrantyScan);
                }


            }


            // Custom Components
            //
            if (currentLine.hasOwnProperty("CustomComponents")) {
                //
                let warrantyScan = [] as any
                scanItem.CustomComponents = {};

                if (currentLine.CustomComponents && currentLine.CustomComponents.ShipmentLine.length > 0) {
                    scanItem.CustomComponents.ShipmentLine = [];

                    for (let j = 0; j < currentLine.CustomComponents.ShipmentLine.length; j++) {

                        let currentCustComp = currentLine.CustomComponents.ShipmentLine[j];
                        let custCompScan = {} as any;
                        custCompScan = {
                            ActualSerialNo: "",//Need From OMS Detail
                            ItemID: currentCustComp.ItemID,
                            PrimeLineNo: currentCustComp.PrimeLineNo,
                            Quantity: currentCustComp.Quantity,
                            QuantityPicked: currentCustComp.Quantity,
                            RequestedTagNo: "",
                            ShipmentLineKey: currentCustComp.ShipmentLineKey,
                            SKUNumber: "",//Need From OMS Detail
                        }

                        if (currentCustComp.WarrantyComponents) {
                            let custCompWarranty = currentCustComp.WarrantyComponents;
                            if (Array.isArray(currentCustComp.WarrantyComponents?.ShipmentLine)) {
                                for (let w = 0; w < currentCustComp.WarrantyComponents?.ShipmentLine.length; w++) {

                                    let custCompWarrantyScan = {
                                        ActualSerialNo: "",//Need From OMS Detail
                                        ItemID: custCompWarranty.ShipmentLine[w].ItemID,
                                        PrimeLineNo: custCompWarranty.ShipmentLine[w].PrimeLineNo,
                                        Quantity: custCompWarranty.ShipmentLine[w]?.Quantity,
                                        OriginalQuantity: custCompWarranty.ShipmentLine[w]?.OriginalQuantity,
                                        //QuantityPicked: warrantyComp.ShipmentLine?.ScannedQty,
                                        RequestedTagNo: "",
                                        ShipmentLineKey: custCompWarranty.ShipmentLine[w]?.ShipmentLineKey,
                                        SKUNumber: "",//Need From OMS Detail
                                    }
                                    //
                                    custCompScan.WarrantyComponents = { ShipmentLine: [] }
                                    custCompScan.WarrantyComponents.ShipmentLine.push(custCompWarrantyScan)
                                    warrantyScan.push(custCompWarrantyScan);
                                }
                            }
                            else {
                                let custCompWarrantyScan = {
                                    ActualSerialNo: "",//Need From OMS Detail
                                    ItemID: custCompWarranty.ShipmentLine.ItemID,
                                    PrimeLineNo: custCompWarranty.ShipmentLine.PrimeLineNo,
                                    Quantity: custCompWarranty.ShipmentLine.Quantity,
                                    RequestedTagNo: "",
                                    ShipmentLineKey: custCompWarranty.ShipmentLine.ShipmentLineKey,
                                    SKUNumber: "",//Need From OMS Detail
                                }
                                custCompScan.WarrantyComponents = { ShipmentLine: [] }
                                custCompScan.WarrantyComponents.ShipmentLine.push(custCompWarrantyScan)
                                warrantyScan.push(custCompWarrantyScan)
                            }
                        }
                        scanItem.CustomComponents.ShipmentLine.push(custCompScan);

                    }
                    if (warrantyScan.length > 0) {
                        if (!(scanItem.WarrantyComponents && scanItem.WarrantyComponents.ShipmentLine)) {
                            scanItem.WarrantyComponents = { ShipmentLine: [] } as any
                        }
                        scanItem.WarrantyComponents.ShipmentLine = warrantyScan;
                    }
                }
            }

            //Sales Person List Required
            // if (currentLine?.GCPickScanSkuResult?.GCSalesPersonList?.GCSalesPerson) {
            //     scanItem.GCSalesPersonList = currentLine.GCPickScanSkuResult.GCSalesPersonList
            // }
            payLoad.Shipment.ShipmentLines.ShipmentLine.push(scanItem);
        }
        //Release Order
        payLoad.Shipment.IsReleaseFromPickHoldStatus = "Y";
        payLoad.Shipment.Action = action;
        console.log(payLoad)
        try {
            let response = await ApiService.post(`/oms/GCPickConfirm`, payLoad, shipmentKey, currentStatus);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }


    }

    public static GCUnHoldPickConfirm = async (orderDetail: OmsPickOrderDetail) => {

        let payLoad = {
            Shipment: {
                OrderHeaderKey: orderDetail.OrderHeaderKey,
                EnterpriseCode: orderDetail.EnterpriseCode,
                IsManagerApproval: "Y",
                ShipNode: orderDetail.ShipNode.ShipNode,
                ShipmentKey: orderDetail.ShipmentKey,
                UserID: OMSManager.GetUserIdFromLocalStorage(),
                EmployeeID: OMSManager.GetEmployeeId(),
                ShipmentLines: {
                    ShipmentLine: []
                }
            }
        } as any;

        for (let i = 0; i < orderDetail.ShipmentLines.ShipmentLine.length; i++) {

            let currentLine = orderDetail.ShipmentLines.ShipmentLine[i];
            let scanItem = {} as any;
            scanItem = {
                OrderLine: { "OrderLineKey": currentLine.OrderLineKey, FulfillmentType: orderDetail.FulfillmentType },
                KitCode: currentLine.KitCode,
                OrderHeaderKey: currentLine.OrderHeaderKey,
                OrderReleaseKey: currentLine.OrderReleaseKey,
                OrderLineKey: currentLine.OrderLineKey,
                ProductClass: currentLine.ProductClass,
                ShipmentLineKey: currentLine.ShipmentLineKey,
                Quantity: currentLine.Quantity,


            } as any;

            // Stand Alone Warranty inside Top level for an shipment
            if (currentLine.hasOwnProperty("WarrantyComponents")) {
                //
                // Initialize waranty components
                // scanItem.WarrantyComponents = {};
                let warrantyComp = currentLine.WarrantyComponents;
                if (Array.isArray(currentLine.WarrantyComponents?.ShipmentLine)) {
                    scanItem.WarrantyComponents.ShipmentLine = [];
                    for (let w = 0; w < currentLine.WarrantyComponents?.ShipmentLine.length; w++) {
                        let warrantyScan = {
                            OrderLineKey: warrantyComp.ShipmentLine[w]?.OrderLineKey,
                            OrderReleaseKey: warrantyComp.ShipmentLine[w]?.OrderReleaseKey,
                            Quantity: warrantyComp.ShipmentLine[w]?.Quantity,
                            OriginalQuantity: warrantyComp.ShipmentLine[w]?.OriginalQuantity,
                            ShipmentLineKey: warrantyComp.ShipmentLine[w]?.ShipmentLineKey,

                        }
                        //
                        scanItem.WarrantyComponents.ShipmentLine.push(warrantyScan);
                    }
                }
                else {
                    scanItem.WarrantyComponents.ShipmentLine = [];
                    let warrantyScan = {
                        OrderLineKey: warrantyComp.ShipmentLine?.OrderLineKey,
                        OrderReleaseKey: warrantyComp.ShipmentLine?.OrderReleaseKey,
                        Quantity: warrantyComp.ShipmentLine?.Quantity,
                        OriginalQuantity: warrantyComp.ShipmentLine?.OriginalQuantity,
                        ShipmentLineKey: warrantyComp.ShipmentLine?.ShipmentLineKey,

                    }
                    //
                    scanItem.WarrantyComponents.ShipmentLine.push(warrantyScan);
                }


            }


            // Custom Components
            //
            if (currentLine.hasOwnProperty("CustomComponents")) {
                //
                let warrantyScan = [] as any

                if (currentLine.CustomComponents && currentLine.CustomComponents.ShipmentLine.length > 0) {
                    scanItem.CustomComponents.ShipmentLine = [];

                    for (let j = 0; j < currentLine.CustomComponents.ShipmentLine.length; j++) {

                        let currentCustComp = currentLine.CustomComponents.ShipmentLine[j];
                        let custCompScan = {} as any;
                        custCompScan = {
                            OrderLineKey: currentCustComp.OrderLineKey,
                            OrderReleaseKey: currentCustComp.OrderReleaseKey,
                            Quantity: currentCustComp.Quantity,
                            ShipmentLineKey: currentCustComp.ShipmentLineKey,

                        }

                        if (currentCustComp.WarrantyComponents) {
                            let custCompWarranty = currentCustComp.WarrantyComponents;
                            if (Array.isArray(currentCustComp.WarrantyComponents?.ShipmentLine)) {
                                for (let w = 0; w < currentCustComp.WarrantyComponents?.ShipmentLine.length; w++) {

                                    let custCompWarrantyScan = {
                                        OrderLineKey: custCompWarranty.ShipmentLine[w]?.OrderLineKey,
                                        OrderReleaseKey: custCompWarranty.ShipmentLine[w]?.OrderReleaseKey,
                                        Quantity: custCompWarranty.ShipmentLine[w]?.Quantity,
                                        OriginalQuantity: custCompWarranty.ShipmentLine[w]?.OriginalQuantity,
                                        ShipmentLineKey: custCompWarranty.ShipmentLine[w]?.ShipmentLineKey,

                                    }
                                    //
                                    custCompScan.WarrantyComponents = { ShipmentLine: [] }
                                    custCompScan.WarrantyComponents.ShipmentLine.push(custCompWarrantyScan)
                                    warrantyScan.push(custCompWarrantyScan);
                                }
                            }
                            else {
                                let custCompWarrantyScan = {
                                    OrderLineKey: custCompWarranty.ShipmentLine.OrderLineKey,
                                    OrderReleaseKey: custCompWarranty.ShipmentLine.OrderReleaseKey,
                                    Quantity: custCompWarranty.ShipmentLine.Quantity,
                                    ShipmentLineKey: custCompWarranty.ShipmentLine.ShipmentLineKey,

                                }
                                custCompScan.WarrantyComponents = { ShipmentLine: [] }
                                custCompScan.WarrantyComponents.ShipmentLine.push(custCompWarrantyScan)
                                warrantyScan.push(custCompWarrantyScan)
                            }
                        }
                        scanItem.CustomComponents.ShipmentLine.push(custCompScan);

                    }
                    if (warrantyScan.length > 0) {
                        if (!(scanItem.WarrantyComponents && scanItem.WarrantyComponents.ShipmentLine)) {
                            scanItem.WarrantyComponents = { ShipmentLine: [] } as any
                        }
                        scanItem.WarrantyComponents.ShipmentLine = warrantyScan;
                    }
                }
            }

            payLoad.Shipment.ShipmentLines.ShipmentLine.push(scanItem);
        }

        try {
            let response = await ApiService.post(`/oms/GCUnholdShipment`, payLoad);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }


    }


    public static GCPickResourceView = async (orderDetail: OmsPickOrderDetail, shipmentKey: any, distance: Number, zipCode: string) => {
        let ShipmentLine: ShipmentLine = orderDetail?.ShipmentLines?.ShipmentLine?.filter((shipmentLine: ShipmentLine) => {
            return shipmentLine.ShipmentLineKey == shipmentKey
        })[0];
        let EnterpriseCode = orderDetail.EnterpriseCode;
        let OrderHeaderKey = orderDetail.OrderHeaderKey;
        let ItemID = ShipmentLine.OrderLine.ItemDetails.ItemID;
        let KitCode = ShipmentLine.OrderLine.KitCode;
        let ProductClass = ShipmentLine.ProductClass;
        let UnitOfMeasure = ShipmentLine.UnitOfMeasure;
        let ExtnIsStoreClearance = ShipmentLine?.OrderLine?.Extn?.ExtnIsStoreClearance ? ShipmentLine.OrderLine.Extn.ExtnIsStoreClearance : "N";
        let RequiredQty = ShipmentLine?.Quantity;
        let Country = "US";
        let DistanceToConsider = distance;
        let DisplayLocalizedFieldInLocale = "en_US_EST";
        let IgnoreOrdering = "Y";
        let Mode = "01";
        let OrganizationCode = ShipmentLine.Order.SellerOrganizationCode;
        let PageNumber = "1"
        let PageSize = "50"
        let payload = {
            "AlternateStore": {
                "Order": {
                    "EnterpriseCode": EnterpriseCode,
                    "OrderHeaderKey": OrderHeaderKey
                },
                "OrderLines": {
                    "OrderLine": {
                        "Order": {
                            "EnterpriseCode": EnterpriseCode,
                            "OrderHeaderKey": OrderHeaderKey
                        },
                        "Item": {
                            "ItemID": ItemID,
                            "KitCode": KitCode,
                            "ProductClass": ProductClass,
                            "UnitOfMeasure": UnitOfMeasure
                        },
                        "Extn": {
                            "ExtnIsStoreClearance": ExtnIsStoreClearance
                        },
                        "RequiredQty": RequiredQty
                    }
                },
                "NodeSearch": {
                    "ShipToAddress": {
                        "Country": Country,
                        "ZipCode": zipCode
                    },
                    "DistanceToConsider": DistanceToConsider
                },
                "DisplayLocalizedFieldInLocale": DisplayLocalizedFieldInLocale,
                "IgnoreOrdering": IgnoreOrdering,
                "Mode": Mode,
                "OrganizationCode": OrganizationCode,
                "PageNumber": PageNumber,
                "PageSize": PageSize
            }
        }
        try {
            let response = await ApiService.post(`/oms/GCPickResourceView`, payload);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }
    public static GCPickResourceLine = async (input: IResourceItemInput) => {
        let payload: any = {};
        if (!Utils.partialResource() || input.availableQty == input.resourceQty) {
            payload = this.getResourceLinePayload(input);
        }
        else {
            payload = this.getPartialResourcePayload(input);
        }

        //On Hold
        if (input.orderDetail?.Status == ShipmentStatusConstants.OnHold) {
            payload.Shipment.IsActionForPickHoldStatus = "Y";
        }
        console.log(payload)
        try {
            let response = await ApiService.post(`/oms/GCPickResourceLine`, payload);
            return response;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    private static getResourceLinePayload = (input: IResourceItemInput) => {
        let EnterpriseCode = input.orderDetail.EnterpriseCode
        let ShipmentKey = input.orderDetail.ShipmentKey
        let ShipmentLine: ShipmentLine = input.orderDetail?.ShipmentLines?.ShipmentLine?.filter((shipmentLine: ShipmentLine) => {
            return shipmentLine.ShipmentLineKey == input.selectedShipmentLineKey
        })[0];
        let CustomComponentShipmentLines = [] as any;
        let WarrantyShipmentLine = [] as any;
        let KitCode = ShipmentLine.OrderLine.KitCode;
        let ProductClass = ShipmentLine.ProductClass;
        let OrderHeaderKey = input.orderDetail.OrderHeaderKey;
        if (ShipmentLine.CustomComponents != undefined && ShipmentLine.CustomComponents.ShipmentLine.length > 0) {
            ShipmentLine.CustomComponents.ShipmentLine.forEach((cShipmentLine: ShipmentLine) => {
                var CustomComponent = {
                    "OrderLineKey": cShipmentLine.OrderLineKey,
                    "ParentShipmentLineKey": input.selectedShipmentLineKey,
                    "Quantity": Number(cShipmentLine.Quantity),
                    "ShipmentLineKey": cShipmentLine.ShipmentLineKey,
                    "ResourceShipNode": input.resourceShipmentNode,
                    "Availability": Number(input.availability),
                    "RequestShipDate": input.firstAvailableDate,
                    "OrderLine": {
                        "OrderLineKey": cShipmentLine.OrderLineKey,
                        "FulfillmentType": input.orderDetail?.FulfillmentType
                    },
                }
                CustomComponentShipmentLines.push(CustomComponent);
                if (cShipmentLine.WarrantyComponents != undefined) {
                    let warrentyComponent = {
                        "OrderLineKey": cShipmentLine.WarrantyComponents.ShipmentLine.OrderLineKey,
                        "Quantity": Number(cShipmentLine.WarrantyComponents.ShipmentLine.Quantity),
                        "ShipmentLineKey": cShipmentLine.WarrantyComponents.ShipmentLine.ShipmentLineKey,
                        "ResourceShipNode": input.resourceShipmentNode,
                        "RequestShipDate": input.firstAvailableDate,
                        "OrderLine": {
                            "OrderLineKey": cShipmentLine.WarrantyComponents.ShipmentLine.OrderLineKey,
                            "FulfillmentType": input.orderDetail?.FulfillmentType
                        },
                    }
                    WarrantyShipmentLine.push(warrentyComponent)
                }
            });
        }
        //Warranty on root level
        if (ShipmentLine?.WarrantyComponents) {
            if (Array.isArray(ShipmentLine.WarrantyComponents.ShipmentLine)) {
                ShipmentLine.WarrantyComponents.ShipmentLine.forEach((warrantyLine: any) => {
                    let warrentyComponent = {
                        "OrderLineKey": warrantyLine.OrderLineKey,
                        "Quantity": Number(warrantyLine.Quantity),
                        "ShipmentLineKey": warrantyLine.ShipmentLineKey,
                        "ResourceShipNode": input.resourceShipmentNode,
                        "RequestShipDate": input.firstAvailableDate,
                        "OrderLine": {
                            "OrderLineKey": warrantyLine.OrderLineKey,
                            "FulfillmentType": input.orderDetail?.FulfillmentType
                        },
                    }
                    WarrantyShipmentLine.push(warrentyComponent)
                })
            }
            else {
                let warrentyComponent = {
                    "OrderLineKey": ShipmentLine.WarrantyComponents.ShipmentLine.OrderLineKey,
                    "Quantity": Number(ShipmentLine.WarrantyComponents.ShipmentLine.Quantity),
                    "ShipmentLineKey": ShipmentLine.WarrantyComponents.ShipmentLine.ShipmentLineKey,
                    "ResourceShipNode": input.resourceShipmentNode,
                    "RequestShipDate": input.firstAvailableDate,
                    "OrderLine": {
                        "OrderLineKey": ShipmentLine.WarrantyComponents.ShipmentLine.OrderLineKey,
                        "FulfillmentType": input.orderDetail?.FulfillmentType
                    },
                }
                WarrantyShipmentLine.push(warrentyComponent)
            }

        }
        let payload: any = {
            "Shipment": {
                "ShipmentLines": {
                    "ShipmentLine": [
                        {
                            "CustomComponents": {
                                "ShipmentLine": CustomComponentShipmentLines
                            },
                            "WarrantyComponents": {
                                "ShipmentLine": WarrantyShipmentLine
                            },
                            "OrderLine": {
                                "OrderLineKey": ShipmentLine.OrderLineKey,
                                "FulfillmentType": input.orderDetail?.FulfillmentType
                            },
                            "KitCode": KitCode,
                            "OrderHeaderKey": OrderHeaderKey,
                            "OrderLineKey": ShipmentLine.OrderLineKey,
                            "ProductClass": ProductClass,
                            "Quantity": Number(ShipmentLine.Quantity),
                            "ReasonCode": input.reasonCode,
                            "ReasonText": input.reasonText,
                            "ShipmentLineKey": input.selectedShipmentLineKey,
                            "ResourceShipNode": input.resourceShipmentNode,
                            "RequestShipDate": input.firstAvailableDate,
                        }
                    ]
                },
                "Action": "resourceLine",
                "EnterpriseCode": EnterpriseCode,
                "IsManagerApproval": "Y",
                "ReasonCode": input.reasonCode,
                "ReasonText": input.reasonText,
                "ShipNode": input.shipNode,
                "ShipmentKey": ShipmentKey,
                "UserID": OMSManager.GetUserIdFromLocalStorage(),
                "EmployeeID": OMSManager.GetEmployeeId(),
                "ForceSourcing": input.forceSourcing
            }
        }
        return payload;
    }

    private static getPartialResourcePayload = (input: IResourceItemInput) => {
        let EnterpriseCode = input.orderDetail.EnterpriseCode
        let ShipmentKey = input.orderDetail.ShipmentKey
        let ShipmentLine: ShipmentLine = input.orderDetail?.ShipmentLines?.ShipmentLine?.filter((shipmentLine: ShipmentLine) => {
            return shipmentLine.ShipmentLineKey == input.selectedShipmentLineKey
        })[0];
        let CustomComponentShipmentLines = [] as any;
        let WarrantyShipmentLine = [] as any;
        let KitCode = ShipmentLine.OrderLine.KitCode;
        let ProductClass = ShipmentLine.ProductClass;
        let OrderHeaderKey = input.orderDetail.OrderHeaderKey;
        if (ShipmentLine.CustomComponents != undefined && ShipmentLine.CustomComponents.ShipmentLine.length > 0) {
            ShipmentLine.CustomComponents.ShipmentLine.forEach((cShipmentLine: ShipmentLine) => {
                var CustomComponent = {
                    "OrderLineKey": cShipmentLine.OrderLineKey,
                    "ParentShipmentLineKey": input.selectedShipmentLineKey,
                    "ShipmentLineKey": cShipmentLine.ShipmentLineKey,
                    // "ResourceShipNode": input.resourceShipmentNode,
                    // "Availability": Number(input.availability),
                    "RequestShipDate": input.firstAvailableDate,
                    "ItemID": cShipmentLine.ItemID,
                    "OrderLine": {
                        "OrderLineKey": cShipmentLine.OrderLineKey,
                        "FulfillmentType": input.orderDetail?.FulfillmentType
                    },
                }
                CustomComponentShipmentLines.push(CustomComponent);
                if (cShipmentLine.WarrantyComponents != undefined) {
                    const warrantyQuantity = this.checkWarrantyQuantity(input.availableQty, input.resourceQty, Number(cShipmentLine?.WarrantyComponents?.ShipmentLine?.Quantity));
                    if (warrantyQuantity > 0) {
                        let warrentyComponent = {
                            "OrderLineKey": cShipmentLine.WarrantyComponents.ShipmentLine.OrderLineKey,
                            "Quantity": warrantyQuantity,
                            "TotalQuantity": Number(cShipmentLine?.WarrantyComponents?.ShipmentLine?.Quantity),
                            "ShipmentLineKey": cShipmentLine.WarrantyComponents.ShipmentLine.ShipmentLineKey,
                            //"ResourceShipNode": input.resourceShipmentNode,
                            //"RequestShipDate": input.firstAvailableDate,
                            "OrderLine": {
                                "OrderLineKey": cShipmentLine.WarrantyComponents.ShipmentLine.OrderLineKey,
                                "FulfillmentType": input.orderDetail?.FulfillmentType
                            },
                        }
                        WarrantyShipmentLine.push(warrentyComponent)
                    }
                }
            });
        }

        //Warranty on root level
        if (ShipmentLine?.WarrantyComponents) {
            if (Array.isArray(ShipmentLine.WarrantyComponents.ShipmentLine)) {
                ShipmentLine.WarrantyComponents.ShipmentLine.forEach((warrantyLine: any) => {
                    const warrantyQuantity = this.checkWarrantyQuantity(input.availableQty, input.resourceQty, Number(warrantyLine?.Quantity));
                    if (warrantyQuantity > 0) {
                        let warrentyComponent = {
                            "OrderLineKey": warrantyLine.OrderLineKey,
                            "Quantity": warrantyQuantity,
                            "TotalQuantity": Number(warrantyLine?.Quantity),
                            "ShipmentLineKey": warrantyLine.ShipmentLineKey,
                            //"ResourceShipNode": input.resourceShipmentNode,
                            //"RequestShipDate": input.firstAvailableDate,
                            "OrderLine": {
                                "OrderLineKey": warrantyLine.OrderLineKey,
                                "FulfillmentType": input.orderDetail?.FulfillmentType
                            },
                        }
                        WarrantyShipmentLine.push(warrentyComponent)
                    }
                })
            }
            else {
                const warrantyQuantity = this.checkWarrantyQuantity(input.availableQty, input.resourceQty, Number(ShipmentLine?.WarrantyComponents?.ShipmentLine?.Quantity));
                if (warrantyQuantity > 0) {

                    let warrentyComponent = {
                        "OrderLineKey": ShipmentLine.WarrantyComponents.ShipmentLine.OrderLineKey,
                        "Quantity": warrantyQuantity,
                        "TotalQuantity": Number(ShipmentLine?.WarrantyComponents?.ShipmentLine?.Quantity),
                        "ShipmentLineKey": ShipmentLine.WarrantyComponents.ShipmentLine.ShipmentLineKey,
                        //"ResourceShipNode": input.resourceShipmentNode,
                        //"RequestShipDate": input.firstAvailableDate,
                        "OrderLine": {
                            "OrderLineKey": ShipmentLine.WarrantyComponents.ShipmentLine.OrderLineKey,
                            "FulfillmentType": input.orderDetail?.FulfillmentType
                        },
                    }
                    WarrantyShipmentLine.push(warrentyComponent);
                }
            }

        }

        let payload: any = {
            "Shipment": {
                "ShipmentLines": {
                    "ShipmentLine": [
                        {
                            "CustomComponents": {
                                "ShipmentLine": CustomComponentShipmentLines
                            },
                            "WarrantyComponents": {
                                "ShipmentLine": WarrantyShipmentLine
                            },
                            "OrderLine": {
                                "OrderLineKey": ShipmentLine.OrderLineKey,
                                "FulfillmentType": input.orderDetail?.FulfillmentType
                            },
                            "KitCode": KitCode,
                            "ItemID": ShipmentLine.ItemID,
                            "OrderHeaderKey": OrderHeaderKey,
                            "OrderLineKey": ShipmentLine.OrderLineKey,
                            "ProductClass": ProductClass,
                            "Quantity": input.resourceQty,
                            "TotalQuantity": input.availableQty,
                            "ReasonCode": input.reasonCode,
                            "ReasonText": input.reasonText,
                            "ShipmentLineKey": input.selectedShipmentLineKey,
                            //"ResourceShipNode": input.resourceShipmentNode,
                            //"RequestShipDate": input.firstAvailableDate,
                        }
                    ]
                },
                "Action": Constants.ResourceActions.PartialResourceLine,
                "EnterpriseCode": EnterpriseCode,
                "IsManagerApproval": "Y",
                "ReasonCode": input.reasonCode,
                "ReasonText": input.reasonText,
                "ShipNode": input.shipNode,
                "ShipmentKey": ShipmentKey,
                "UserID": OMSManager.GetUserIdFromLocalStorage(),
                "EmployeeID": OMSManager.GetEmployeeId(),
                //"ForceSourcing": input.forceSourcing
            }
        }
        return payload;
    }

    private static checkWarrantyQuantity = (totalQty: number, resourceQty: number, warrantyQty: number) => {
        if (totalQty == warrantyQty) {
            return resourceQty;
        }
        else {
            let quantityDiff = totalQty - warrantyQty
            //Warranty shouldn't be send in this case.
            if (quantityDiff >= resourceQty) {
                return -1;
            }
            else {
                //To ensure warranty qty will be the same as per the available qty after partial resourcing
                return (warrantyQty - (totalQty - resourceQty));
            }
        }
    }

    public static GCPackUpdateTrackingNo = async (ShipmentKey: string, ShipmentLineKey: string[], TrackingNo: string[], unpackagedItems: OmsPackOrderDetail, itemQty: any, totalPrice: string[], totalPackagesWeight: string[], packageDimension: string[], shippingService: string[], shippingCarrier: string[], currentStatus?:string, ) => {
        let ContainerDetails = [] as any;
        ShipmentLineKey.forEach((LineKey: string) => {
            let selectedItemQuantityToPack = itemQty.filter((qty: any) => { return qty.id == LineKey })[0].toPack
            let selectedItemDetail: ShipmentLine2 = unpackagedItems.ShipmentLines.ShipmentLine.filter((item: any) => { return item.ShipmentLineKey == LineKey })[0];
            let ContainerDetail = {
                "OrderLineKey": selectedItemDetail.OrderLineKey,
                "KitCode": selectedItemDetail.OrderLine.KitCode,
                "Quantity": Number(selectedItemQuantityToPack),
                "ShipmentLineKey": LineKey
            }
            ContainerDetails.push(ContainerDetail)
        });

        let payload = {
            "Shipment": {
                "ShipmentKey": ShipmentKey,
                "Containers": {
                    "Container": {
                        "Extn": {
                            "ExtnShippingService": shippingService.toString(),
                            "ExtnCarrier": shippingCarrier.toString()
                        },
                        "TrackingNo": TrackingNo.toString(),
                        "ContainerGrossWeight": totalPackagesWeight.toString(),
                        "ActualFreightCharge": totalPrice.toString(),
                        "Dimension": packageDimension.toString(),
                        "ContainerDetails": {
                            "ContainerDetail": ContainerDetails
                        }
                    }
                }
            }
        }
        try {
            let response = await ApiService.post("/oms/GCPackUpdateTrackingNo", payload ,ShipmentKey ,currentStatus)
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }
        //return { success: false, msg: "", data: { errors: [{ ErrorDescription: "" }] } }
    }



    public static GCPackDeleteTrackingNo = async (ShipmentKey: any, ShipmentContainerKey: any, packagedItems: Container[]) => {
        let ExtnMultiBoxGroupID = ""
        let containerDetail = packagedItems.filter((container) => {
            return container.ShipmentContainerKey == ShipmentContainerKey;
        })[0]
        if (containerDetail) {
            if (containerDetail.Extn.ExtnMultiBoxGroupID != "") {
                ExtnMultiBoxGroupID = containerDetail.Extn.ExtnMultiBoxGroupID;
            }
        }
        let payload = {
            "Shipment": {
                "ShipNode": "",
                "ShipmentKey": ShipmentKey,
                "Containers": {
                    "Container": {
                        "ShipmentContainerKey": ShipmentContainerKey,
                        "Extn": {
                            "ExtnMultiBoxGroupID": ExtnMultiBoxGroupID,
                            "ExtnMultiBoxDesc": ""
                        }
                    }
                }
            }
        }
        try {
            let response = await ApiService.post("/oms/GCPackDeleteTrackingNo", payload)
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    public static GCPackShipConfirm = async (shipmentKey: any, fulfillmentType: any, OrderHeaderKey: any, currentStatus?:any) => {

        //
        let payload = {
            "Shipment": {
                "UserIdDomain": "",
                "ShipmentKey": shipmentKey,
                "FulfillmentType": fulfillmentType,
                "OrderHeaderKey": OrderHeaderKey,
                "UserID": OMSManager.GetUserIdFromLocalStorage(),
                "EmployeeID": OMSManager.GetEmployeeId(),
                "IsManagerApproval": "N"
            }
        }
        try {
            let response = await ApiService.post("/oms/GCPackShipConfirm", payload,shipmentKey,currentStatus);
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    public static GCPackCancelLine = async (payLoad: any) => {

        try {
            let response = await ApiService.post("/oms/GCPackCancelLine", payLoad)
            return response
        }
        catch (error) {
            console.log(error);
        }

    }
    public static GCPickCancelLine = async (payLoad: any) => {

        try {
            let response = await ApiService.post("/oms/GCPickCancelLine", payLoad)
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }
    public static GCPickCancelLineNom = async (payLoad: any) => {

        try {
            let response = await ApiService.post(ApiURLS.NOM.getPickCancelLineNom.URL, payLoad)
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    public static GCCustomerPickedUpConfirm = async (payLoad: any) => {

        try {
            let response = await ApiService.post("/oms/GCCustomerPickedUpConfirm", payLoad)
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    // public static getCustomerDetailsOMS = (data: any) => {

    //     let initialCustomerDetail: ICustomerDetail = {
    //         name: "",
    //         city: "",
    //         email: "",
    //         phone: "",
    //         zipCode: "",
    //         address: "",
    //         state: ""
    //     }
    //     if (data.AddressLine1 != undefined) {
    //         try {
    //             if (data.hasOwnProperty("FirstName")) {
    //                 initialCustomerDetail.name = data.FirstName
    //             }
    //             if (data.hasOwnProperty("LastName")) {
    //                 initialCustomerDetail.name = initialCustomerDetail.name + " " + data.LastName
    //             }
    //             if (data.hasOwnProperty("City")) {
    //                 initialCustomerDetail.city = data.City
    //             }
    //             if (data.hasOwnProperty("CustomerEMailID")) {
    //                 initialCustomerDetail.email = data.CustomerEMailID
    //             }
    //             if (data.hasOwnProperty("DayPhone")) {
    //                 initialCustomerDetail.phone = data.DayPhone
    //             }
    //             if (initialCustomerDetail.phone == "" && data.hasOwnProperty("MobilePhone")) {
    //                 initialCustomerDetail.phone = data.MobilePhone
    //             }
    //             if (data.hasOwnProperty("ZipCode")) {
    //                 initialCustomerDetail.zipCode = data.ZipCode
    //             }
    //             if (initialCustomerDetail.zipCode == "" && data.hasOwnProperty("ShortZipCode")) {
    //                 initialCustomerDetail.zipCode = data.ShortZipCode
    //             }
    //             if (data.hasOwnProperty("AddressLine1")) {
    //                 initialCustomerDetail.address = data.AddressLine1
    //             }
    //             if (data.hasOwnProperty("AddressLine2") && data.AddressLine2 != "") {
    //                 initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.AddressLine2
    //             }
    //             if (data.hasOwnProperty("AddressLine3") && data.AddressLine3 != "") {
    //                 initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.AddressLine3
    //             }

    //             if (data.hasOwnProperty("State")) {
    //                 initialCustomerDetail.state = data.State
    //             }
    //         }
    //         catch {
    //             console.log("Error Getting Customer Detail")
    //         }

    //     }
    //     return initialCustomerDetail
    // }

    public static getCustomerDetails = (data: any) => {

        let initialCustomerDetail: ICustomerDetail = {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            state: ""
        }
        if (data.ToAddress != undefined) {
            try {
                if (data.ToAddress.hasOwnProperty("FirstName")) {
                    initialCustomerDetail.name = data.ToAddress.FirstName
                }
                if (data.ToAddress.hasOwnProperty("LastName")) {
                    initialCustomerDetail.name = initialCustomerDetail.name + " " + data.ToAddress.LastName
                }
                if (data.ToAddress.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.ToAddress.City
                }
                if (data.ToAddress.hasOwnProperty("EMailID")) {
                    initialCustomerDetail.email = data.ToAddress.EMailID
                }
                if (data.ToAddress.hasOwnProperty("DayPhone")) {
                    initialCustomerDetail.phone = data.ToAddress.DayPhone
                }
                if (initialCustomerDetail.phone == "" && data.ToAddress.hasOwnProperty("MobilePhone")) {
                    initialCustomerDetail.phone = data.ToAddress.MobilePhone
                }
                if (data.ToAddress.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.ToAddress.ZipCode
                }
                if (initialCustomerDetail.zipCode == "" && data.ToAddress.hasOwnProperty("ShortZipCode")) {
                    initialCustomerDetail.zipCode = data.ToAddress.ShortZipCode
                }
                if (data.ToAddress.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.ToAddress.AddressLine1
                }
                if (data.ToAddress.hasOwnProperty("AddressLine2") && data.ToAddress.AddressLine2 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.ToAddress.AddressLine2
                }
                if (data.ToAddress.hasOwnProperty("AddressLine3") && data.ToAddress.AddressLine3 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.ToAddress.AddressLine3
                }
                if (data.ToAddress.hasOwnProperty("AddressLine4") && data.ToAddress.AddressLine4 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.ToAddress.AddressLine4
                }
                if (data.ToAddress.hasOwnProperty("AddressLine5") && data.ToAddress.AddressLine5 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.ToAddress.AddressLine5
                }
                if (data.ToAddress.hasOwnProperty("AddressLine6") && data.ToAddress.AddressLine6 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.ToAddress.AddressLine6
                }
                if (data.ToAddress.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.ToAddress.State
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        if (data.AddressLine1 != undefined) {
            try {
                if (data.hasOwnProperty("FirstName")) {
                    initialCustomerDetail.name = data.FirstName
                }
                if (data.hasOwnProperty("LastName")) {
                    initialCustomerDetail.name = initialCustomerDetail.name + " " + data.LastName
                }
                if (data.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.City
                }
                if (data.hasOwnProperty("CustomerEMailID")) {
                    initialCustomerDetail.email = data.CustomerEMailID
                }
                if (data.hasOwnProperty("DayPhone")) {
                    initialCustomerDetail.phone = data.DayPhone
                }
                if (initialCustomerDetail.phone == "" && data.hasOwnProperty("MobilePhone")) {
                    initialCustomerDetail.phone = data.MobilePhone
                }
                if (data.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.ZipCode
                }
                if (initialCustomerDetail.zipCode == "" && data.hasOwnProperty("ShortZipCode")) {
                    initialCustomerDetail.zipCode = data.ShortZipCode
                }
                if (data.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.AddressLine1
                }
                if (data.hasOwnProperty("AddressLine2") && data.AddressLine2 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.AddressLine2
                }
                if (data.hasOwnProperty("AddressLine3") && data.AddressLine3 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.AddressLine3
                }

                if (data.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.State
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        return initialCustomerDetail
    }

    public static GetCancelPayload = (orderDetail: any, cancelReasonObject: any, code: any, text: any) => {


        let payLoad = {
            "Shipment": {
                "ShipmentKey": orderDetail.ShipmentKey,
                "IsManagerApproval": "N",
                "Action": cancelReasonObject.cancelCompleteOrder ||
                    orderDetail.ShipmentLines && orderDetail.ShipmentLines.ShipmentLine && orderDetail.ShipmentLines.ShipmentLine.length == 1
                    ? "cancelAll" :
                    "cancelLine",
                "EnterpriseCode": orderDetail.EnterpriseCode,
                "ShipNode": orderDetail.ShipNode.ShipNode,
                "ReasonCode": code,
                "ReasonText": text,
                "UserID": OMSManager.GetUserIdFromLocalStorage(),
                "EmployeeID": OMSManager.GetEmployeeId(),
                "ShipmentLines": {
                    "ShipmentLine": []
                }
            }
        } as any;
        if (orderDetail.Status == ShipmentStatusConstants.OnHold) {
            payLoad.Shipment.IsActionForPickHoldStatus = "Y";
        }

        let shipLines: any[] = [];

        if (cancelReasonObject.cancelItem && !cancelReasonObject.cancelCompleteOrder) {
            shipLines = orderDetail.ShipmentLines.ShipmentLine.filter((a: any) => {
                return a.ShipmentLineKey == cancelReasonObject.cancelItem.ShipmentLineKey
            })
        }
        else {
            shipLines = orderDetail.ShipmentLines.ShipmentLine;
        }

        if (shipLines && shipLines.length > 0) {


            payLoad.Shipment.ShipmentLines.ShipmentLine = [];
            for (let i = 0; i < shipLines.length; i++) {
                let line = {

                    OrderHeaderKey: shipLines[i].OrderHeaderKey,
                    OrderLineKey: shipLines[i].OrderLineKey,
                    KitCode: shipLines[i].KitCode,
                    ProductClass: shipLines[i].ProductClass,
                    Quantity: shipLines[i].Quantity,
                    ReasonCode: code,
                    ReasonText: text,
                    ShipmentLineKey: shipLines[i].ShipmentLineKey,


                } as any;

                if (shipLines[i].hasOwnProperty("CustomComponents")) {

                    line["CustomComponents"] = {};
                    line["CustomComponents"]["ShipmentLine"] = [];

                    for (let c = 0; c < shipLines[i].CustomComponents.ShipmentLine.length; c++) {
                        let curComp = shipLines[i].CustomComponents.ShipmentLine[c];

                        let copm = {
                            ParentShipmentLineKey: curComp.ParentShipmentLineKey,
                            OrderLineKey: curComp.OrderLineKey,
                            Quantity: curComp.Quantity,
                            ShipmentLineKey: curComp.ShipmentLineKey
                        } as any;

                        line.CustomComponents.ShipmentLine.push(copm);

                        if (curComp.hasOwnProperty("WarrantyComponents")) {
                            let warrantyInput = curComp.WarrantyComponents.ShipmentLine;

                            line["WarrantyComponents"] = {};
                            line["WarrantyComponents"]["ShipmentLine"] = [];
                            line.WarrantyComponents.ShipmentLine.push({

                                OrderLineKey: warrantyInput.OrderLineKey,
                                Quantity: warrantyInput.Quantity,
                                ShipmentLineKey: warrantyInput.ShipmentLineKey

                            });
                        }

                    }

                }
                payLoad.Shipment.ShipmentLines.ShipmentLine.push(line);
            }
        }


        return payLoad;

    }

    public static GCGetUserLoginID = async (errorCallBack: any, iEmployeeId?: any) => {


        // get the employeeId from user userprofile
        //
        if (OMSManager.GetUserIdFromLocalStorage().length > 0) {
            return;
        }

        let localStorageEmpId = IdentityManager.getUserProperty(Constants.EmployeeID);
        let empId = iEmployeeId && iEmployeeId.length > 0 ? iEmployeeId : localStorageEmpId;
        let payLoad = {
            "User": {
                "EmployeeID": (Utils.isExternalRoute() || (IdentityManager.isAdmin && (!empId || empId.length == 0))) ? StoreManager.config?.defaultEmployeeId : empId
            }
        }
        try {
            let url = Utils.isExternalRoute() ? ApiURLS.NOM.GCGetUserLoginID.URL : "/oms/GCGetUserLoginID"
            let response: any = await ApiService.post(url, payLoad);
            if (response.success && response.data.Loginid) {
                if (localStorage) {

                    localStorage.setItem(Constants.UserId, response.data.Loginid);
                    if ((iEmployeeId && iEmployeeId.length > 0) || (!localStorageEmpId || localStorageEmpId.length == 0) || Utils.isExternalRoute()) {
                        localStorage.setItem(Constants.UseDefaultEmployeeId, "true");
                        OMSManager.GetEmployeeId();
                    }
                }
            }
            else {
                if ((response.msg && response.msg.toLowerCase().indexOf("user not found") > -1) && IdentityManager.isAdmin) {
                    await OMSManager.GCGetUserLoginID(errorCallBack, StoreManager.config?.defaultEmployeeId);
                }
                else {
                    if (errorCallBack)
                        errorCallBack();
                }
            }
            return response;

        }
        catch (error) {
            if (errorCallBack)
                errorCallBack();
            console.log(error);
            throw error;
        }

    }

    // In case of External User/Route or Admin with Empty EMployeeId return the default EmployeeId as being used
    // FOr the cases like if Admins has an employee id but its not valid then use the Default EmployeeId in all oms api's
    public static GetEmployeeId = () => {
        let empId = IdentityManager.getUserProperty(Constants.EmployeeID) as any;
        let useDefaultEmployeeValue = null;
        let IsUserDefaultEmployeeId = false;
        useDefaultEmployeeValue = localStorage.getItem(Constants.UseDefaultEmployeeId);
        if (localStorage && useDefaultEmployeeValue) {
            IsUserDefaultEmployeeId = useDefaultEmployeeValue && useDefaultEmployeeValue.length > 0 ? true : false;
        }
        if ((!empId || empId.length == 0) || IsUserDefaultEmployeeId || Utils.isExternalRoute()) {
            empId = StoreManager.config?.defaultEmployeeId;
        }
        return empId;

    }

    public static GetUserIdFromLocalStorage = () => {

        let userId: any = "";
        if (localStorage) {
            userId = localStorage.getItem(Constants.UserId);
        }

        return userId == null ? "" : userId;

    }

    public static GetUserIdDoamin = () => {

        let userdomain: any = { domain: "", user: "" };

        if (localStorage) {
            let userId = localStorage.getItem(Constants.UserId);
            if (userId && userId.length > 0) {
                let splitted = userId.split("@");
                if (splitted.length > 0) {
                    userdomain.domain = splitted[0];
                }
                if (splitted.length > 1) {
                    userdomain.user = splitted[1];
                }

            }
        }

        return userdomain;

    }

    public static RemoveUserIdFromLocalStorage = () => {

        if (localStorage) {
            localStorage.removeItem(Constants.UserId);
        }

    }

    public static GCGetEComCatalog = async () => {

        try {
            let response = await ApiService.post("/oms/GCGetEComCatalog", {
                "Category": {}
            })
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    public static GCShipToDCDetailView = async (shipmentKey: any, store: any) => {

        try {
            let response = await ApiService.post("/oms/GCShipToDCDetailView", {
                "Shipment":
                {
                    "ShipmentKey": shipmentKey,
                    "ShipNode": store
                }
            })
            return response
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    public static GCShipToDCUpdateTrackingNo = async (ShipmentKey: string, ShipmentLineKey: any[], TrackingNo: string[], orderLineKey: any[], qty: any[]) => {


        //
        let payLoad: any = {
            "Shipment": {
                "ShipmentKey": ShipmentKey,
                "Containers": {
                    "Container": {
                        "TrackingNo": TrackingNo && TrackingNo.length > 0 ? TrackingNo.join(",") : "",
                        "ContainerDetails": {
                            "ContainerDetail": [

                            ]
                        }
                    }
                }
            }
        }


        for (let i = 0; i < ShipmentLineKey.length; i++) {
            let cDetail: any = {
                "OrderLineKey": orderLineKey[i],
                "KitCode": "",
                "Quantity": qty[i],
                "ShipmentLineKey": ShipmentLineKey[i]
            };
            payLoad.Shipment.Containers.Container.ContainerDetails.ContainerDetail.push(cDetail);
        }

        try {
            return await ApiService.post("/oms/GCShipToDCUpdateTrackingNo", payLoad);
        }
        catch (error) {
            console.log(error);
            throw error;
        }

    }

    public static GCShipToDCDeleteTrackingNo = async (store: any, ShipmentKey: any, ShipmentContainerKey: any, ExtnMultiBoxGroupID: any) => {
        // debugger
        if (ExtnMultiBoxGroupID == undefined || ExtnMultiBoxGroupID == null) {
            ExtnMultiBoxGroupID = ""
        }
        let payLoad = {
            "Shipment": {
                "ShipNode": store,
                "ShipmentKey": ShipmentKey,
                "Containers": {
                    "Container": {
                        "ShipmentContainerKey": ShipmentContainerKey,
                        "Extn": {
                            "ExtnMultiBoxGroupID": ExtnMultiBoxGroupID,
                            "ExtnMultiBoxDesc": ""
                        }
                    }
                }
            }
        }

        try {
            return await ApiService.post("/oms/GCShipToDCDeleteTrackingNo", payLoad);
        }
        catch (error) {
            console.log(error);
            throw error;
        }


    };

    public static GCShipToDCShipConfirm = async (ShipmentKey: any, UserID: any, FulfillmentType: any) => {

        let payLoad = {
            "Shipment": {
                "UserIdDomain": "",
                "ShipmentKey": ShipmentKey,
                "FulfillmentType": FulfillmentType,
                "OrderHeaderKey": "",
                "UserID": UserID,
                "IsManagerApproval": "N"
            }
        }

        try {
            return await ApiService.post("/oms/GCShipToDCShipConfirm", payLoad);
        }
        catch (error) {
            console.log(error);
            throw error;
        }


    }

    public static GCShipToDCMarkInv = async (ShipmentKey: any, ShipmentLineKey: any, qty: any) => {

        const user = OMSManager.GetUserIdDoamin();
        let payLoad = {
            "Shipment": {
                "ShipmentKey": ShipmentKey,
                "OrderHeaderKey": "",
                "ReasonCode": "",
                "UserID": OMSManager.GetUserIdFromLocalStorage(),
                "IsManagerApproval": "Y",
                "UserIdDomain": user.domain,
                "ShipmentLines": {
                    "ShipmentLine": {
                        "ShipmentLineKey": ShipmentLineKey,
                        "Quantity": qty
                    }
                },
                "loggedInUserName": user.user,
                "IsCompleteCancel": "Y"
            }
        }

        try {
            return await ApiService.post("/oms/GCShipToDCMarkInv", payLoad);
        }
        catch (error) {
            console.log(error);
            throw error;
        }


    }

    public static GCCustomerPickSlipDetails = async (payLoad: any) => {


        try {
            return await ApiService.post("/oms/GCCustomerPickSlipDetails", payLoad);
        }
        catch (error) {
            console.log(error);
            throw null;
        }


    };

    public static omsGeneral = async (payLoad: any) => {

        try {
            return await ApiService.post("/oms/OMSGeneral", payLoad);
        }
        catch (error) {
            console.log(error);
            throw null;
        }

    };

    public static OMSGetPosInventory = async (payLoad: any) => {


        try {
            return await ApiService.post(`/oms/OMSGetPosInventory`, payLoad);
        }
        catch (error) {
            console.log(error);
            throw null;
        }

    };


}