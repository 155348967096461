import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Constants from "../../Common/Constants";
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Divider, Grid, Tooltip, Typography, TextField, Card, CardContent, Button, Chip, InputLabel, Box } from "@mui/material";
import { CancelIcon, DeleteOutlinedIcon, ArrowForwardIosSharpIcon, ExpandMoreIcon, CheckBoxOutlinedIcon } from "../Shared/SharedIcons"
import allActions from '../../Store/Actions/AllActions';
import IitemQty from '../../Models/IItemQty';
import OrderLineShimmer from "../PickOrder/OrderLineShimmer"
import { Container, OmsPackOrderDetail, ShipmentLine2 } from "../../Models/OmsPackOrderDetails";
import CancelLine from '../Shared/CancelLine';
import OMSManager from '../../Managers/Oms.manager';
import { IReasonState } from '../../Models/IReasons';
import Utils from '../../Common/Utils';
import OrderLineShimmerMobile from "../PickOrder/OrderLineShimmerMobile"
import OrderManager from '../../Managers/Orders.Manager';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Shared/ImageViewerPopup';
import ExtendedDescription from '../PickOrder/ExtendedDescription';
import { useNavigate } from 'react-router-dom';
var moment = require('moment');

export const UnPackagedItems: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // cancel line popup
    let cancelReason: IReasonState = useSelector((state: any) => state.ActionLoaderReducersState.cancelReason);

    //
    let unpackagedItemsList: OmsPackOrderDetail = useSelector((state: any) => state.PackOrderReducersState.unpackagedItems);
    let packagedItems: Container[] = useSelector((state: any) => state.PackOrderReducersState.packagedItems);
    let selectedItems = useSelector((state: any) => state.PackOrderReducersState.selectedItems);
    let itemQty: IitemQty[] = useSelector((state: any) => state.PackOrderReducersState.itemQty);
    let isResidential: boolean = useSelector((state: any) => state.PackOrderReducersState.isResidential);
    const [expandedUnPacked, setExpandedUnPacked] = React.useState(true);
    const [imagePopUp, setImagePopUp] = React.useState(false);
    const [imageData, setImageData] = React.useState({} as IImageViewerDetail);
    function getSumQtyAvailableToPack(total: number, item: any) {
        return total + Number(item.QtyAvailableToPack);
    }
    const getTotalQtyToPack = () => {
        if (props.loadingOrderLines == false && unpackagedItemsList.ShipmentLines.ShipmentLine.length > 0) {
            let total = unpackagedItemsList.ShipmentLines.ShipmentLine ? unpackagedItemsList.ShipmentLines.ShipmentLine.reduce(getSumQtyAvailableToPack, 0) : 0;
            return total
        }
        else {
            return 0
        }

    }

    const logCancelOrderHistory = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const shipmentkey = urlParams.get('shipmentkey');
        await OrderManager.saveOrderHistory({
            shipmentKey: shipmentkey,
            logType: "history",
            cancelAction: "Pack",
            transactionType: Constants.OrderHistoryTypes.Cancel_Order,
            transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
        });
    }

    const getCancelReasonValues = async (code: any, text: any) => {
        dispatch(allActions.actionLoaderActions.showLoader());
        console.log(code, text);
        let itemToCancel = { ...cancelReason.cancelItem };
        let payLoad = getCancelPayload(itemToCancel, code, text);
        //
        if (payLoad) {

            await cancelLineCall(payLoad).then((response: any) => {
                if (payLoad?.Shipment?.IsCompleteCancel == "Y" && response.success) {
                    logCancelOrderHistory();
                }
            })

            dispatch(allActions.actionLoaderActions.setCancelReason(
                Utils.initCanceReasonState()
            ))
            dispatch(allActions.actionLoaderActions.hideLoader());

            if(payLoad?.Shipment?.IsCompleteCancel == "Y" && packagedItems.length==0)
            {
                navigate("/FulfillOrders");
            }
            else if (props.getPackDetailFromOms) {
                props.getPackDetailFromOms(true);
            }

        }
    }

    const cancelLineCall = async (payLoad: any) => {
        return await OMSManager.GCPackCancelLine(payLoad);
    }
    const showCancelPopup = (item: any) => {

        dispatch(allActions.actionLoaderActions.setCancelReason(
            {
                ...cancelReason,
                showModal: true,
                cancelItem: item
            }
        ))
    }

    const getCancelPayload = (item: any, code: any, text: any) => {

        let obj: any = {
            "Shipment": {
                "ShipmentKey": unpackagedItemsList.ShipmentKey,
                "OrderHeaderKey": unpackagedItemsList.OrderHeaderKey,
                "ReasonCode": code,
                "ReasonText": text,
                "UserID": OMSManager.GetUserIdFromLocalStorage(),
                "EmployeeID": OMSManager.GetEmployeeId(),
                "IsManagerApproval": "Y",
                "UserIdDomain": OMSManager.GetUserIdDoamin().domain,
                "ShipmentLines": {
                    "ShipmentLine": {
                        "ShipmentLineKey": item?.ShipmentLineKey,
                        "Quantity": parseInt(item?.QtyAvailableToPack)
                    }
                },
                "loggedInUserName": OMSManager.GetUserIdDoamin().user,
            }
        }

        if (unpackagedItemsList.ShipmentLines?.ShipmentLine && unpackagedItemsList.ShipmentLines?.ShipmentLine.length == 1) {
            obj["Shipment"]["IsCompleteCancel"] = "Y"
        }

        return obj;
    }

    const renderExtendedDescription = (item: any) => {
        let extnDesc = "";
        if (item?.OrderLine?.ItemDetails?.Extn?.ExtnIsUsedOrVintageItem == "Y") {
            let description = item?.OrderLine?.ItemDetails?.PrimaryInformation?.ExtendedDescription;
            if (description?.toLowerCase()?.includes(Constants.MESSAGES.PRODUCT_CASE.toLowerCase())) {
                extnDesc = Constants.MESSAGES.PRODUCT_CASE;
            }
            else if (description?.toLowerCase()?.includes(Constants.MESSAGES.PRODUCT_BAG.toLowerCase())) {
                extnDesc = Constants.MESSAGES.PRODUCT_BAG;
            }
        }
        return (extnDesc != ""
            ? <Typography style={{ fontSize: Utils.isMobile ? "13px" : "15px", color: "#1976D2" }}><b>{extnDesc}</b></Typography>
            : <></>
        );
    }

    const handleChangeUnPacked = () => {
        setExpandedUnPacked(!expandedUnPacked);
    };
    const changeSelectedItem = (event: any, id: any) => {
        let checked = event.target.checked
        if (checked) {
            let newSelectedItems = [...selectedItems]
            newSelectedItems.push(id)
            dispatch(allActions.packOrderActions.changeSelectedItems(newSelectedItems));
            if (newSelectedItems.length > 1) {
                resetBoxSide()
            }
        }
        else {
            let newSelectedItems = selectedItems.filter((listId: any) => { return listId != id })
            dispatch(allActions.packOrderActions.changeSelectedItems(newSelectedItems));
            if (newSelectedItems.length > 1) {
                resetBoxSide()
            }
        }
    }
    const itemQuantityMultiBoxDisableCheck = () => {
        let disable = false
        itemQty.forEach((item: IitemQty) => {
            if (selectedItems.indexOf(item.id) > -1) {
                if (Number(item.toPack) > 1) {
                    disable = true
                }
            }
        })
        props.setdisableMultiBox(disable);
        if (disable) {
            resetBoxSide()
        }
    }
    const changeQuantity = (event: any, id: string) => {
        let newQuantity: IitemQty[] = [...itemQty]
        newQuantity.filter((qty) => { return qty.id.toString() == id.toString() })[0].toPack = event.target.value;
        dispatch(allActions.packOrderActions.changeItemQuantity(newQuantity));
    }
    const changeResidential = () => {
        let changeIsResidential = !isResidential
        dispatch(allActions.packOrderActions.changeResidential(changeIsResidential));
    }
    const changeShipDate = (shipDate: any) => {
        dispatch(allActions.packOrderActions.changeShipDate(shipDate));
    }
    const resetBoxSide = () => {
        const boxCharerisitic = JSON.parse(JSON.stringify(Constants.boxCharacteristic));
        dispatch(allActions.packOrderActions.initilizeBoxCharacteristicList(boxCharerisitic));
        if (isResidential == false) {
            changeResidential()
        }
        changeShipDate(new Date())
    }

    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }

    const imageViewer = (item: any) => {
        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ?? "-",
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.Quantity
        }

        setImagePopUp(true);
        setImageData(imageData);
    }

    React.useEffect(() => {
        itemQuantityMultiBoxDisableCheck()
        return () => {
        }
    }, [selectedItems, itemQty]);
    const webView = () => {
        return (
            // <Card className='dCard'>
            //     <CardContent className='dCardContent'>

            <div style={{ display: getTotalQtyToPack() == 0 && props.loadingOrderLines == false ? "none" : "block" }}>
                <Card className="dCard" style={{ margin: 16 }}>
                    <CardContent className="dCardContent">
                        <Typography className="card-heading">Un-Packaged Items</Typography>
                        {/* </div> */}
                        <Divider style={{border:"1px"}}/>
                        {props.loadingOrderLines == false ?
                            <div style={{ padding: "10px" }}>
                                {
                                    unpackagedItemsList.ShipmentLines.ShipmentLine.map((item: ShipmentLine2,index:any) => {

                                        let qtyOfItem: IitemQty = itemQty.filter((qty: any) => { return qty.id == item.ShipmentLineKey })[0]
                                        let qtyToPack = qtyOfItem.toPack
                                        return (

                                            <React.Fragment key={item.ShipmentLineKey}>

                                                {/* Item Header */}
                                                <div style={{ display: Number(item.QtyAvailableToPack) > 0 ? "block" : "none" }}>

                                                    <Grid container display={"flex"} style={{ marginTop: "5px" }} className="justifyBetween productHeader">
                                                        <div >
                                                            <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">
                                                                {Utils.disableUserAction(props.store) && <Checkbox
                                                                
                                                                    checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                                                                    onChange={(e: any) => changeSelectedItem(e, item.ShipmentLineKey)}
                                                                    checked={selectedItems.indexOf(item.ShipmentLineKey) > -1 ? true : false}
                                                                    style={{
                                                                        color: "#888888",
                                                                        maxHeight: "40px",
                                                                        marginTop: 18
                                                                    }} />}
                                                                <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                        QTY to Ship
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                        {Number(item.QtyAvailableToPack)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item display={"flex"} alignItems="center" style={{ marginTop: "8px" }}>
                                                                    <Typography sx={{ fontSize: 12, whiteSpace: 'nowrap' }} color="text.secondary" style={{ marginRight: '8px' }}>
                                                                        QTY to Pack
                                                                    </Typography>
                                                                    <TextField
                                                                        id="order"
                                                                        className="remove-buttons"
                                                                        onChange={(event: any) => changeQuantity(event, item.ShipmentLineKey)}
                                                                        value={Number(qtyToPack)}
                                                                        type={"number"}
                                                                        inputProps={{ style: { padding: "0px !important" } }}
                                                                        InputProps={{ inputProps: { min: 1, max: Number(item.QtyAvailableToPack) }, readOnly: false, style: { padding: "0px !important", background: "white" } }}
                                                                        style={{ width: "90%", height: "50%", padding: "4px 0px 0px 0px" }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div>
                                                            {Utils.disableUserAction(props.store) && <Grid item xs={1} sm={1} className='textCenter'>
                                                                <Tooltip title="Cancel Item">
                                                                    <IconButton onClick={() => { showCancelPopup(item) }}
                                                                        style={{ color: Constants.Colors.black }}
                                                                    >
                                                                        <DeleteOutlinedIcon></DeleteOutlinedIcon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>}
                                                        </div>
                                                    </Grid>
                                                </div>

                                                <Grid container spacing={1} display={"flex"} style={{ display: Number(item.QtyAvailableToPack) > 0 ? "block" : "none" }}>
                                                    <Grid item xs={12} sm={12} className='textLeft'>
                                                        <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                            <Grid item xs={3} sm={3} style={{ padding: "8px", display: "flex", justifyContent: "center" }}>
                                                                <div className="image-zoom" onClick={() => imageViewer(item)}>
                                                                    <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                                                </div>
                                                            </Grid>

                                                            {/* Chip */}
                                                            <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                {
                                                                    item.KitCode != undefined && item.KitCode != "" ?
                                                                        <Chip
                                                                            className="chipTracking"
                                                                            label={item.KitCode}
                                                                            style={{
                                                                                background: "#A1D899",
                                                                                color: "#006100",
                                                                                fontSize: "12px",
                                                                                fontWeight: 700,
                                                                                height: "20px",
                                                                                borderRadius: "4px"
                                                                            }}
                                                                        />
                                                                        :
                                                                        ""
                                                                }

                                                                {/* Product Item Description */}
                                                                <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{item.ItemDesc}</Typography>
                                                                {
                                                                    renderExtendedDescription(item)
                                                                }
                                                                <ExtendedDescription key={index} itemDetails={item}></ExtendedDescription>

                                                                <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                        <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                Item #
                                                                            </Typography>
                                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ? item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID : '-'}</b>
                                                                            </Typography>
                                                                        </div>
                                                                        <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                Serial #
                                                                            </Typography>
                                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                <b>{item?.SerialNumber ? item.SerialNumber : ""}</b>
                                                                            </Typography>
                                                                        </div>
                                                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                Price
                                                                            </Typography>
                                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                <b>${Utils.nFormatter(item.UnitPrice,2)}</b>
                                                                            </Typography>

                                                                        </div>
                                                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                Brand #
                                                                            </Typography>
                                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnBrandName ? item.OrderLine?.ItemDetails?.Extn?.ExtnBrandName : '-'}</b>
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </div>
                            :
                            <React.Fragment>
                                <OrderLineShimmer></OrderLineShimmer>
                            </React.Fragment>
                        }
                        <Divider style={{border:"1px"}}/>
                    </CardContent>
                </Card>

            </div>

            //     </CardContent>
            // </Card>
        );
    }
    const mobileView = () => {
        return (
            <Accordion defaultExpanded onChange={() => handleChangeUnPacked()} style={{ display: getTotalQtyToPack() == 0 && props.loadingOrderLines == false ? "none" : "block", margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ color: Constants.Colors.darkGrey, height: 80 }}
                >
                    <div className="topHeading">
                        <div className="gridHeading" style={{ display: "flex" }}>
                            <Typography>Un-Packaged Items</Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <Divider className="card-divider" />
                <AccordionDetails style={{ display: "block", padding: 0, overflowY: "scroll" }}>

                    <div style={{ padding: "5px" }}>
                        {props.loadingOrderLines == false ?
                            <React.Fragment>
                                {
                                    unpackagedItemsList.ShipmentLines.ShipmentLine.map((item: ShipmentLine2,index:any) => {
                                        let qtyOfItem: IitemQty = itemQty.filter((qty: any) => { return qty.id == item.ShipmentLineKey })[0]
                                        let qtyToPack = qtyOfItem.toPack
                                        return (

                                            <div style={{ display: Number(item.QtyAvailableToPack) > 0 ? "block" : "none" }}>
                                                <Grid container display={"flex"} style={{ marginTop: "11px" }} className="justifyBetween productHeader">
                                                    <div >
                                                        <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">
                                                            {Utils.disableUserAction(props.store) && <Checkbox
                                                                checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                                                                onChange={(e: any) => changeSelectedItem(e, item.ShipmentLineKey)}
                                                                checked={selectedItems.indexOf(item.ShipmentLineKey) > -1 ? true : false}
                                                                style={{
                                                                    color: "#888888",
                                                                    maxHeight: "40px",
                                                                    marginTop: 18
                                                                }} />}
                                                            <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                    QTY to Ship
                                                                </Typography>
                                                                <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                    {Number(item.QtyAvailableToPack)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item display={"flex"} alignItems="center" style={{ marginTop: "8px" }}>
                                                                <Typography sx={{ fontSize: 12, whiteSpace: 'nowrap' }} color="text.secondary" style={{ marginRight: '8px' }}>
                                                                    QTY to Pack
                                                                </Typography>
                                                                <TextField
                                                                    id="order"
                                                                    className="remove-buttons"
                                                                    onChange={(event: any) => changeQuantity(event, item.ShipmentLineKey)}
                                                                    value={Number(qtyToPack)}
                                                                    type={"number"}
                                                                    inputProps={{ style: { padding: "0px !important" } }}
                                                                    InputProps={{ inputProps: { min: 1, max: Number(item.QtyAvailableToPack) }, readOnly: false, style: { padding: "0px !important", background: "white" } }}
                                                                    style={{ width: "90%", height: "50%", padding: "4px 0px 0px 0px" }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div>
                                                        {Utils.disableUserAction(props.store) && <Grid item xs={1} sm={1} className='textCenter'>
                                                            <Tooltip title="Cancel Item">
                                                                <IconButton onClick={() => { showCancelPopup(item) }}
                                                                    style={{ marginTop: 11, color: Constants.Colors.black }}
                                                                >
                                                                    <DeleteOutlinedIcon></DeleteOutlinedIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>}
                                                    </div>
                                                </Grid>


                                                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                    <Grid item xs={4} sm={4} style={{ padding: "8px" }}>
                                                        <img style={{ height: "100px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} onClick={() => { imageViewer(item) }} />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                                        <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{item.ItemDesc}</Typography>
                                                        {renderExtendedDescription(item)}
                                                        <ExtendedDescription key={index} itemDetails={item}></ExtendedDescription>
                                                    </Grid>
                                                </Grid>


                                                <div style={{ marginLeft: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                        <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                Item #
                                                            </Typography>
                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ? item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID : '-'}</b>
                                                            </Typography>
                                                        </div>
                                                        <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                Serial #
                                                            </Typography>
                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                <b>{item?.SerialNumber ? item.SerialNumber : ""}</b>
                                                            </Typography>
                                                        </div>
                                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                Price
                                                            </Typography>
                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                <b>${Utils.nFormatter(item.UnitPrice,2)}</b>
                                                            </Typography>
                                                        </div>
                                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                Brand #
                                                            </Typography>
                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnBrandName ? item.OrderLine?.ItemDetails?.Extn?.ExtnBrandName : '-'}</b>
                                                            </Typography>
                                                        </div>
                                                    </div>

                                                </div>


                                                {/* <Grid
                                                        spacing={2}
                                                        container
                                                        style={{ margin: "0px" }}
                                                        sx={{ flexGrow: 1 }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            sm={3}
                                                            style={{
                                                                padding: "6px",
                                                                borderRight: "1px solid #2f2d2d",
                                                            }}
                                                        >
                                                            {Utils.disableUserAction(props.store) &&
                                                                <Checkbox
                                                                    onChange={(e: any) => changeSelectedItem(e, item.ShipmentLineKey)}
                                                                    checked={selectedItems.indexOf(item.ShipmentLineKey) > -1 ? true : false}
                                                                    style={{
                                                                        color: "#D93A2F",
                                                                    }} />
                                                            }
                                                            {" "}
                                                            <img alt=""
                                                                style={{ height: "78px", cursor: "zoom-in" }}
                                                                src={Utils.convertToHttps(item.ImageUrl)}
                                                                onClick={() => imageViewer(item)}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            sm={9}
                                                            style={{ padding: "8px", textAlign: "left" }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontSize: "13px",
                                                                    fontWeight: 500,
                                                                    color: "#D93A2F",
                                                                }}
                                                            >
                                                                <em>
                                                                    {item.ItemDesc}
                                                                </em>
                                                            </Typography>
                                                            {
                                                                renderExtendedDescription(item)
                                                            }
                                                            <Typography style={{ fontSize: "11px" }}>
                                                                Item #: <b>{item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ? item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID : '-'}</b>
                                                            </Typography>
                                                            <Typography style={{ fontSize: "11px" }}>
                                                                Brand: <b>-</b>
                                                            </Typography>
                                                            <Typography style={{ fontSize: "11px" }}>
                                                                Serial #: <b>{item?.SerialNumber ? item.SerialNumber : ""}</b>
                                                            </Typography>
                                                            <Typography style={{ fontSize: "11px" }}>
                                                                Price: <b>$ {item.UnitPrice}</b>
                                                            </Typography>
                                                            <Typography style={{ fontSize: "11px" }}>
                                                                QTY to Ship: <b>{Number(item.QtyAvailableToPack)}</b>
                                                            </Typography>
                                                            <div style={{ display: "flex" }}>
                                                                <Typography variant="body2" sx={{ fontSize: 11, marginTop: 1.5 }} gutterBottom>
                                                                    QTY to Pack:
                                                                </Typography>
                                                                <TextField id="order"
                                                                    onChange={(event: any) => changeQuantity(event, item.ShipmentLineKey)}
                                                                    value={Number(qtyToPack)}
                                                                    type={"number"}
                                                                    inputProps={{ style: { padding: "0px !important" } }}
                                                                    InputProps={{ inputProps: { min: 1, max: Number(item.QtyAvailableToPack) }, readOnly: false, style: { padding: "0px !important" } }}
                                                                    style={{ marginLeft: "5px", width: "55%", padding: "0px !importnat" }}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid> */}
                                                {/* {Utils.disableUserAction(props.store) && <Grid container className="rightAlign">
                                                    <Button
                                                        onClick={() => { showCancelPopup(item) }}
                                                        startIcon={<CancelIcon fontSize="small" />}
                                                        className="btn btnMblCancel"
                                                        style={{
                                                            color: "#ffffff",
                                                            border: "1px solid #D93A2F",
                                                            width: "100%",
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Cancel Line
                                                    </Button>F
                                                </Grid>} */}
                                            </div>
                                        );
                                    })
                                }
                            </React.Fragment>

                            :
                            <OrderLineShimmerMobile></OrderLineShimmerMobile>
                        }

                    </div>

                </AccordionDetails>
            </Accordion >

        );
    }
    return (
        <React.Fragment>
            {Utils.isMobile ? mobileView() : webView()}
            {cancelReason.showModal ? <CancelLine getCancelReasonValues={getCancelReasonValues} isExternal={false}></CancelLine> : ""}
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
        </React.Fragment>
    );
}