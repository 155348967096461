import Label from "../Models/Label.model";



export default class Constants {

   public static USER_PROFILE: string = "oktauserProfile_3";
   public static OKTA_PROFILE: string = "oktaUserProfileDisplayName";
   public static EmployeeID: string = "EmployeeID";
   public static EmployeeName: string = "displayName";
   public static Location: string = "Location";
   public static Preferred_UserName: string = "preferred_username";
   public static UserId = "UserId_1";
   public static UseDefaultEmployeeId = "UserDefaultEmployeeId_1";
   public static createLogin = "createLogin_1";
   public static JobCode = "JobCode";
   public static Config = "Config";
   public static CompactNavbarToggle = "CompactNavbarToggle";
   public static reportErrorHistory = "reportErrorHistory";
   public static boxNumberHistory = "boxNumberHistory";


   public static REPORT_ERROR_ACTIONS = {
      PICK_UNHOLD: 'pickUnHold',
      PICK_EMAIL_TEMPLATE: 'PICK_EMAIL_TEMPLATE',
      PICK_SEND_EMAIL: 'PICK_SEND_EMAIL',
      PICK_VIEW_EMAIL_TEMPLATE: 'PICK_VIEW_EMAIL_TEMPLATE',
      PICK_CONTACT_CUSTOMER_TEMPLATE_HTML: "PICK_CONTACT_CUSTOMER_TEMPLATE_HTML",
      PICK_DETAILS_FROM_OMS: "PICK_DETAILS_FROM_OMS",
      PICK_SCAN_SKU: 'PICK_SCAN_SKU',
      PICK_GC_CNFRM: 'PICK_GC_CNFRM',
      PACK_PACKAGED: 'PACK_PACKAGED',
      PACK_CREATE_SHIPMENT: 'PACK_CREATE_SHIPMENT',
      PACK_CREATE_LABEL: 'PACK_CREATE_LABEL',
      PACK_UPDATE_TRACKING_NO: 'PACK_UPDATE_TRACKING_NO',
      PACK_ORDER_DETAIL: 'PACK_ORDER_DETAIL',
      PACK_SHIP_CONFIRM: 'PACK_SHIP_CONFIRM',
      CUSTOMER_PICKUP_CONFIRM_OMS: 'CUSTOMER_PICKUP_CONFIRM_OMS',
      CUSTOMER_PICKUP_DETAILED_OMS: 'CUSTOMER_PICKUP_DETAILED_OMS',
      EXTERNAL_CUSTOMER_PICK_DETAILED: 'EXTERNAL_CUSTOMER_PICK_DETAILED',
      FULLFILLED_ORDERS_CUSTOMER_PICKUP: 'FULLFILLED_ORDERS_CUSTOMER_PICKUP',
      FULLFILLED_ORDERS_DATA_FRM_ELASTIC: 'FULLFILLED_ORDERS_DATA_FRM_ELASTIC',
      KCDC_RETURN_MARK_INV_TRIGGER: 'KCDC_RETURN_MARK_INV_TRIGGER',
      KCDC_RETURN_MARK_LABEL_CREATE: 'KCDC_RETURN_MARK_LABEL_CREATE',
      KCDC_RETURN_UPDATE_TRACKING_NMBR: 'KCDC_RETURN_UPDATE_TRACKING_NMBR',
      KCDC_RETURN_AD_HOC_LABEL_CREATE: 'KCDC_RETURN_AD_HOC_LABEL_CREATE',
      KCDC_RETURN_CREATE_SHIPMENT: 'KCDC_RETURN_CREATE_SHIPMENT',
      KCDC_RETURN_CREATE_SHIPMENT_LABEL: 'KCDC_RETURN_CREATE_SHIPMENT_LABEL',
      KCDC_RETURN_OMS_DEL_TRACKING_NMBR: 'KCDC_RETURN_OMS_DEL_TRACKING_NMBR',
      KCDC_RETURN_VOID_LBL: 'KCDC_RETURN_VOID_LBL',
      KCDC_RETURN_SHIP_IT: 'KCDC_RETURN_SHIP_IT',
      KCDC_RETURN_PRINT_LBL: 'KCDC_RETURN_PRINT_LBL',
      KCDC_RETURN_LOAD_DATA: 'KCDC_RETURN_LOAD_DATA',
      KCDC_RETURNS_ORDER_DETAILS: 'KCDC_RETURNS_ORDER_DETAILS',
      ORDER_DETAILS_GET_DETAILS: 'ORDER_DETAILS_GET_DETAILS',
      PENDING_RECEIPT_RECEIVE_DETAILS_OMS: 'PENDING_RECEIPT_RECEIVE_DETAILS_OMS',
      PENDING_RECEIPT_CARTON_PROBLEM: 'PENDING_RECEIPT_CARTON_PROBLEM',
      PENDING_RECEIPT_CNFRM_RECEIVE: 'PENDING_RECEIPT_CNFRM_RECEIVE',
      RECEIVE_ORDER_FRM_ELASTIC: 'RECEIVE_ORDER_FRM_ELASTIC',
      TECH_AUDIT_LOAD_DATA: 'TECH_AUDIT_LOAD_DATA',
      PICKUP_PRINT_SLIP: 'PICKUP_PRINT_SLIP',
      RESOURCE_ITEM_RESOURCE_VIEW: 'RESOURCE_ITEM_RESOURCE_VIEW',
      RESOURCE_ITEM_PICK_LINE: 'RESOURCE_ITEM_PICK_LINE',
   }

   public static TECH_AUDIT_FILTERS = [
      { NAME: 'All', VALUE: '' },
      { NAME: 'OMS', VALUE: 'OMS Store Fulfillment UI' },
      { NAME: 'Shipping Center', VALUE: 'Shipping Center' },
   ]

   public static SMS_MESSAGES = [
      { NAME: 'New Condition, Out of Box', VALUE: 'noOriginalPacking' },
      { NAME: 'Not New Condition', VALUE: 'openBoxSMS' },
      { NAME: 'Custom Email', VALUE: 'customEmail' },
      { NAME: 'Follow up', VALUE: 'followUp' }

   ]

   public static MESSAGES = {
      LABEL_SUBMITTED: 'Shipment Label Generated!',
      RETURN_LABEL_SUBMITTED: 'Return Label Generated!',
      VOID_LABEL_MESSAGE: 'Would you like to void this label?',
      VOID_LABEL_SUCCESS: 'Label has been voided',
      VOID_LABEL_FAILURE: 'Error occured',
      SELECT_DISTRICT: "Choose a district to select multiple stores within that district",
      PRODUCT_CASE: 'This product comes with a case',
      PRODUCT_BAG: 'This product comes with a bag',
      SN_CNFRM_TITLE: "Report to ServiceNow",
      SN_CNFRM_BODY: "The error has already been documented in our logs.However, should you prefer to initiate a ServiceNow ticket for further action, you can simply click on the Continue button.",
      ALREADY_REPORTED_SN_TITLE: "Service Now",
      ALREADY_REPORTED_SN_BODY: "This error is already reported to Service Now",
      CARRIER_MSG:"This Tracking Number is not associated with any Carrier."
   }

   public static ORDER_TYPE = {
      SHIPPING: 'Shipped',
      IN_STORE: 'InStore',
      USED: 'Used'
   }

   public static ORDER_TYPE_LABEL = {
      Customer_Picked_Up: 'Customer Picked Up'
   }

   public static LOG_TYPES = {
      ERROR: 'error',
      LOGIN: 'Login',
      LOGOUT: 'Logout',
      REPRINT: 'RePrint',
      VOID_LABEL: 'VoidLabel',
      STORE_PRINT: 'storeprint',
      CREATE_LABEL: 'CreateLabel',
      RETURN_LABEL: 'returnlabel',
      PRINT_CONFIG: 'printconfig',
      INFO: 'info',
      PICK_SLIP: "PickSlip",
      PACK_SLIP: "PackSlip",
      CUSTOMER_PICKUP_SLIP: "PrintPickupSlip",
      CANCEL_PICK_ORDER: "CancelPickOrder",

   }

   public static PROGRESS_BAR_STYLE_DESKTOP = {
      pathColor: `#e15241`,
      textColor: '#e15241',
      trailColor: '#d6d6d6',
      backgroundColor: '#e15241'
   }

   public static COLORS = {
      MOBILE_RED: "#c11022",
      DESKTOP_RED: "#e15241",
      WARNING_YELLOW: "#ffd500",
      WARNING_ORANGE: "#ff9800",
      APPROVED_GREEN: "#0b9e0b",
   }

   public static DEFAULT = {
      LOGOUT_URL: "https://login.microsoftonline.com/tenantid/oauth2/v2.0/logout",
   }

   public static FLAGS = {
      PROOF_FLAG: false,
      IS_RESIDENTIAL: false,
      SHIPMENTHOLD_FLAG: false,
      PROOF_SIGNATURE_FLAG: false,
      SATURDAYDELIVERY_FLAG: false,
   }

   public static SHIP_DETAILS = {
      "id": 0,
      "StoreEmail": '',
      "DistrictID": 0,
      "StoreID": 0,
      "Address": "",
      "Address2": "",
      "City": "",
      "State": "",
      "Zip": "",
      "Phone": "",
      "Fax": "",
      "StoreManager": 0,
      "OpsManager": 0,
      "gcpro": "",
      "Latitude": "",
      "Longitude": ""
   }

   public static LABELS = {
      PAGINATION: {
         OPTIONS: [25, 50, 100],
         DEFAULT: { size: 25, page: 1 },
      },
   }
   public static OrderTypeFilters = [
      {
         name: "ALL",
         valueCount: 0
      },
      {
         name: "EXPEDITED",
         valueCount: 0
      },
      {
         name: "INSTORE",
         valueCount: 0
      },
      {
         name: "SHIPTOCUSTOMER",
         valueCount: 0
      },
      {
         name: "SHIPTOSTORE",
         valueCount: 0
      },
      {
         name: "ONHOLD",
         valueCount: 0
      },

   ]
   public static OrderAgeFilters = [
      {
         name: "0-1",
         valueCount: 0
      },
      {
         name: "1-2",
         valueCount: 0
      },
      {
         name: "2-3",
         valueCount: 0
      },
      {
         name: "g3",
         valueCount: 0
      },
   ]
   public static OrderValueFilters = [
      {
         name: "<$25",
         valueCount: 0
      },
      {
         name: "$25 - $100",
         valueCount: 0
      },
      {
         name: "$100 - $500",
         valueCount: 0
      },
      {
         name: ">$500",
         valueCount: 0
      },
   ]
   public static TabCounts = [
      {
         name: 0,
         valueCount: 0
      },
      {
         name: 1,
         valueCount: 0
      },
      {
         name: 2,
         valueCount: 0
      },
      {
         name: 3,
         valueCount: 0
      },
   ]
   public static TECH_AUDIT = {
      PAGINATION: {
         OPTIONS: [25, 50, 100],
         DEFAULT: { size: 25, page: 1 },
      },
      FILTERS: ['All', 'Create', 'Return', 'Reprint', 'Print Config', 'Void', 'Error', 'Store Print', 'Login', 'Logout'],
   }

   public static SIZES = [
      { value: "Custom", name: "Custom" },
      { value: "10x10x10", name: "10x10x10" },
      { value: "10x18x4.5", name: "10x18x4.5" },
      { value: "12x12x12", name: "12x12x12" },
      { value: "12x9x1", name: "Envelope" },
      { value: "15x15x20", name: "15x15x20" },
      { value: "16x16x16", name: "16x16x16" },
      { value: "18x18x12", name: "18x18x12" },
      { value: "18x6x4.5", name: "18x6x4.5" },
      { value: "19x19x19", name: "19x19x19" },
      { value: "20x16x4", name: "20x16x4" },
      { value: "20x20x20", name: "20x20x20" },
      { value: "20x20x36", name: "20x20x36" },
      { value: "20x8x50", name: "20x8x50" },
      { value: "22x22x30", name: "22x22x30" },
      { value: "22x22x8", name: "22x22x8" },
      { value: "24x10x10", name: "24x10x10" },
      { value: "24x16x24", name: "24x16x24" },
      { value: "24x20x12", name: "24x20x12" },
      { value: "24x24x16", name: "24x24x16" },
      { value: "26x26x26", name: "26x26x26" },
      { value: "26x26x6", name: "26x26x6" },
      { value: "28x20x24", name: "28x20x24" },
      { value: "28x28x12", name: "28x28x12" },
      { value: "28x28x28", name: "28x28x28" },
      { value: "32x24x24", name: "32x24x24" },
      { value: "36x24x24", name: "36x24x24" },
      { value: "36x36x24", name: "36x36x24" },
      { value: "48x24x12", name: "48x24x12" },
      { value: "60x20x8", name: "60x20x8" },
      { value: "6x9x4.5", name: "6x9x4.5" },
      { value: "8x8x8", name: "8x8x8" }
   ]

   public static PROCESS_FULFILLMENT = {
      ACTIONS: {
         REPRINT: 'RE_PRINT',
         VOID_SHIPMENT: 'VOID_SHIPMENT',
         CREATE_SHIPMENT: 'CREATE_SHIPMENT',
      },
      APP_ID: 'Shipping Center'
   }

   public static STATES = [
      { value: "AL", name: "Alabama" },
      { value: "AK", name: "Alaska" },
      { value: "AZ", name: "Arizona" },
      { value: "AR", name: "Arkansas" },
      { value: "CA", name: "California" },
      { value: "CO", name: "Colorado" },
      { value: "CT", name: "Connecticut" },
      { value: "DE", name: "Delaware" },
      { value: "DC", name: "District Of Columbia" },
      { value: "FL", name: "Florida" },
      { value: "GA", name: "Georgia" },
      { value: "HI", name: "Hawaii" },
      { value: "ID", name: "Idaho" },
      { value: "IL", name: "Illinois" },
      { value: "IN", name: "Indiana" },
      { value: "IA", name: "Iowa" },
      { value: "KS", name: "Kansas" },
      { value: "KY", name: "Kentucky" },
      { value: "LA", name: "Louisiana" },
      { value: "ME", name: "Maine" },
      { value: "MD", name: "Maryland" },
      { value: "MA", name: "Massachusetts" },
      { value: "MI", name: "Michigan" },
      { value: "MN", name: "Minnesota" },
      { value: "MS", name: "Mississippi" },
      { value: "MO", name: "Missouri" },
      { value: "MT", name: "Montana" },
      { value: "NE", name: "Nebraska" },
      { value: "NV", name: "Nevada" },
      { value: "NH", name: "New Hampshire" },
      { value: "NJ", name: "New Jersey" },
      { value: "NM", name: "New Mexico" },
      { value: "NY", name: "New York" },
      { value: "NC", name: "North Carolina" },
      { value: "ND", name: "North Dakota" },
      { value: "OH", name: "Ohio" },
      { value: "OK", name: "Oklahoma" },
      { value: "OR", name: "Oregon" },
      { value: "PA", name: "Pennsylvania" },
      { value: "RI", name: "Rhode Island" },
      { value: "SC", name: "South Carolina" },
      { value: "SD", name: "South Dakota" },
      { value: "TN", name: "Tennessee" },
      { value: "TX", name: "Texas" },
      { value: "UT", name: "Utah" },
      { value: "VT", name: "Vermont" },
      { value: "VA", name: "Virginia" },
      { value: "WA", name: "Washington" },
      { value: "WV", name: "West Virginia" },
      { value: "WI", name: "Wisconsin" },
      { value: "WY", name: "Wyoming" },
   ]
   public static NotificationPopupType = {
      Error: "error",
      Info: "info",
      Warning: "warning",
      Success: "success",
      Conformation: "conformation",
      CancelWithReason: "cancelwithreason",
      ReportError: "reporterror"
   }
   public static Colors = {
      red: "#D93A2F",
      green: "#4CAF50",
      yellow: "#ff9800",
      blue: "#2196F3",
      grey: "grey",
      white: "white",
      greybackground: "#F3F3F4",
      black: "black",
      lightGrey: "#C0C0C0",
      smokeGrey: "#E8E8E8",
      nearWhite: "#F8F8F8",
      orange: "#FF8042",
      lightBlue: "#1976D2",
      darkGrey: "#333333",
      dimGrey: "#676767",
      sandyBrown: "#FFCB78",
      orangered: "#E94B00",
      skyblue: "#54A6F0",
      lightRed: "#F23E3B"
   }
   public static SiteColors = {
      Red: "#D93A2F"
   }
   public static SiteBreadCrumbs = {
      FulfillOrders: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: true,
            icon: "fulfillorders",
            label: "Fulfill Orders",
            route: "/FulfillOrders"
         }
      ],
      Pick: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         }
         ,
         {
            active: false,
            icon: "fulfillorders",
            label: "Fulfill Orders",
            route: "/FulfillOrders"
         }
         ,
         {
            active: true,
            icon: "pick",
            label: "Pick",
            route: ""
         }
      ],
      BulkPick: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         }
         ,
         {
            active: false,
            icon: "fulfillorders",
            label: "Fulfill Orders",
            route: "/FulfillOrders"
         }
         ,
         {
            active: true,
            icon: "pick",
            label: "Bulk Pick",
            route: ""
         }
      ],
      Pack: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         }
         ,
         {
            active: false,
            icon: "fulfillorders",
            label: "Fulfill Orders",
            route: "/FulfillOrders"
         }
         ,
         {
            active: true,
            icon: "pack",
            label: "Pack",
            route: ""
         }
      ]
      ,
      CustomerPickup: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         }
         ,
         {
            active: false,
            icon: "fulfillorders",
            label: "Fulfill Orders",
            route: "/FulfillOrders"
         }
         ,
         {
            active: true,
            icon: "customerpickup",
            label: "Customer Pickup",
            route: ""
         }
      ],
      ReceiveOrders: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: true,
            icon: "receiveorders",
            label: "Receive Orders",
            route: "/ReceiveOrders"
         }
      ],
      TechAudits: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: true,
            icon: "techaudits",
            label: "Tech Audits",
            route: "/TechAudits"
         }
      ],
      Feedback: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: true,
            icon: "feedback",
            label: "Feedback",
            route: "/Feedback"
         }
      ],
      PendingReceipt: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: false,
            icon: "receiveorders",
            label: "Receive Orders",
            route: "/ReceiveOrders"
         }
         ,
         {
            active: true,
            icon: "pendingreceipt",
            label: "Pending Receipt",
            route: ""
         }
      ],
      Search: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: true,
            icon: "search",
            label: "Search",
            route: "/Search"
         }
      ],
      OrderDetail: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         // {
         //    active: false,
         //    icon: "search",
         //    label: "Search",
         //    route: "/Search"
         // }
         // ,
         {
            active: true,
            icon: "orderdetail",
            label: "Order Detail",
            route: "/OrderDetail"
         }
      ],
      KCDCReturns: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: true,
            icon: "kcdcreturn",
            label: "KCDC Returns",
            route: "/KCDCReturns"
         }
      ],
      KCDCReturnDetails: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: false,
            icon: "kcdcreturn",
            label: "KCDC Returns",
            route: "/KCDCReturns"
         },
         {
            active: true,
            icon: "kcdcreturndetail",
            label: "KCDC Return Detail",
            route: "/KCDCReturnDetail"
         }
      ],
      Reports: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: true,
            icon: "reports",
            label: "Reports",
            route: "/Reports"
         }
      ],
      OrdersSyncFlow: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: true,
            icon: "orderssyncflow",
            label: "Orders Sync Flow",
            route: "/OrdersSyncFlow"
         }
      ],
      FulfilledOrdersHistory: [
         {
            active: false,
            icon: "home",
            label: "Home",
            route: "/"
         },
         {
            active: true,
            icon: "orderHistory",
            label: "Order History",
            route: "/OrderHistory"
         }
      ],
   }
   public static boxCharacteristic = {
      index: 0,
      boxType: "0",
      quantity: 1,
      dimensions: "",
      weight: "",
      length: "",
      width: "",
      height: "",
      Error: {
         quantity: false,
         dimensions: false,
         weight: true,
         length: true,
         width: true,
         height: true,
         weightLimit: false,
         lengthLimit: false,
         girthLimit: false
      }
   }

   public static OrderHistoryTypes = {

      Order_Release: "Order Release",
      Picking: "Picking",
      Packing: "Packing",
      Shipped: "Shipped",
      Customer_Pickup: "Customer Pickup",
      Customer_Contacted: "Customer Contacted",
      Note: "Note",
      Pending_Receive: "Pending Receive",
      Order_Queue: "Order_Queue",
      Resource_Item: "Resourced Item",
      Cancel_Order: "Cancel Order",
      OnHold: "On_Hold",
      UnHold: "UnHold"
   }

   public static OrderFulfillmentType = {
      SHIP_2_CUSTOMER: "SHIP_2_CUSTOMER",
      PICKUP_IN_STORE: "PICKUP_IN_STORE"
   }
   public static DateFormat = {
      Format: "MM/DD/YYYY h:mm A",
      DateOnly: "MM/DD/YYYY"
   }

   /**
    *  CryptoRandomTypes
    */
   public static CryptoRandomTypes = {

      None: "None",
      base64: "base64",
      url_safe: "url-safe",
      numeric: "numeric",
      distinguishable: "distinguishable",
      ascii_printable: "ascii-printable",
      alphanumeric: "alphanumeric",
      abc: "abc"


   }

   public static choicesValues = [
      {
         id: "0",
         val: "Order Number"
      },
      {
         id: "1",
         val: "Shipping Method"
      },
      {
         id: "2",
         val: "Order Age (days)"
      },
      {
         id: "3",
         val: "Item ID"
      },
      {
         id: "4",
         val: "Category"
      }
   ]

   public static customerSlipDurationOptions = [
      {
         id: "0",
         name: "05 Days"
      },
      {
         id: "1",
         name: "10 Days"
      },
      {
         id: "2",
         name: "15 Days"
      },
      {
         id: "3",
         name: "All"
      }
   ]

   public static customerSlipSortOptions = [
      {
         id: "asc",
         name: "Ascending"
      },
      {
         id: "desc",
         name: "Descending"
      }
   ]

   public static SingleMultiplePickingOptions = [
      {
         id: "0",
         val: "Order #"
      },
      {
         id: "1",
         val: "Category"
      },
      {
         id: "2",
         val: "Item ID"
      }

   ]
   public static OrderFlowConstants = {
      PICK_PACK_SHIP: "PICK_PACK_SHIP",
      PICK_PICKUP: "PICK_PICKUP",
      PICK_PACK_SHIP_RECEIVE_PICKUP: "PICK_PACK_SHIP_RECEIVE_PICKUP"
   }

   public static AuditTransactionidTypes = {
      Associate: "ASSOCIATE",
      Customer: "CUSTOMER"
   }

   public static RouteLabels = {
      KCDCReturns: "KCDCReturns",
      FulfillOrders: "FulfillOrders"
   }

   public static ContactCustomerFileName = {
      FollowUp: "followup.txt",
      Custom: "Custom.txt"
   }

   public static Configurations = {
      SaveLogResponse: "SaveLogResponse",
      LogsEndpoint: "LogsEndpoint",
      EmpIdSwitch: "EmpIdSwitch",
      StoreDMRVP: "StoreDMRVP",
      ShippingAPI: "ShippingAPI",
      OnHold: "OnHold",
      OnUnHold: "OnUnHold",
      ReportErrorSNFlag: "ReportErrorSNFlag",
      PartialResource: "PartialResource",
      ReportErrorSNImpactFlag: "ReportErrorSNImpactFlag",
      ReportErrorSNPriorityFlag: "ReportErrorSNPriorityFlag",
      ReportErrorSNUrgencyFlag: "ReportErrorSNUrgencyFlag",
      CarrierUrl: "CarrierUrls",
      CarrierMsg: "CarrierMsg",
      CheckStatus: "CheckStatus",
      RetryConfig: "RetryConfig",
      ResolveErrorsEndpoint: "ResolveErrorsEndpoint",
      ResolveErrors: "ResolveErrors",
      APITimeout: "APITimeout",
      ValidStatuses: "ValidStatuses"
   }

   public static PFServicesRoutes = {
      CreateShipment: "/pf/createShipment",
      VoidShipment: "/pf/voidShipment",
      RePrintLabel: "/pf/rePrintLabel",
   }

   public static FulfillOrdersGrid = {
      CustomerContacted: "Customer Contacted",
      CustomerResponded: "Customer Responded",
      MobileRowsPerPage: 25
   }

   public static FulfillOrdersDisplayNames = {
      OnHold: "On Hold",
      Resourced: "Resourced",
      Pending_Receive: "Pending Receipt"
   }

   public static FeedbackReactions = {
      Satisfied: "1",
      Neutral: "0",
      UnSatisfied: "-1"
   }

   public static FulfilledOrdersHistory = {
      OrderTypes: {
         All: "All",
         Shipped: "Shipped",
         Pickedup: "Pickedup",
         Used: "Used"
      }
   }
   public static UserSettingsFields = {
      Pagination: "pagination",
      ExpandFilters: "expandFilters"
   }
   public static UserSettingsDefault = {
      Pagination: 50,
      ExpandFilters: false
   }

   public static ResourceActions = {
      ResourceLine: "resourceLine",
      PartialResourceLine: "partialResourceLine"
   }

   public static RouteNames = [
      {
         "/":"Dashboard"
      },
      {
         "/Dashboard":"Dashboard"
      },
      {
         "/FulfillOrders":"Fulfill Orders"
      },
      {
         "/ReceiveOrders":"Pending Receipt"
      },
      {
         "/KCDCReturns":"Returns"
      },
      {
         "/OrderHistory":"Order History"
      },
      {
         "/Reports":"Reports"
      },
      {
         "/TechAudits":"Tech Audits"
      },
      {
         "/OrdersSyncFlow":"Orders Sync Flow"
      },
      {
         "/Feedback":"Feedback"
      },

   ]
}


